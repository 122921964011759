<template>
 <div class="static-pages container">
        <h2>Advertising and Sponsorship Policy</h2>
         <div class="text-container">
          <p>To further our mission of making reliable health information available to the public, we accept advertising and sponsorship under strict guidelines. We will refuse any advertisement that we believe is incompatible with our mission. The presence of an ad on our site or in one of our newsletters does not imply endorsement of the advertised company or product. Advertisers and sponsors must not make unsubstantiated health claims or suggest that Mayo Clinic has endorsed a product. Advertising may be placed on the site adjacent to content related to advertiser or sponsor interest, and also may be targeted to users by using non-personal data. Our sponsors and advertisers have agreed that they will not collect any personally identifiable information from our site visitors while they are on Mayo Clinic's health information website.</p>
<p>Mayo Clinic maintains a distinct separation between advertising content and editorial content. All advertising content on Mayo Clinic's health information website or in one of our newsletters is clearly labeled as an advertisement. Sponsored areas of the site are labeled as such, and the sponsoring organizations are identified.</p>
<p>For more detailed information about advertising and sponsorship on Mayo Clinic's health information website, go to:</p>
<ul>
<li><a href="/advertising-policy" >Advertising and sponsorship</a></li>
</ul>
</div>
       
    </div>
</template>
<script>
export default{
    setup(){
        return{

        }
    }
}
</script>
