<template>
  <div class="main-wrapper">
    <div class="video-section">
      <div class="overlay"></div>
      <video autoplay muted loop playsinline>
        <source src="../assets/bann-video.mp4" type="video/mp4" />
      </video>
    </div>

    <!-- promotion comp -->
    <div class="promotionsComp">
      <div class="container">
        <div class="title cursoreffect">
          <h2>Current Promotion</h2>
        </div>
        <!-- promotions desktop -->
        <div class="promotions">
          <div
            @click="gotodetailpage(promotion)"
            v-for="(promotion, index) in promotions"
            :key="index"
            class="card cursoreffect"
          >
            <div class="img">
              <img :src="promotion.image" />
            </div>
            <div class="text">
              <h4>{{ promotion.title }}</h4>
            </div>
          </div>
        </div>

        <!-- promotions mob slider -->
        <div class="promotions-mob">
          <Carousel
            :settings="settings"
            :breakpoints="breakpoints"
            :autoplay="4000"
            :wrapAround="true"
          >
            <Slide
              v-for="(promotion, index) in promotions"
              :key="index"
              class="card"
              @click="gotodetailpage(promotion)"
            >
              <div class="img">
                <img :src="promotion.image" />
              </div>
              <div class="text">
                <h4>{{ promotion.title }}</h4>
              </div>
            </Slide>
            <template #addons>
              <!-- <Pagination /> -->
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../eventBus";
import { Carousel, Navigation, Slide } from "vue3-carousel";
// import {Pagination} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { useRouter } from "vue-router";
export default {
  components: {
    // Pagination,
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const router = useRouter();
    const settings = {
      itemsToShow: 3,
      snapAlign: "center",
    };
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    const breakpoints = {
      // 700px and up
      300: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      767: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: "start",
      },
    };
    const promotions = [
      {
        title: "Book Appointment",
        image: require("../assets/calendar.png"),
        link: "https://noblehrc.com/patient-portal",
      },
      {
        title: "Our Specialities",
        image: require("../assets/stethoscope.png"),
        path: "/specialities",
      },
      { title: "Find a Doctor", image: require("../assets/doctor.png") },
      {
        title: "Second Opinion",
        image: require("../assets/suggestion.png"),
        path: "/second-opinion",
      },
      {
        title: "Home Care",
        image: require("../assets/homecare.jpg"),
      },
    ];
    const gotodetailpage = (promotion) => {
      if (promotion.title === "Find a Doctor") {
        scrollToBottom();
      } else if (promotion.link) {
        window.open(promotion.link, "_blank");
      } else {
        router.push(promotion.path);
        window.scrollTo(0, 0);
        document.body.style.overflow = "";
      }
    };

    const scrollToBottom = () => {
      EventBus.emit("scrollToDoctorSection");
    };
    return {
      gotodetailpage,
      breakpoints,
      settings,
      Carousel,
      Slide,
      scrollToBottom,
      promotions,
    };
  },
};
</script>

<style lang="less" scoped>
.main-wrapper {
  position: relative;
  height: 83vh;
  width: 100%;

  @media (max-width: 576px) {
    height: 70vh;
  }
  .title {
    // position: absolute;
    // bottom: 8vh;
    // left: 0;
    // right: 0;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1024px) {
      bottom: 6vh;
    }
    h2 {
      color: #fff;
    }
  }
  .video-section {
    height: 100%;
    width: 100%;

    @media (max-width: 767px) {
      margin-top: 39px;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      @media (max-width: 1600px) {
        height: 100%;
      }
      @media (max-width: 1024px) {
        height: 100%;

        object-fit: cover;
        width: 100%;
      }
      @media (max-width: 767px) {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .promotionsComp {
    position: absolute;
    width: 100%;
    //  bottom: -7vh;
    bottom: -13vh;

    @media (max-width: 1600px) {
      bottom: -12vh;
    }
    @media (max-width: 1024px) {
      bottom: -8vh;
    }
    @media (max-width: 576px) {
      bottom: -5vh;
    }
    .container {
      @media (max-width: 767px) {
        padding-bottom: 0px;
      }
    }
    .promotions {
      background: #fff;

      display: grid;
      grid-template-columns: auto auto auto auto auto;
      border-radius: 15px;
      box-shadow: 0px 1px 0px #d7e2e6;

      margin-top: 20px;
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }

      .card {
        display: flex;
        background: aliceblue;
        cursor: pointer;
        border-left: 1px solid #cae0ff;
        justify-content: center;
        align-items: center;
        padding: 25px 0px;
        transition: background-color, 0.3s ease-in-out;
        @media (max-width: 767px) {
          padding: 14px 0px;
        }
        .img {
          width: 40px;
          height: 40px;
          @media (max-width: 767px) {
            width: 37px;
          }
          img {
            width: 100%;
          }
        }

        h4 {
          padding-left: 10px;
          text-align: center;
        }
        &:nth-child(1) {
          border-left: none;

          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;

          @media (max-width: 1024px) {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            border: none;
          }
          @media (max-width: 767px) {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            border: none;
          }
          .text {
            h4 {
              padding-left: 10px;
              text-align: center;
            }
          }
        }
        &:nth-child(2) {
          @media (max-width: 1024px) {
            border-radius: 15px;
            border: none;
          }
          @media (max-width: 767px) {
            border-radius: 15px;
            border: none;
          }
        }
        &:nth-child(3) {
          @media (max-width: 1024px) {
            border-radius: 15px;
            border: none;
          }
          @media (max-width: 767px) {
            border-radius: 15px;
            border: none;
          }
        }
        &:nth-child(4) {
          @media (max-width: 1024px) {
            border-radius: 15px;
            border: none;
          }
          @media (max-width: 767px) {
            border-radius: 15px;
            border: none;
          }
        }
        &:nth-child(5) {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          @media (max-width: 1024px) {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            border: none;
          }
          @media (max-width: 767px) {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            border: none;
          }
        }
        &:hover {
          background: #0063a8;
          .text {
            h4 {
              color: #fff;

              padding-left: 10px;
              text-align: center;
            }
          }
          img {
            filter: invert(1);
          }
        }
      }
    }

    .promotions-mob {
      background: #fff;
      box-shadow: 0px 1px 0px #d7e2e6;
      display: none;
      margin-top: 20px;

      @media (max-width: 1024px) {
        display: block;
        border-radius: 15px;
      }

      @media (max-width: 767px) {
        display: block;
      }
      :deep(.carousel__viewport) {
        @media (max-width: 1024px) {
          border-radius: 15px;
        }
      }
      .card {
        display: flex;
        background: aliceblue;
        cursor: pointer;
        border-left: 1px solid #cae0ff;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        flex-direction: column;

        @media (max-width: 767px) {
          padding: 14px 0px;
        }

        h4 {
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }
        .img {
          width: 40px;
          height: 40px;
          @media (max-width: 767px) {
            width: 28px;
          }
          img {
            width: 100%;
          }
        }
        p {
          padding: 0px;
          color: #58595b;
          text-align: center;
        }
        &:nth-child(1) {
          border-left: none;
          background: #0063a8;

          .text {
            h4 {
              color: #fff;

              padding-left: 10px;
              text-align: center;
            }
          }
          img {
            filter: invert(1);
          }
        }

        &:hover {
          background: #0063a8;
          .text {
            h4 {
              color: #fff;

              padding-left: 10px;
              text-align: center;
            }
          }
          img {
            filter: invert(1);
          }
        }
      }
      :deep(.carousel__prev) {
        @media (max-width: 1024px) {
          display: none;
        }
      }
      :deep(.carousel__next) {
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}
</style>
