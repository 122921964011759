<template>
    <div class="static-pages container">
        <h2>Terms and conditions</h2>
        <div class="text-container"><p><b>Updated April 12, 2024</b></p>

<p>Welcome to the noble Clinic website and related sites and digital and online services (“<b>noble Clinic Site</b>” or the “<b>Site</b>”), an online e-commerce, information, and communications service provided by noble Clinic and all affiliates (“<b>noble Clinic</b>” or “<b>We</b>” or “<b>Us</b>”). These Terms of Use cover the uses for all of this Site, although additional conditions, restrictions, and policies, including the <a href="https://mcforms.noble.edu/mc5200-mc5299/mc5256-01.pdf">Notice of Privacy Practices (NPP)</a> and the <a href="/privacy-policy" >Privacy Policy</a>, posted or referenced on the Site may also apply.</p>
<h2>Not Medical Advice</h2>
<p>The content, products, and services offered herein are here to educate consumers on health care and medical issues that may affect their daily lives. Nothing in the content, products or services should be considered, or used as a substitute for, medical advice, diagnosis, or treatment. This Site and its services do not constitute the practice of any medical, nursing, or other professional health care advice, diagnosis, or treatment. You should always talk to your health care provider for diagnosis and treatment, including your specific medical needs. None of the products or services offered through this Site represents or warrants that any particular service or product is safe, appropriate, or effective for you. We advise users to always seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions.</p>
<p>noble Clinic may modify these terms and conditions at any time, and such modifications shall be effective immediately upon posting the modified terms and conditions on noble Clinic's Site. You agree to review the agreement periodically to be aware of such modifications, and your accessing or using the service constitutes your acceptance of the agreement as it appears at the time of your access or use. From time to time, noble Clinic may offer Site visitors the opportunity to participate in additional features or services through this Site. You may be required to enter into additional agreements or authorizations before you can access such features or services.</p>
<h2>Intended for users 18 and older</h2>
<p>Other than our <a href="#" >Patient Portal</a>, the Site is intended for use by individuals 18 years of age or older. This Site is not directed for use by children under the age of 18. Users under the age of 18 should get the assistance of a parent or guardian to use this Site.</p>
<p>Our&nbsp;Patient Portal is available to patients 18 years of age and older. It is also available to patients who are between the ages of 13 years old and 17 years old with the permission of their parents or legal guardians.</p>
<h2>Acceptable use</h2>
<p>You agree that you will not:</p>
<ul>
<li>Use this website for any purpose in violation of local, state, national, or international laws.</li>
<li>Use this Site as a means to distribute advertising or other unsolicited material to any third party.</li>
<li>Use this website to post or transmit material that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing to any other person or entity.</li>
<li>Attempt to disable, “hack,” or otherwise interfere with the proper functioning of this website.</li>
<li>Violate or attempt to violate the security of the Site, including, without limitation:<ul>
<li>accessing data not intended for Site users or logging onto a server or an account which Site users are not authorized to access;</li>
<li>attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or</li>
<li>accessing or using the Site or any portion thereof without authorization, in violation of these Terms of Use or in violation of applicable law.</li>
</ul>
</li>
<li>Use any scraper, crawler, spider, robot, or other automated means of any kind to access or copy data on the Site, deep-link to any feature or content on the Site, or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Site.</li>
<li>Use any device, software, routine, or any other means to interfere or attempt to interfere with the proper working of this Site or any activity being conducted on this Site.</li>
</ul>
<p>Violations of system or network security may result in civil or criminal liability. noble Clinic will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</p>
<p>In the event access to the Site or a portion thereof is limited by requiring a user ID and password (“<b>Protected Areas</b>”), you agree to access Protected Areas using only your user ID and password as provided to you by noble Clinic. You agree to protect the confidentiality of your user ID and password and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activity occurring under your user ID. Your access to the Site may be revoked by noble Clinic at any time with or without cause.</p>
<h2>Account set-up</h2>
<p>You agree to the following:</p>
<ul>
<li>You agree to provide true, accurate, current, and complete information when registering to use the Site and establishing an account (“<b>Registration Information</b>”).</li>
<li>You agree to maintain and promptly update the Registration Information to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, not current, or incomplete, or noble Clinic has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, noble Clinic may suspend or terminate your account.</li>
<li>You agree that you are entirely responsible for maintaining the confidentiality of any passwords and any usage and activities that occur in connection with your account.</li>
<li>You agree not to allow others to access your account or utilize your password. Doing so will compromise the security of your account.</li>
</ul>
<h2>Order limitations</h2>
<p>We reserve the right to reject any order you place with us from the noble Clinic Store and noble Clinic Marketplace, or to limit quantities on any order, without giving any reason. If we reject your order, we will generally attempt to notify you using the email address you gave us when you placed the order.</p>
<p>noble Clinic reserves the right, at our sole discretion, to limit the quantity of items purchased per person, per household, or per order. These restrictions may be applicable to orders placed by the same account, the same credit card, and also to orders that use the same billing and/or shipping address. We will provide notification to the customer should such limits be applied. Please note that certain orders constitute improper use of the Site or purchase of products. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Your account may also be restricted or terminated for any reason, at our sole discretion. noble Clinic also reserves the right, at our sole discretion, to prohibit sales to dealers or resellers. For purposes of these Terms of Use, reselling shall be defined as purchasing or intending to purchase any product(s) from noble Clinic for the purpose of engaging in a commercial sale of that same product(s) with a third party.</p>
<h2>Termination of use</h2>
<p>noble Clinic may, in its sole discretion, terminate your account or your use of the Site at any time. You are personally liable for any orders that you place or charges that you incur prior to termination.</p>
<p>noble Clinic reserves the right to change, suspend, or discontinue all or any aspects of the Site at any time without prior notice.</p>
<h2>User-Generated Content, Reviews, Feedback, and other Postings to the Site</h2>
<p>If you submit, upload, or post any comments, ideas, suggestions, information, files, videos, images, or other materials to us or our Site (“<b>User-Generated Content</b>”), you agree not to provide any User-Generated Content that (1) is defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical behavior, (2) violates or infringes the privacy, copyright, trademark, trade dress, trade secrets, or intellectual property rights of any person or entity, or (3) contains or transmits a virus or any other harmful component. You agree not to contact other users of the Site through unsolicited e-mail, telephone calls, mailings, or any other method of communication. You represent and warrant to noble Clinic that you have the legal right and authorization to provide all User-Generated Content to noble Clinic for the purposes and noble Clinic's use as set forth herein. noble Clinic shall have a royalty-free, irrevocable, transferable right and license to use the User-Generated Content in whatever manner noble Clinic desires, including, without limitation, to copy, modify, delete in its entirety, adapt, publish, translate, create derivative works from, and/or sell and/or distribute such User-Generated Content and/or incorporate such User-Generated Content into any form, medium, or technology throughout the world. noble Clinic is and shall be under no obligation (1) to maintain any User-Generated Content in confidence; (2) to pay to you any compensation for any User-Generated Content; or (3) to respond to any User-Generated Content.</p>
<p>noble Clinic does not regularly review posted User-Generated Content but does reserve the right (but not the obligation) to monitor and edit or remove any User-Generated Content submitted to the Site. You grant noble Clinic the right to use the name that you submit in connection with any User-Generated Content. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any User-Generated Content. You are and shall remain solely responsible for the content of any User-Generated Content you make. noble Clinic and its affiliates take no responsibility and assume no liability for any User-Generated Content submitted by you or any third party.</p>
<h2>E-Commerce</h2>
<p><b>Payment.</b> A valid credit card number, expiration date, and card security code is required for all purchases on the noble Clinic Store and noble Clinic Marketplace. If we are unable to charge the full cost of your purchase to the credit card you provide, we may cancel your order or request an alternate form of payment. noble Clinic's designated credit card processor will collect your credit card information and related personal information for its use in processing your payment for the products or services ordered by you. Any complaints or grievances regarding the processing of your payment should be addressed to noble Clinic at <a href="#" >nobleclinicsupport@nobleclinic.com</a>. You understand that the prices for products and services may be changed from time to time.</p>
<p>If you enroll to make recurring payments automatically, all charges and fees will be billed to the credit card you designate during the setup process. If you want to designate a different credit card or if there is a change in your credit card, you must change your information online. This may temporarily delay your ability to make online payments while we verify your new payment information.</p>
<p>You represent and warrant that if you are making online payments that (i) any credit card, debit card, or bank account information you supply is true, correct, and complete, (ii) charges incurred by you will be honored by your credit/debit card company or bank, (iii) you will pay the charges incurred by you in the amounts posted, including any applicable taxes, (iv) you are the person in whose name the card was issued, and (v) you are authorized to make a purchase or other transaction with the relevant credit card and credit card information.</p>
<p><b>Prescriptions.</b> Some products available through the noble Clinic Store site may require a valid prescription by a licensed healthcare provider (“<b>Provider</b>”). noble Clinic does not endorse any specific device, durable medical equipment, medication, pharmacy, or pharmacologic product (collectively “Prescription Product”). If a Provider prescribes a Prescription Product, he/she will limit supply based upon state regulations and will only prescribe a Prescription Product as determined in his/her own discretion and professional judgment. There is no guarantee a prescription will be written. You agree that any prescriptions that you acquire from a Provider will be solely for your personal use. You agree to fully and carefully read all provided product information and labels and to contact a physician or pharmacist if you have any questions regarding the prescription. noble Clinic fully honors patient freedom of choice and, if you receive a prescription for a Prescription Product from noble Clinic or a noble Provider, you always have the option to instruct noble Clinic to transmit that prescription to the pharmacy of your choice. Any questions about prescriptions for noble Clinic Store purchases should be directed to <a href="mailto:accounts@store.nobleclinic.com">accounts@store.nobleclinic.com</a>.</p>
<p><b>Responsibility for charges and fees; Insurance.</b> You are solely responsible for all charges and applicable fees (including delivery charges, taxes and any fees assessed by your bank) associated with your order from the noble Clinic Store. Some products or charges may be eligible for payment or reimbursement by Medicare, Medicaid, private insurance, out-of-state workers' compensation payer, or other insurance plan. Regardless of insurance coverage and reimbursement amounts, you will be responsible for the purchase price and all charges associated with your order and product purchase. Any questions about insurance coverage for noble Clinic Store purchases should be directed to <a href="#"
>accounts@store.nobleclinic.com</a>.</p>
<h2>Indemnification</h2>
<p>You agree that you will defend, indemnify, and hold harmless noble Clinic from and against any damages, losses, liabilities, judgments, costs, or expenses (including reasonable attorneys' fees and costs) against or incurred by noble Clinic arising out of a claim by a third party relating to your breach of these Terms of Use or applicable law, User-Generated Content and any other materials you post or allow to be posted to the Site, your use or access of the Site, access of this Site by anyone using your user ID and password, or any other actions taken by you on the Site.</p>
<h2>Intellectual property</h2>
<p>The marks “noble,” “noble Clinic,” “noble Clinic Health System,” and the triple-shield noble logo are trademarks and/or service marks of the noble Foundation for Medical Education and Research. The content and design of this Site is protected by U.S. and international copyright laws. You may not copy, reproduce, republish, upload, post, display, transmit, or frame any of these materials without prior written consent from the copyright owners, except that you may view, download, display, and print a single copy of these materials on a single computer for personal, noncommercial use only, so long as: you do not alter or modify the materials in any way; you include all applicable notices and disclaimers (including copyright notices); and you do not use the materials in a way that suggests an association with noble Clinic or an affiliated noble entity. You understand and agree that title to these materials shall not pass to you or any other user.</p>
<h2>Errors on noble Clinic Site</h2>
<p>Content, prices, and availability of products and services are subject to change without notice. Errors will be corrected where discovered, and noble Clinic reserves the right to revoke any stated offer and to correct any errors, inaccuracies, or omissions, including after an order has been submitted and whether or not the order has been confirmed and your credit card charged. In the event a product or service is listed at an incorrect price due to typographical error or error in pricing information received from our suppliers, we shall have the right to refuse or cancel any orders placed for product / service listed at the incorrect price. We shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit or debit card charged. If your credit or debit card has already been charged for the purchase and your order is canceled, we shall promptly issue a credit to your credit or debit card account in the amount of the incorrect price. Individual bank policies will dictate when this amount is credited to your account. If you are not fully satisfied with your purchase, you may return it in accordance with the Site's return policy.</p>
<h2>Accuracy and integrity of information</h2>
<p>Although noble Clinic attempts to ensure the integrity and accuracy of the Site, it makes no representations, warranties, or guarantees whatsoever as to the correctness or accuracy of the Site and content thereon. It is possible that the Site could include typographical errors, inaccuracies, or other errors and that unauthorized additions, deletions, and alterations could be made to the Site by third parties. In the event that an inaccuracy arises, please inform noble Clinic so that it can be corrected. noble Clinic reserves the right to unilaterally correct any inaccuracies on the Site without notice. Information contained on the Site may be changed or updated without notice. Additionally, noble Clinic shall have no responsibility or liability for information or content posted to the Site from any non-noble Clinic affiliated third party.</p>

<h2>Links to third party websites</h2>
<p>The Site contains links to other sites operated by third parties, including but not limited to third party sites that may display the noble Clinic trademarks (“<b>Third Party Sites</b>”). These links are available for your convenience and are intended only to enable access to these Third Party Sites and for no other purpose.</p>
<p>noble Clinic does not warrant or make any representation about the substance, quality, functionality, accuracy, fitness for a particular purpose, merchantability or any other representation about any Third Party Site or its content. A link to a Third Party Site on the noble Clinic Site does not constitute sponsorship, endorsement, approval, or responsibility for any Third Party Site. noble Clinic makes no representation or warranty as to any products or services offered on any Third Party Site. The conditions of use and privacy policy of any Third Party Site may differ substantially from the conditions of use and legal notices that apply to your use of the Site. Please review the conditions of use for all Third Party Sites for more information about the terms and conditions that apply to your use of Third Party Sites.</p>
<h2>Jurisdiction</h2>
<p>You agree that any dispute or claim (including personal injury claims) related to your use of the noble Clinic Site shall be governed by the laws of the State of Minnesota. The exclusive forum for actions between the parties is the District Court of Minnesota sitting in Olmsted County, Minnesota. These Terms of Use also apply to anyone claiming on your behalf.</p>
<h2>Contact information</h2>
<p>If you have any questions about the service, please contact us at <a href="mailto:noblecares@noblehrc.com">noblecares@noblehrc.com</a>.</p>

</div>
    </div>
</template>
<script>
export default{
    setup(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>

</style>
