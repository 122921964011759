<template>
  <div class="overview-slider">
    <div class="row carousel" ref="carousel">
      <div class="card col-sm-4 col-md-3" v-for="card in cards" :key="card">
        <div class="card-content">
          <div class="card">
            <div class="img">
              <img :src="card.img" />
            </div>
            <div class="content">
              <h3>{{ card.title }}</h3>
              <p>{{ card.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";

export default {
  name: "OverviewSlider",
  components: {},
  setup() {
    const carousel = ref(null);
    const cards = reactive([
      {
        img: require("../assets/anethisiology.png"),
        title: "Anesthesiology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/breast-care.png"),
        title: "Breast Care Center",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/cardiac-sciences.png"),
        title: "Cardiac Sciences",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/cognitive-behaviourial.png"),
        title: "Cognitive-Behavioural Psychotherapy",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/critical-care.png"),
        title: "Critical Care",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/dental-and-maxi.png"),
        title: "Dental & Maxillofacial Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/dermatology.png"),
        title: "Dermatology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/diabetes.png"),
        title: "Diabetes",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/emergencvy-and-trauma.png"),
        title: " Emergency and Trauma",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/endocrinology.png"),
        title: "Endocrinology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/ent.png"),
        title: "ENT",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/fertility-medicine.png"),
        title: "Fertility Medicine",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/fetal-medicine.png"),
        title: "Fetal Medicine",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/gaesotroentrelogy.png"),
        title: "Gastroenterology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/hematology.png"),
        title: "Hematology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/infectious-desease.png"),
        title: "Infectious Diseases",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },

      {
        img: require("../assets/internal-medicne.png"),
        title: "Internal Medicine",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/international-radiology.png"),
        title: "Interventional Radiology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Cardiac Sciences3.png"),
        title: "Laboratory Medicine",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/laboratory-medicine.png"),
        title: "Laparoscopic and Minimally Invasive Surgery (MIS) ",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/mental-health.png"),
        title: "Mental Health",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/neaonatal.png"),
        title: "Neonatal ICU",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/nephrology.png"),
        title: "Nephrology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/neuro-surgery.png"),
        title: "Neuro surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/neuro-internationaly-radiology.png"),
        title: "Neuro-interventional Radiology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/neurology.png"),
        title: "Neurology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/nutrition-and-dietritics.png"),
        title: "Nutrition & Dietetics",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/obesity.png"),
        title: "Obesity",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/obestrics-and -gynocology.png"),
        title: "Obstetrics & Gynecology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/oncology.png"),
        title: "Oncology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/opthamology.png"),
        title: "Ophthalmology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },

      {
        img: require("../assets/orthopedics.png"),
        title: "Orthopedics",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pain-management-clinic.png"),
        title: " Pain Management Clinic",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pediatric-endocrdionology.png"),
        title: "Pediatric Endocrinology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pediatric-nephrology.png"),
        title: "Pediatric Nephrology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pediatric-nephrology.png"),
        title: " Pediatric Neurology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pediatrics.png"),
        title: "Pediatrics",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pediatric-surgery.png"),
        title: "Pediatrics  Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/physiotherapy.png"),
        title: "Physiotherapy",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/plastic-reconstructive-surgery.png"),
        title: "Plastic & Reconstructive Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },

      {
        img: require("../assets/preventive-medicine.png"),
        title: " Preventive Medicine",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/psychiatry.png"),
        title: "Psychiatry",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/pulmonology.png"),
        title: "Pulmonology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/radiology.png"),
        title: "Radiology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/rheumatology.png"),
        title: "Rheumatology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },

      {
        img: require("../assets/robotics-knee-joint.png"),
        title: "Robotic Knee Joint Replacement  ",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/shoulder-arthroscopy.png"),
        title: "Shoulder Arthroscopy & Sports Injuries",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/vascular-surgery.png"),
        title: "Vascular Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/surgical-gastero.png"),
        title: "Surgical Gastroenterology, Liver & HPB Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/surgical-oncology.png"),
        title: "Surgical Oncology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/urology.png"),
        title: "Urology",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/vascular-surgery.png"),
        title: "Vascular Surgery",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
    ]);
    onMounted(() => {
      $(carousel.value).slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 2,
        arrows: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 776,
            settings: {
              slidesToShow: 1,
              rows: 1,
            },
          },
        ],
      });
    });

    return {
      carousel,
      cards,
    };
  },
};
</script>

<style lang="less" scoped>
.card > .card-content {
  border-radius: 20px;
  background: #fff;
  border: 1px solid rgba(222, 229, 234, 0.5);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 17px;
  margin: 10px;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content {
    text-align: center;

    height: 130px;
    @media (max-width: 1600px) {
      height: 162px;
    }
    @media (max-width: 767px) {
      height: auto;
    }
    h3 {
      margin: 20px 0px;
      @media (max-width: 767px) {
        margin: 0px;
      }
    }
      p{

   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
  }
}

:deep(.slick-prev) {
  left: 66% !important;
  bottom: 0px;
  top: 100%;
  width: 40px;
  margin-top: 20px;
  margin-right: 10px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;
  @media (max-width: 1600px) {
    left: 62% !important;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
  }
}
:deep(.slick-next) {
  left: 74% !important;
  bottom: 0px;
  top: 100% !important;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
  }
}
:deep(.slick-slide.slick-current.slick-active) {
  margin-top: 60px;
  @media(max-width:767px){
    margin-top: 0px;
  }
}
</style>
