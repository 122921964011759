<template>
  <div class="main-wrapper">
    <div class="international-section">
      <div class="about-section ">
       <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>International Patients</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>International Patients</h2>
    </div>
  </div>
</div>
      </div>
    </div>
    <div class="international-patient">
      <div class="wrapper container">
        <div class="banner">
          <div id="chartdiv"></div>
          <!-- <div class="inter-counting">
            <div class="stats-container">
              <div class="stats">
                <div class="stats-inner">
                  <div class="count" ref="count1">0</div>
                  <div class="stats-title">
                    Average International Patients Per Year
                  </div>
                </div>
              </div>
              <div class="stats stats-2">
                <div class="stats-inner">
                  <div class="count" ref="count2">0</div>
                  <div class="stats-title">Countries</div>
                </div>
              </div>
              <div class="stats stats-2">
                <div class="stats-inner">
                  <div class="count" ref="count3">0</div>
                  <div class="stats-title">Hospitals in India</div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="flags-container">
            <div class="flag">
              <img src="../assets/flag.png" />
            </div>
            <div class="flag">
              <img src="../assets/flag2.png" />
            </div>
            <div class="flag">
              <img src="../assets/flag3.png" />
            </div>
            <div class="flag">
              <img src="../assets/flag4.png" />
            </div>
          </div>
        </div>
        <QueryForm />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import QueryForm from "../components/QueryForm.vue";
import EcgComp from "./EcgComp.vue";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

export default {
  name: "InternationalPatients",
  components: {
    QueryForm,
    EcgComp,
  },
  setup() {
    const count1 = ref(null);
    const count2 = ref(null);
    const count3 = ref(null);

    const startCounting = (ref, endValue) => {
      let startValue = 0;
      const duration = 2000;
      const increment = endValue / (duration / 16);

      const counter = () => {
        startValue += increment;
        if (startValue < endValue) {
          if (ref.value) {
            ref.value.innerText = Math.ceil(startValue);
          }
          requestAnimationFrame(counter);
        } else {
          if (ref.value) {
            ref.value.innerText = endValue;
          }
        }
      };

      counter();
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === count1.value) {
            startCounting(count1, 2000);
          } else if (entry.target === count2.value) {
            startCounting(count2, 130);
          } else if (entry.target === count3.value) {
            startCounting(count3, 6);
          }
        }
      });
    };

    const setupObserver = () => {
      const options = {
        root: null,
        threshold: 0.1,
      };

      const observer = new IntersectionObserver(handleIntersection, options);

      if (count1.value) observer.observe(count1.value);
      if (count2.value) observer.observe(count2.value);
      if (count3.value) observer.observe(count3.value);
    };

    onMounted(() => {
      setupObserver();

      // Create map instance
      let chart = am4core.create("chartdiv", am4maps.MapChart);

      // Set map definition
      chart.geodata = am4geodata_worldLow;

      // Disable the amCharts logo
      chart.logo.disabled = true;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = am4core.color("#e5e5e5");

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#cc0033");

      // Remove Antarctica
      polygonSeries.exclude = ["AQ"];

      // Bind "fill" property to "fill" key in data
      polygonTemplate.propertyFields.fill = "fill";

      // // Create image series
      // let imageSeries = chart.series.push(new am4maps.MapImageSeries());

      // // Create an image in image series template so it gets replicated to all new images
      // let imageSeriesTemplate = imageSeries.mapImages.template;
      // let image = imageSeriesTemplate.createChild(am4core.Image);
      // image.width = 16; // Adjust the width of the image as needed
      // image.height = 16; // Adjust the height of the image as needed
      // image.href = require("../assets/user-location.png"); // Replace "url_to_your_image" with the actual URL of your image
      // image.tooltipText = "{title}";

      // // Set property fields
      // imageSeriesTemplate.propertyFields.latitude = "latitude";
      // imageSeriesTemplate.propertyFields.longitude = "longitude";

      // // Add data for the cities
      // imageSeries.data = [
      //   { latitude: 28.7041, longitude: 77.1025, title: "India" },
      //   { latitude: 22.5726, longitude: 88.3639, title: "Kolkata" },
      //   { latitude: 17.4065, longitude: 78.4772, title: "Hyderabad" },
      //   { latitude: 4.2105, longitude: 101.9758, title: "Malaysia" },
      //   { latitude: 12.8797, longitude: 121.774, title: "Philippines" },
      //   { latitude: 14.0583, longitude: 108.2772, title: "Vietnam" },
      //   { latitude: 0.0236, longitude: 37.9062, title: "Kenya" },
      // ];
    });

    return {
      count1,
      count2,
      count3,
    };
  },
};
</script>

<style scoped lang="less">
.main-wrapper {
  background: #fff;

  .international-patient {
    background: #0063a8;
    #chartdiv {
      height: 300px;
      @media (max-width: 1024px) {
        height: auto;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        display: block;
      }
      .banner {
        width: 66.67%;
        padding: 0 35px;
        @media (max-width: 767px) {
          width: 100%;
          padding: 0;
        }
      }
      .flags-container {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .flag {
          width: 60px;
          padding: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      // .stats-container {
      //   display: flex;
      //   justify-content: space-between;
      //   width: 80%;
      //   margin: 30px auto;
      //   flex-wrap: wrap;
      //   @media (max-width: 1600px) {
      //     width: 100%;
      //   }
      //   @media (max-width: 1024px) {
      //     width: 100%;
      //   }
      //   @media (max-width: 767px) {
      //     width: 100%;

      //     text-align: center;
      //   }
      //   .stats-2 {
      //     @media (max-width: 1024px) {
      //       width: 50% !important;
      //       margin-left: 0px !important;
      //       margin-right: 0px !important;
      //       margin-top: 20px;
      //     }
      //     @media (max-width: 767px) {
      //       width: 50% !important;
      //     }
      //   }
      //   .stats {
      //     margin-left: 10px;
      //     margin-right: 10px;
      //     @media (max-width: 1024px) {
      //       width: 100%;
      //     }
      //     @media (max-width: 767px) {
      //       width: 100%;
      //       margin-left: 0px;
      //       margin-right: 0px;
      //     }
      //     .stats-inner {
      //       @media (max-width: 767px) {
      //         padding-bottom: 20px;
      //       }

      //       .count {
      //         font-size: 32px;
      //         color: #fff;
      //         margin-bottom: 20px;
      //         text-align: center;
      //         line-height: 1.2;
      //         font-weight: 600;
      //         font-family: "FuturaMediumBT";
      //         @media (max-width: 1024px) {
      //           margin-bottom: 10px;
      //         }
      //         @media (max-width: 767px) {
      //           font-size: 24px;
      //         }
      //         &:after {
      //           content: "+";
      //         }
      //       }
      //       .stats-title {
      //         font-size: 14px;
      //         font-weight: 600;
      //         color: #fff;
      //         line-height: 1.4;
      //         text-align: center;

      //         font-family: "FuturaMediumBT";
      //         @media (max-width: 767px) {
      //           font-size: 12px;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }

  .international-section {
    .about-section {
      padding-left: 2rem;
      padding-right: 2rem;
      h2 {
        line-height: 1.2;
        color: #454545;
        text-align: center;
      }
      .intro-block {
        text-align: center;
        margin-bottom: 30px;
        .monitor {
          padding: 0;
          max-width: 200px;

          margin: 0 auto;
        }

        svg {
          max-width: 470px;
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          transition: all 0.5s ease-in-out;
          .ekg {
            fill: none;
            stroke: #0063a8;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: miter;
            opacity: 0;
            stroke-dashoffset: 1000;
            stroke-dasharray: 1000;
            animation: ekg 1.5s linear forwards infinite;
          }
        }
      }
      @keyframes ekg {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 1;
        }
        50% {
          stroke-dashoffset: 2000;
        }
        99% {
          opacity: 0;
          stroke-dashoffset: 3000;
        }
        100% {
          stroke-dashoffset: 1000;
        }
      }
      .boxes {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 30px;
        margin: 40px auto;
        @media (max-width: 767px) {
          grid-template-columns: auto auto;
          grid-gap: 30px;
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .counter-div {
            text-align: center;
            margin: 10px 0px;

            @media (max-width: 767px) {
              width: 100px;
            }
            span {
              color: #0e2d58;
              @media (max-width: 767px) {
                color: #0e2d58;
              }
            }
            p {
              color: #0e2d58;
              // border-top: 2px solid #3f6599;
              padding: 10px;
              word-break: break-word;
              @media (max-width: 767px) {
                color: #0e2d58;
              }
            }
          }
        }
      }
       .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
    }
  }
}
</style>
