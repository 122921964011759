<template>
  <div
    class="backdrop"
    @click="closevideohandler"
    :class="{ active: showvideo }"
  ></div>
  <div class="vision-wrapper">
    <div class="background-abtwrapper container">
   <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Vision and Mission</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Vision and Mission</h2>
    </div>
  </div>
</div>
      <div class="abt-wrapper">
        <div class="abt-img">
          <img class="desk-img" src="../assets/about-img.png" alt="img" />
          <span class="play-button cursoreffect" @click="videohandler">
            <img src="../assets/play-btn.png" />
          </span>
        </div>
        <div class="abt-content">
          <h3>VISION AND MISSION</h3>

          <div class="boxes">
            <div class="img">
              <img src="../assets/vission.png" />
            </div>
            <div class="text">
              <h4>Vision</h4>
              <p>
                Achieving unmatched standards of excellence that become a
                benchmark of healthcare practice through innovation, technology
                and compassionate care.
              </p>
            </div>
          </div>

          <div class="boxes">
            <div class="img">
              <img src="../assets/Mission 1.png " />
            </div>
            <div class="text">
              <h4>Mission</h4>
              <p>
                To provide integrated healthcare of global standards to patients
                from all walks of life and to establish ourselves as one of
                India's most trusted and advanced healthcare destinations.
              </p>
            </div>
          </div>
          <div class="boxes">
            <div class="img">
              <img src="../assets/Valu.png " />
            </div>
            <div class="text">
              <h4>Values</h4>
              <p>
                <b>Compassion – </b>Encouraging open communication, transparency
                and maintaining excellence in personal and professional
                conduct.<br />
                <b>Quality –</b> Adhering to the highest standards of patient
                care.<br />
                <b>Innovation – </b>Recognising and implementing new ideas.<br />
                <b>Collaboration –</b> Fostering efficiency and synergising to
                drive professional and organisational growth.<br />
                <b>Leadership –</b> Being approachable, dependable and inspiring
                to build trust and mutual respect.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- youtube video section -->

    <div class="video-wrapper" v-show="showvideo">
      <div class="video-section">
        <div class="close-icon cursoreffect">
          <img @click="closevideohandler" src="../assets/close.png" />
        </div>
        <iframe
          src="https://www.youtube.com/embed/TJA7V1Yx1XM?si=rMemSg5sC0hWFYOn"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <!-- accrediation section start -->
  <div class="accrediation-section">
    <div class="accred-banner">
      <div class="image">
        <img src="../assets/parallel.jpg" />
      </div>
      <div class="accred-badges">
        <div class="badge-wrapper cursoreffect" @click="gotoJCIpage">
          <div class="jci-logo">
       <img src="../assets/jci.png" />
          </div>
          <div class="text">
            <h3>JCI</h3>
            <h4>Joint Comission International</h4>
          </div>
        </div>
        <div class="badge-wrapper cursoreffect" @click="gotoNABHpage">
          <div class="nabh-logo">
            <img src="../assets/nabh.png" />
          </div>
          <div class="text">
            <h3>NABH</h3>
            <h4>youngest Hospital to get C green and NABH certification.</h4>
          </div>
        </div>
        <div class="badge-wrapper cursoreffect" @click="gotoNABLpage">
          <div class="nabl-logo">
            <img src="../assets/nabl.png" />
          </div>
          <div class="text">
            <h3>NABL</h3>
            <h4>2023 Milestone-We are NABL and JCI accredited</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

   import { useRouter } from "vue-router";
import { ref } from "vue";
import EcgComp from "../components/EcgComp.vue";
export default {
  name: "VisionComp",
  components: { EcgComp },
  setup() {
       const router = useRouter();
    const showvideo = ref(false);

    const videohandler = () => {
      showvideo.value = true;
      document.body.style.overflow = "hidden";
    };
    const closevideohandler = () => {
      showvideo.value = false;
      document.body.style.overflow = "";
    };
     const gotoJCIpage = () => {
      router.push("/JCI");
      window.scrollTo(0, 0);
    };
    const gotoNABHpage = () => {
      router.push("/NABH");
      window.scrollTo(0, 0);
    };
    const gotoNABLpage = () => {
      router.push("/NABL");
      window.scrollTo(0, 0);
    };
    return {
      videohandler,
      closevideohandler,
      gotoNABLpage,
      gotoNABHpage,
      showvideo,
      gotoJCIpage
    };
  },
};
</script>

<style lang="less" scoped>
.vision-wrapper {
  .background-abtwrapper {
    // background: #f9f9f9;
  .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
    .abt-wrapper {
      position: relative;
      // background-color: #f9f9f9;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      align-items: center;

      @media (max-width: 1024px) {
        display: block;
      }

      @media (max-width: 767px) {
        display: block;
      }

      .abt-img {
        img {
          width: 100%;
        }
        .play-button {
          box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

          cursor: pointer;
          position: absolute;
          left: 13%;
          top: 100px;
          padding: 18px;
          background: #fff;
          border-radius: 50%;
          @media (max-width: 1024px) {
            left: 27%;
          }
          @media (max-width: 767px) {
            padding: 14px;
            left: 22%;
            top: 74px;
          }
          img {
            width: auto;
          }
        }
      }

      .abt-content {
        h3 {
          margin-bottom: 30px;
          text-align: left;

          @media (max-width: 1024px) {
            margin-bottom: 13px;
          }

          @media (max-width: 767px) {
            margin-bottom: 14px;
            margin-top: 14px;
          }
        }
      }

      .boxes {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        img {
          height: 55px;
          width: 55px;

          margin-right: 10px;

          @media (max-width: 767px) {
            height: 40px;
            width: 40px;
          }
        }

        span {
          margin-left: 20px;
          font-size: 18px;

          @media (max-width: 1024px) {
            font-size: 16px;
          }

          @media (max-width: 767px) {
            font-size: 14px;
          }
        }

        .text {
          h4 {
            margin-bottom: 8px;
            text-transform: uppercase;
          }

          p {
            line-height: 1.6;

            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .video-section {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    .close-icon {
      position: absolute;
      top: -34px;
      right: -32px;
      width: 20px;
      height: 20px;
      @media (max-width: 767px) {
        top: -28px;
        right: -5px;
      }
      img {
        width: 100%;
        cursor: pointer;
      }
    }
    iframe {
      width: 900px;
      height: 700px;
      @media (max-width: 1600px) {
        width: 700px;
        height: 500px;
      }
      @media (max-width: 1024px) {
        width: 700px;
        height: 700px;
      }
      @media (max-width: 767px) {
        width: auto;
        height: 315px;
      }
    }
  }
}
//   backdrop css
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}

.backdrop.active {
  display: block;
}
.accrediation-section {
  .accred-banner {
    position: relative;
    .image {
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .accred-badges {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -27px;
    @media (max-width: 1024px) {
      width: 95%;
      margin: auto;
    }
    .badge-wrapper {
      cursor: pointer;
      border-radius: 10px;
      position: relative;
      background-color: #fff;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;
      padding: 20px 15px;
      @media (max-width: 1024px) {
        height: 176px;
        padding: 0px;
      }
      @media (max-width: 767px) {
        display: block;
        height: 147px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -6px;
        right: 50%;
        width: 26px;
        height: 22px;
        border-color: #ffffff transparent transparent #fefeff;
        background-color: #ffffff;
        background-color: #ffffff;
        transform: rotate(-221deg);

        @media (max-width: 767px) {
          right: 43%;
          height: 16px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .jci-logo {
        width: 50%;
        height: 120px;
        @media (max-width: 767px) {
          height: 50px;
          margin: 15px auto auto auto;
        }
      }
      .text {
        width: 50%;
        @media (max-width: 767px) {
          padding: 0px 10px;
          width: 100%;
        }
        h3 {
          @media (max-width: 767px) {
            font-size: 12px;
            padding: 5px 0px;
          }
        }
        h4 {
          @media (max-width: 767px) {
            font-size: 10px;
          }
        }
      }

      .nabh-logo {
        width: 50%;
        height: 120px;
        @media (max-width: 767px) {
          height: 50px;
          margin: 15px auto auto auto;
        }
      }
      .nabl-logo {
        width: 50%;
        height: 120px;
        @media (max-width: 767px) {
          height: 50px;
          margin: 15px auto auto auto;
        }
      }
    }
  }
}
</style>