<template>
  <div class="main-wrapper">
    <div class="donation-wrapper">
      <div class="donatediv container">
        <div class="img">
          <img src="../assets/heart-icon.svg" />
        </div>
        <div class="title">
          <h2>Life is precious, we care for it</h2>
        </div>

        <div class="btn">
          <button class="cursoreffect">
            <a href="https://noblehrc.com/patient-portal" target="_blank">
              Book an appointment</a
            >
          </button>
        </div>
      </div>
    </div>
    <!-- footer start -->
    <div class="footer-wrapper">
      <div class="top-footer container">
        <div
          class="about-noble-section"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div class="images-wrapper">
            <img
              @click="gotohomepage"
              class="logo-img cursoreffect"
              src="../assets/noble-logo.png"
            />
            <!-- <img
              class="badgesmob-img cursoreffect"
              src="../assets/badges.png"
            /> -->
          </div>

          <p>
            Noble Hospitals & Research Centre is a 350-bedded, quaternary care
            hospital in Hadapsar, Pune — including a chain of polyclinics — that
            was started with an aim to provide high-quality integrated
            healthcare to patients at an affordable cost. Currently, the
            hospital has over 66 ICU beds, 130+ doctors, 30+ clinical
            departments, 8 certified cGreen operation theatre complexes, 24x7
            Emergency care (backed by the latest radio-diagnostic technology),
            and 32 outpatient consultation rooms.
          </p>
          <h3 class="head-top">About</h3>

          <p>
            Noble Hospitals is not limited merely to treating illnesses but aims
            to foster an environment conducive to wellness too.
          </p>
        </div>
        <div class="about" data-aos="fade-up" data-aos-duration="3000">
          <h3>About Noble</h3>
          <ul>
            <li>
              <p><span class="cursoreffect">Overview</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Message From Chairman</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Accrediations</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Awards & Accolades</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Gallery</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Covid 19 Screening</span></p>
            </li>
          </ul>
          <h3 class="head-top">Regulatory</h3>
          <ul>
            <li>
              <p>
                <span class="cursoreffect"
                  >Patients Rights & Responsibility</span
                >
              </p>
            </li>
            <li>
              <p><span class="cursoreffect">Accrediation</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Insurance & TPA</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Preventive Health Package</span></p>
            </li>
          </ul>
        </div>
        <div class="quick-links" data-aos="fade-up" data-aos-duration="3000">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <p>
                <span class="cursoreffect" @click="gotoabout">About Us</span>
              </p>
            </li>

            <li>
              <p><span class="cursoreffect">Services</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Media</span></p>
            </li>
            <li>
              <p>
                <span class="cursoreffect" @click="gotocontact"
                  >Contact Us</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="cursoreffect" @click="gotocareer">Careers</span>
              </p>
            </li>
            <li>
              <p><span class="cursoreffect">Bio Medical Waste</span></p>
            </li>
          </ul>
          <h3 class="head-top">International Patients</h3>
          <ul>
            <li>
              <p><span class="cursoreffect">About Us</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Services</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Media</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Contact Us</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Careers</span></p>
            </li>
            <li>
              <p><span class="cursoreffect">Bio Medical Waste</span></p>
            </li>
          </ul>
        </div>
        <div class="contact" data-aos="fade-up" data-aos-duration="3000">
          <h3>Locations</h3>
          <ul>
            <li>
              <p><span class="cursoreffect">Noble Hospitals</span></p>
            </li>
            <li>
              <p>
                <span class="cursoreffect"
                  >Multispeciality Clinic Magarpatta</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="cursoreffect">Multispeciality Clinic Amanora</span>
              </p>
            </li>
            <li>
              <p>
                <span class="cursoreffect"
                  >Samruddhi Diagnostic Center - Sasanenagar</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="cursoreffect"
                  >Purandhar Diagnostic Centre- Saswad
                </span>
              </p>
            </li>
          </ul>
          <h3 class="head-top">Payment Options</h3>
          <ul>
            <li>
              <p><span class="cursoreffect">Pay Online</span></p>
            </li>
          </ul>
          <h3 class="head-top">Contact</h3>
          <p>
            <span class="soc-icon"><img src="../assets/call.png" /></span
            >+91-22-69315555 (Appointments)
          </p>
          <p>
            <span class="soc-icon"><img src="../assets/email.png" /></span
            >noblecares@noblehrc.com
          </p>
          <p>
            <span class="soc-icon"><img src="../assets/loc.png" /></span>Noble
            Hospitals, 153, Magarpatta City Road, Hadapsar, Pune - 411013
          </p>
        </div>
      </div>
      <!-- social-icons footer -->
      <div class="social-icons-wrapper container">
        <div class="socials-cons">
          <h3>Follow Noble Hospitals</h3>
          <div class="icons-wrapper">
            <div class="soc">
              <a
                target="_blank"
                href="https://www.facebook.com/noblehospitalsandresearchcentre"
              >
                <img class="cursoreffect" src="../assets/fb.png"
              /></a>
            </div>
            <div class="soc">
              <a
                target="_blank"
                href="https://www.instagram.com/noblehospitalsresearchcentre/?hl=en"
                ><img class="cursoreffect" src="../assets/insta.png"
              /></a>
            </div>
            <div class="soc">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCPJc26PyakSxH1pi9XZTjew"
                ><img class="cursoreffect" src="../assets/utube.png"
              /></a>
            </div>
            <div class="soc">
              <a
                target="_blank"
                href="https://in.linkedin.com/company/noble-hospitals-and-research-centre"
                ><img class="cursoreffect" src="../assets/linkedin.png"
              /></a>
            </div>
            <div class="soc">
              <a target="_blank" href="#"
                ><img class="cursoreffect" src="../assets/twitter.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <!-- bottom footer -->
      <div class="bottom-footer">
        <div class="page-container container">
          <ul>
            <li @click="navigatetoterms">
              <p class="cursoreffect">Terms & Conditions</p>
            </li>
            <li @click="navigatetoprivacy">
              <p class="cursoreffect">Privacy Policy</p>
            </li>

            <li><p class="cursoreffect">Site Map</p></li>
          </ul>
        </div>
        <div class="copy-right-section container">
          <p>Copyright © 2024 Noble Hospitals.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AOS from "aos";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
export default {
  name: "FooterComp",
  setup() {
    const router = useRouter();
    onMounted(() => {
      setTimeout(() => {
        AOS.init();
      }, 100);
    });
    const gotohomepage = () => {
      router.push("/");
      window.scrollTo(0, 0);
    };
    const gotodocdetail = () => {
      router.push("/doctors-detail");
      window.scrollTo(0, 0);
    };
    const navigatetoterms = () => {
      router.push("/terms-conditions");
      window.scrollTo(0, 0);
    };
    const navigatetoprivacy = () => {
      router.push("/privacy-policy");
      window.scrollTo(0, 0);
    };

    const gotoJCIpage = () => {
      router.push("/JCI");
      window.scrollTo(0, 0);
    };
    const gotoNABHpage = () => {
      router.push("/NABH");
      window.scrollTo(0, 0);
    };
    const gotoNABLpage = () => {
      router.push("/NABL");
      window.scrollTo(0, 0);
    };
    const gotoabout = () => {
      router.push("/about-us");
      window.scrollTo(0, 0);
    };
    const gotocontact = () => {
      router.push("/contact-us");
      window.scrollTo(0, 0);
    };
    const gotocareer = () => {
      router.push("/careerpage");
      window.scrollTo(0, 0);
    };
    return {
      gotohomepage,
      gotoJCIpage,
      gotoNABHpage,
      gotoNABLpage,
      navigatetoterms,
      gotocontact,
      gotocareer,
      gotodocdetail,
      gotoabout,
      navigatetoprivacy,
    };
  },
};
</script>

<style lang="less" scoped>
.main-wrapper {
  .donation-wrapper {
    background: #1360a4;
    .donatediv {
      display: flex;
      justify-content: space-between;
      padding: 40px 20px;
      align-items: center;
      @media (max-width: 1024px) {
        width: 100%;
        display: block;
      }
      @media (max-width: 767px) {
        width: 100%;
        display: block;
      }
      .title {
        padding: 0px 20px;
        width: 45%;
        @media (max-width: 1024px) {
          width: 100%;
          padding-bottom: 20px;
        }
        @media (max-width: 767px) {
          width: 100%;
          padding-bottom: 20px;
        }
        h2 {
          color: #fff;
          @media (max-width: 767px) {
            text-align: left;
          }
        }
      }
      .para {
        width: 25%;
        padding: 0px 20px;
        @media (max-width: 1024px) {
          width: 100%;
          padding-bottom: 20px;
        }
        @media (max-width: 767px) {
          width: 100%;
          padding-bottom: 20px;
        }
        h4 {
          color: #fff;
        }
      }
      .img {
        width: 20%;
        display: flex;
        justify-content: center;
        @media (max-width: 1024px) {
          display: flex;
          justify-content: center;

          margin: auto;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        img {
          width: 150px;
        }
      }
      .btn {
        width: 30%;
        display: flex;
        justify-content: center;
        @media (max-width: 1024px) {
          width: 100%;
        }

        button {
          border-radius: 50px;
          background: #012d61;
          color: #fff;
          border: none;
          font-size: 14px;
          cursor: pointer;
          padding: 18px 67px;
          @media (max-width: 767px) {
            width: 100%;
          }
          p {
            font-size: 20px;
            font-weight: 400;
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
  .footer-wrapper {
    background: #012d61;

    .top-footer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .head-top {
        padding-top: 30px;
      }
      .about-noble-section {
        width: 25%;

        @media (max-width: 1024px) {
          width: 100%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        .images-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .logo-img {
            width: 200px;
            @media (max-width: 767px) {
              width: 150px;
            }
          }
          .badgesmob-img {
            display: none;
            width: 200px;
            @media (max-width: 1024px) {
              display: block;
              height: 100%;
              object-fit: contain;
            }

            @media (max-width: 576px) {
              display: none;
            }
          }
        }
        .badges-img {
          width: 200px;

          @media (max-width: 1024px) {
          }
        }
        p {
          cursor: pointer;

          color: #fff;

          // padding-top: 15px;
          // padding-bottom: 15px;
          @media (max-width: 1024px) {
            padding-bottom: 30px;
          }
        }
        img {
          width: 100%;
        }
      }
      .about {
        width: 30%;
        padding-left: 5%;
        @media (max-width: 1024px) {
          width: 33.33%;
          padding-left: 0%;
        }
        @media (max-width: 767px) {
          width: 50%;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
        p {
          color: #fff;
        }
      }
      .quick-links {
        width: 20%;
        @media (max-width: 1024px) {
          width: 33.33%;
        }
        @media (max-width: 767px) {
          width: 50%;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
        p {
          color: #fff;
        }
      }
      .contact {
        width: 25%;
        @media (max-width: 1024px) {
          width: 33.33%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
        .badges-img {
          width: 150px;
          padding-top: 15px;
          @media (max-width: 1024px) {
            display: none;
          }
          @media (max-width: 576px) {
            display: block;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      h3 {
        margin: 0 0 10px;
        color: #fff;
      }
      ul {
        list-style: none;

        li {
          line-height: 24px;
          color: #fff;
          margin: 0 0 10px 0;
          a {
            color: #fff;
            text-decoration: none;
          }
        }
        span {
          cursor: pointer;
        }
      }
      .contact {
        p {
          line-height: 24px;
          color: #fff;

          display: flex;
        }
        .soc-icon {
          img {
            width: 30px;
            padding-right: 10px;
          }
        }
      }
    }
    .social-icons-wrapper {
      border-top: 1px solid #fff;
      display: flex;
      padding: 20px 0px;
      justify-content: space-between;
      h3 {
        color: #fff;
      }
      .badges-img {
        width: 150px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        @media (max-width: 767px) {
          width: auto;
        }
        img {
          width: 52px;
          object-fit: contain;
          height: 52px;
          @media (max-width: 767px) {
            width: 35px;

            object-fit: contain;
            height: 35px;
          }
        }
      }
    }
    .socials-cons {
      .icons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .soc {
          width: 40px;
          height: 40px;

          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            padding-right: 10px;
            object-fit: contain;
            margin-top: 10px;

            @media (max-width: 767px) {
              padding-right: 5px;
              width: 35px;
              margin-top: 0px;
            }
          }
        }
      }
    }
    .bottom-footer {
      background: #e1f0fe;
      .page-container {
        padding: 40px 0px;

        ul {
          display: flex;
          justify-content: center;
          list-style: none;

          a {
            text-decoration: none;
          }
          p {
            cursor: pointer;
            color: #000;
            text-align: center;
            padding: 0px 30px;
            @media (max-width: 1024px) {
              line-height: 2;
            }
            @media (max-width: 767px) {
              padding: 0px 10px;
            }
          }
        }
      }
      .copy-right-section {
        border-top: 1px solid #d4d4d4;
        padding: 10px 0px;
        p {
          color: #000;
          text-align: center;

          padding: 12px;
        }
      }
    }
  }
}
</style>
