<template>
  <div class="main-container">
    <div class="news-section container">
      <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>News & Events</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>News & Events</h2>
    </div>
  </div>
</div>
      <div
        class="newsevent-section"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <!-- blog section -->
        <div class="blog">
          <img src="../assets/blog.jpg" />
          <div class="card-desk">
            <div class="media-text">
              <h4>Feb 14, 2024</h4>
              <h5>
                Noble announces the launch of monthly talk show around cardiac
                care – Heart 2 Heart – featuring leading cardiac experts across
                India
              </h5>
              <div class="common-button">
                <p class="anchor-button cursoreffect">
                  Read Post <span><img src="../assets/next.png" /></span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- blog list -->
        <div class="blog-list" data-aos="fade-up" data-aos-duration="2000">
          <div class="news-media" v-for="blog in blogs" :key="blog">
            <div class="media-image">
              <img :src="blog.img" alt='"Saree Walk"' />
            </div>
            <div class="media-text">
              <h4>{{ blog.date }}</h4>
              <h5>{{ blog.title }}</h5>
              <div class="common-button">
                <p class="anchor-button cursoreffect">
                  Read Post <span><img src="../assets/next.png" /></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- upcoming events -->
        <UpcomingEvents />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import UpcomingEvents from "./UpcomingEvents.vue";
import EcgComp from "./EcgComp.vue";

export default {
  components: {
    UpcomingEvents,
    EcgComp,
  },
  setup() {
    const blogs = reactive([
      {
        img: "https://www.medanta.org/storage/posts/March2024//nVIc7Ol0epqjwErDuRt2ALTtdc19zF-metaTWVkYW50YSBJbmRvcmUgU2FyZWUgV2Fsay5qcGc=-.jpg",
        date: "Feb 14, 2024",
        title: "Noble Super Specialty Hospital Hosts ",
      },
      {
        img: "https://www.medanta.org/storage/posts/March2024//EzLqAqSGtOAyr7GXCJa2qnfDbOKKcg-metaTWVkYW50YSBTaGFrdGkgLSBMYW1wIGxpZ2h0aW5nLmpwZw==-.jpg",
        date: "Feb 14, 2024",
        title: "Noble unveils the first edition of its annual women’s conclave",
      },
      {
        img: "https://www.medanta.org/storage/posts/March2024//nVIc7Ol0epqjwErDuRt2ALTtdc19zF-metaTWVkYW50YSBJbmRvcmUgU2FyZWUgV2Fsay5qcGc=-.jpg",
        date: "Feb 14, 2024",
        title: "Noble Super Specialty Hospital Hosts ",
      },
    ]);
    return {
      blogs,
    };
  },
};
</script>
<style lang="less" scoped>
.main-container {
  background: #fafafa;
  .news-section {
     .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
    .newsevent-section {
      display: flex;

      justify-content: space-between;
      width: 100%;
      @media (max-width: 1024px) {
        display: block;
      }
      @media (max-width: 767px) {
        display: block;
      }
      .blog {
        flex: 0 0 30%;
        width: 30%;
        @media (max-width: 1024px) {
          flex: 0 0 100%;
          width: 100%;
        }
        @media (max-width: 767px) {
          flex: 0 0 100%;
          width: 100%;
        }
        img {
          width: 100%;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        .card-desk {
          padding: 30px;
          cursor: pointer;
          background: #fff;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          h4 {
            color: #58595b;
          }
          h5 {
            color: #58595b;
            margin: 10px 0 10px;
          }

          p {
            padding: 10px 0;

            line-height: 1.6;

            margin-bottom: 30px;

            color: #58595b;
          }
          .anchor-button {
            font-family: "FuturaMediumBT";
            display: inline-flex;
            gap: 10px;

            align-items: center;
            cursor: pointer;
            color: #58595b;
            &:hover {
              color: #0063a8;
            }
            span {
              line-height: 18px;
              width: 18px;
              height: 18px;

              img {
                background-color: #0063a8;
                border-radius: 50%;
              }
            }
          }
        }
      }
      .blog-list {
        flex: 0 0 35%;
        width: 35%;
        padding: 0px 20px;
        @media (max-width: 1024px) {
          flex: 0 0 100%;
          width: 100%;
          padding: 20px 0px;
        }
        @media (max-width: 767px) {
          flex: 0 0 100%;
          width: 100%;
          padding: 20px 0px;
        }
        .news-media {
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px;
          gap: 16px;
          align-items: center;
          @media (max-width: 1024px) {
            justify-content: flex-start;
          }
          .media-image {
            max-width: 220px;
            width: 50%;
            @media (max-width: 1024px) {
              max-width: 284px;
              width: 50%;
              margin-right: 20px;
            }
            img {
              width: 100%;
              border-radius: 10px;
            }
          }
          .media-text {
            width: 50%;
            h4 {
              color: #58595b;
            }
            h5 {
              margin: 10px 0 10px;
              color: #58595b;
              @media (max-width: 576px) {
                padding-top: 10px;
                padding-bottom: 10px;
                margin: 0px 0px 0px;
              }
            }
            p {
              padding: 10px 0;

              line-height: 1.6;

              color: #58595b;
            }
            .anchor-button {
              font-family: "FuturaMediumBT";
              display: inline-flex;
              gap: 10px;

              align-items: center;
              cursor: pointer;
              color: #58595b;

              padding-top: 0px;

              &:hover {
                color: #0063a8;
              }
              span {
                line-height: 18px;
                width: 18px;
                height: 18px;
                img {
                  background-color: #0063a8;
                  border-radius: 50%;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
