<template>
  <AboutSpeciality />
  <ChairmanDetail />
  <SpecialityDetailSlider/>
  <TreatmentSpeciality/>
  <TechnologyComp/>
  <PatientStory/>
</template>
<script>
import ChairmanDetail from "../components/ChairmanDetail.vue";
import AboutSpeciality from "../components/AboutSpeciality.vue";
import SpecialityDetailSlider from "../components/SpecialityDetailSlider.vue";
import TreatmentSpeciality from '../components/TreatmentSpeciality.vue'; 
import TechnologyComp from '../components/TechnologyComp.vue';
import PatientStory from "../components/PatientStory.vue";
export default {
  name: "SpecialityDetail",
  components: {
    AboutSpeciality,
    ChairmanDetail,
    SpecialityDetailSlider,
    TreatmentSpeciality,
    TechnologyComp,
    PatientStory,
  },
};
</script>

<style lang="less" scoped></style>
