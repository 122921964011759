<template>
  <div class="speciality-slider">
    <div class="container">
      <div class="slidercomp">
        <div class="row-content">
          <h2>Cardiac Care</h2>

          <p>{{ specialityCard.content }}</p>
          <button>Book an Appoinment</button>
        </div>

        <div class="row carousel" ref="carousel">
          <div
            class="card col-sm-4 col-md-3"
            v-for="(card, index) in cards"
            :key="index"
          >
            <div class="card-content">
              <div class="card">
                <div class="img">
                  <img :src="card.img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, computed } from "vue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";

export default {
  name: "AboutSpeciality",
  setup() {
    const carousel = ref(null);
    const activeIndex = ref(0);

    const cards = reactive([
      {
        img: require("../assets/heart.png"),
        content:
          "End-to-end comprehensive, holistic care of all heart conditions for adults and children",
      },
      {
        img: require("../assets/heart.png"),
        content:
          "Multidisciplinary team of doctors backed by nursing staff specially trained in cardiac care",
      },
      {
        img: require("../assets/heart.png"),
        content:
          "Pioneers in use of next- generation therapies for patients in advanced stages of heart failure",
      },
      {
        img: require("../assets/heart.png"),
        content:
          "Pioneers in use of next- generation therapies for patients in advanced stages of heart failure",
      },
      {
        img: require("../assets/heart.png"),
        content:
          "Availability of round-the- clock specialised Emergency services",
      },
      {
        img: require("../assets/heart.png"),
        content:
          "Pioneers in use of next- generation therapies for patients in advanced stages of heart failure",
      },
    ]);

    const specialityCard = computed(() => cards[activeIndex.value]);
    const totalCards = computed(() => cards.length);
    const formattedActiveIndex = computed(() =>
      String(activeIndex.value + 1).padStart(2, "0")
    );
    const formattedTotalCount = computed(() =>
      String(totalCards.value).padStart(2, "0")
    );
    const progressBarWidth = computed(
      () => `${((activeIndex.value + 1) / totalCards.value) * 100}%`
    );

    onMounted(() => {
      $(carousel.value)
        .slick({
          slidesToShow: 1,
          autoplayHoverPause: false,
          slidesToScroll: 1,
          rows: 1,
          autoplay: true,
          arrows: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 776,
              settings: {
                slidesToShow: 1,
                rows: 1,
              },
            },
          ],
        })
        .on("afterChange", function (event, slick, currentSlide) {
          activeIndex.value = currentSlide;
        });
    });

    return {
      carousel,
      cards,
      specialityCard,
      formattedActiveIndex,
      formattedTotalCount,
      progressBarWidth,
    };
  },
};
</script>

<style lang="less" scoped>
.speciality-slider {
  background-color: #f4f4f4;
  .testimonials-carousel-controller {
    position: absolute;
    left: 40%;
    bottom: 0px;
    margin-top: 20px;
    @media (max-width: 1024px) {
      left: 33%;
    }
    @media (max-width: 767px) {
      display: none;
    }
    .active-controller {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }
    .controller-devided {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }

    .total-controller {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }

    .controller-progress-bar {
      width: 100%;
      height: 5px;
      background: #f0f0f0;
      margin-top: 10px;

      .controller-progress {
        height: 100%;
        background: #0063a8;
      }
    }
  }
  .slidercomp {
    display: flex;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .row.carousel.slick-initialized.slick-slider {
      width: 50%;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
    .row-content {
      width: 50%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 576px) {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 40px 0px 20px;
        text-align: center;
      }
      h2 {
        text-align: left;
        font-size: 70px;
        margin-bottom: 0px;
        @media (max-width: 1280px) {
          margin-bottom: 0px;
          font-size: 50px;
        }
        @media (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 992px) {
          font-size: 35px;
        }
        @media (max-width: 767px) {
          margin-bottom: 0px;
          font-size: 30px;
        }
      }
      p {
        padding-top: 5px;
        font-size: 30px;
        @media (max-width: 1280px) {
          margin-bottom: 0px;
          font-size: 25px;
        }
        @media (max-width: 1024px) {
          font-size: 24px;
        }
        @media (max-width: 992px) {
          font-size: 23px;
        }

        @media (max-width: 767px) {
          padding-top: 0px;
          font-size: 22px;
        }
      }
      button {
        margin-top: 25px;
        width: 240px;
        background: #64c698;
        border: 1px solid transparent;
        color: #fff;
        padding: 14px 20px;
        font-size: 20px;
        border-radius: 50px;
        @media screen and (max-width: 1280px) {
          width: 220px;
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          width: 210px;
          font-size: 17px;
        }
      }
    }
    .card-content {
      margin: 0px 10px;
      .img {
        width: 100%;
        height: auto;
        // @media screen and (max-width: 992px) {
        //   height: 300px;
        // }
        // @media screen and (max-width: 480px) {
        //   height: 250px;
        // }
        img {
          width: 100%;
          height: 100%;
          padding: 10px;
          object-fit: contain;
        }
      }
    }
  }
}

:deep(.slick-slide.slick-active .card-content .img img) {
  width: 100% !important;
  height: 300px !important;
  // transition: all 0.5s ease;
  @media (max-width: 1500px) {
    width: 100% !important;
    height: 189px !important;
  }
  @media (max-width: 1024px) {
    width: 100% !important;
    height: 276px !important;
  }
}
:deep(.slick-track) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
:deep(.slick-slide.slick-current.slick-active .card-content .img img) {
  width: 100% !important;
  height: 100% !important;
}
:deep(.slick-prev) {
  display: none !important;
  left: -100% !important;
  bottom: 0px;
  right: 0px;
  width: 40px;
  margin-top: 20px;
  margin-right: 10px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;

  @media (max-width: 1600px) {
    top: 70%;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
    top: 85% !important;
  }
  @media (max-width: 380px) {
    top: 98% !important;
  }
}

:deep(.slick-next) {
  left: -94% !important;
  bottom: 0px;
  display: none !important;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;

  @media (max-width: 1600px) {
    top: 70%;
    left: -93% !important;
  }

  @media (max-width: 1280px) {
    top: 70%;
    left: -91% !important;
  }
  @media (max-width: 1024px) {
    left: -85% !important;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
    top: 85% !important;
    left: -74% !important;
  }
  @media (max-width: 380px) {
    top: 98% !important;
  }
}
</style>
