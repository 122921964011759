<template>
  <div class="cursor" ref="cursor"></div>
  <SpecialityList />
  <CenterExcellence />
</template>
<script>
import SpecialityList from "../components/SpecialityList.vue";
import CenterExcellence from "../components/CenterExcellence.vue";
import { ref, onMounted } from "vue";
export default {
  name: "SpecialityPage",
  components: {
    SpecialityList,
    CenterExcellence,
  },
  setup() {
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); 
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); 
        });
      });
    });

    return {
      cursor,
    };
  },
};
</script>
