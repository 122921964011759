<template>
  <div class="doctorsay-slider">
    <div class="container">
      <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Our Doctor's Say</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Our Doctor's Say</h2>
    </div>
  </div>
</div>

      <div class="slidercomp">
        <div class="row-content">
          <h2>{{ currentCard.docname }}</h2>
          <h3>{{ currentCard.title }}</h3>
          <p>{{ currentCard.content }}</p>
        </div>

        <div class="testimonials-carousel-controller">
          <span class="active-controller">{{ formattedActiveIndex }}</span>
          <span class="controller-devided">/</span>
          <span class="total-controller">{{ formattedTotalCount }}</span>
        </div>
        <div class="row carousel" ref="carousel">
          <div
            class="card col-sm-4 col-md-3"
            v-for="(card, index) in cards"
            :key="index"
          >
            <div class="card-content">
              <div class="card">
                <div class="img">
                  <img :src="card.img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive, computed } from "vue";
import EcgComp from "../components/EcgComp.vue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";

export default {
  name: "DoctorsSay",
  components: { EcgComp },
  setup() {
    const carousel = ref(null);
    const activeIndex = ref(0);

    const cards = reactive([
      {
        img: require("../assets/Our doctor 4.png"),
        docname: "Dr. Sangeeta Chandrashekhar",
        title: "Anaesthesia",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita.Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 2.png"),
        docname: "Dr Girija A. Patil",
        title: "Breast Care Center",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 3.png"),
        docname: "Dr Sujit S Sawadatkar",
        title: "Cardiac Sciences",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 4.png"),
        docname: "Dr. Sangeeta Chandrashekhar",
        title: "Anaesthesia",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita.Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 3.png"),
        docname: "Dr. Sangeeta Chandrashekhar",
        title: "Anaesthesia",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 2.png"),
        docname: "Dr Girija A. Patil",
        title: "Breast Care Center",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 3.png"),
        docname: "Dr Sujit S Sawadatkar",
        title: "Cardiac Sciences",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
      {
        img: require("../assets/Our doctor 4.png"),
        docname: "Dr. Sangeeta Chandrashekhar",
        title: "Anaesthesia",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita.Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, expedita",
      },
    ]);

    const currentCard = computed(() => cards[activeIndex.value]);
    const totalCards = computed(() => cards.length);
    const formattedActiveIndex = computed(() =>
      String(activeIndex.value + 1).padStart(2, "0")
    );
    const formattedTotalCount = computed(() =>
      String(totalCards.value).padStart(2, "0")
    );
    const progressBarWidth = computed(
      () => `${((activeIndex.value + 1) / totalCards.value) * 100}%`
    );
 onMounted(() => {
      $(carousel.value)
        .slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
          arrows: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                rows: 1,
              },
            },
          ],
        })
        .on("afterChange", function (event, slick, currentSlide) {
          activeIndex.value = currentSlide;
        });
    });

    return {
      carousel,
      cards,
      currentCard,
      formattedActiveIndex,
      formattedTotalCount,
      progressBarWidth,
    };
  },
};
</script>
<style lang="less" scoped>
.doctorsay-slider {
    .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .testimonials-carousel-controller {
    position: absolute;
    left: 40%;
    bottom: 0px;
    margin-top: 20px;
    @media (max-width: 1024px) {
      left: 33%;
    }
    @media (max-width: 767px) {
      display: none;
    }
    .active-controller {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }
    .controller-devided {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }

    .total-controller {
      color: #838282;
      font-size: 30px;
      line-height: 40px;
    }

    .controller-progress-bar {
      width: 100%;
      height: 5px;
      background: #f0f0f0;
      margin-top: 10px;

      .controller-progress {
        height: 100%;
        background: #0063a8;
      }
    }
  }
  .slidercomp {
    display: flex;
    justify-content: space-between;
    position: relative;
    .row.carousel.slick-initialized.slick-slider {
      width: 50%;
    }
    .row-content {
      width: 50%;
      h2 {
        text-align: left;
        font-size: 24px;
        @media (max-width: 767px) {
          margin-bottom: 0px;
          font-size: 18px;
        }
      }
      p {
        padding-top: 20px;
        @media (max-width: 767px) {
          padding-top: 0px;
        }
      }
    }
    .card-content {
      margin: 0px 10px;
      .img {
        height: 400px;
        display: flex;
        align-items: end;
        @media (max-width: 1600px) {
          height: 385px;
        }
        @media (max-width: 1024px) {
          height: 400px;
        }
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
          height: 100%;
        }
      }
    }
  }
}

// :deep .slick-slide.slick-current.slick-active {
//   // transform: translateY(3px);
// }

// :deep .slick-slide.slick-active {
//   //   transform: translateY(57px);
//   transform: scaleY(0.8);
// }
:deep(.slick-slide.slick-active .card-content .img img) {
  width: 100% !important;
  height: 300px !important;
  object-fit: cover;
  border-radius: 15px;
  // transition: all 0.5s ease;
  @media (max-width: 1500px) {
    width: 100% !important;
    height: 276px !important;
  }
  @media (max-width: 1024px) {
    width: 100% !important;
    height: 276px !important;
  }
}
:deep(.slick-track) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
:deep(.slick-slide.slick-current.slick-active .card-content .img img) {
  width: 100% !important;
  height: 100% !important;
}
:deep(.slick-prev) {
  left: -100% !important;
  bottom: 0px;
  right: 0px;
  width: 40px;
  margin-top: 20px;
  margin-right: 10px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;

  @media (max-width: 1600px) {
    top: 70%;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
    top: 85% !important;
  }
  @media (max-width: 380px) {
    top: 98% !important;
  }
}

:deep(.slick-next) {
  left: -94% !important;
  bottom: 0px;

  margin-top: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #0063a8;

  @media (max-width: 1600px) {
    top: 70%;
    left: -93% !important;
  }

  @media (max-width: 1280px) {
    top: 70%;
    left: -91% !important;
  }
  @media (max-width: 1024px) {
    left: -85% !important;
  }
  @media (max-width: 767px) {
    height: 35px;
    width: 35px;
    top: 85% !important;
    left: -74% !important;
  }
  @media (max-width: 380px) {
    top: 98% !important;
  }
}
</style>