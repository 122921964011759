<template>
  <div class="cursor" ref="cursor"></div>
  <div class="container">
    <h2>Meet the Doctor</h2>
    <EcgComp />
    <div class="main-container">
      <!-- left side doctor description -->
      <div class="left-section">
        <div class="description">
          <div class="specialisation">
            <h3>Specialization and Expertise</h3>
            <ul>
              <li>Minimal Invasive Spine Surgery</li>
              <li>Spine Degenerative Diseases and Deformity Correction</li>
              <li>Complex Trauma</li>
              <li>Hip and Pelvi-Acetabulum Surgery</li>
            </ul>
            <h3>Experience</h3>
            <ul>
              <li>12 Years</li>
            </ul>
            <h3>Qualification</h3>
            <ul>
              <li>
                MBBS (2006)<br />
                Topiwala National Medical College, Mumbai, Maharashtra
              </li>

              <li>
                DNB Orthopedics (2011)<br />
                Choithram Hospital and Research Centre, Indore, Madhya Pradesh
              </li>

              <li>
                Fellowship in Spine and Replacement (2014)<br />
                Cooper Hospital, Mumbai, Maharashtra
              </li>
            </ul>
            <!-- <h3>Location</h3>
            <ul>
              <li>Pune</li>
              <li>Banglore</li>
            </ul> -->
            <h3>Languages</h3>
            <ul>
              <li>Hindi| English | Marathi</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- center doctor profile -->
      <div class="center-section">
        <div class="doc">
          <div class="img-div">
            <img src="../assets/dc1.jpg" />
          </div>
          <div class="text-div">
            <h3>Dr. Vishal Chaudhari</h3>
            <p>DNB (Orthopedics ) & Spine Surgery Specialist</p>
            <div class="buttons">
              <div class="appoint-btn cursoreffect"><a href="https://noblehrc.com/patient-portal" target="_blank">Book an Appointment</a></div>
            </div>
          </div>
        </div>
      </div>
      <!-- left side doctor description -->
      <div class="similar-doctors">
        <div class="right-section">
          <div class="doctors-container">
            <div class="all-doctors">
              <div class="doc" v-for="(doctor, index) in doctors" :key="index">
                <div class="img-div">
                  <img :src="doctor.image" />
                </div>
                <div class="text-div">
                  <h5>{{ doctor.name }}</h5>
                  <div class="wrapper">
                    <p class="special">{{ doctor.specialization }}</p>
                    <div class="text-wrapper">
                      <p>
                        <span
                          ><img
                            class="loc-img"
                            src="../assets/location.png" /></span
                        >{{ doctor.location }}
                      </p>

                      <div class="buttons">
                        <img src="../assets/eye.png" class="cursoreffect" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- doctor biodata page -->
    <DoctorBiodata />
  </div>
</template>
<script>
import EcgComp from "../components/EcgComp.vue";
import { ref, onMounted } from "vue";
import DoctorBiodata from "../components/DoctorBiodata.vue";
export default {
  name: "DocdetailPage",
  components: {
    DoctorBiodata,
    EcgComp,
  },
  setup() {
    const doctors = [
      {
        name: "Dr. Vishal Chaudhari",
        specialization: "Spine Surgery Specialist ",
        image: require("../assets/dc1.jpg"),
        location: "Pune",
      },
      {
        name: "Dr.Pallavi Butiyani",
        specialization: "MD (Anesthesiology)",
        image: require("../assets/dc2.jpg"),
        location: "Pune",
      },
      {
        name: "Dr. Rahul Dadasaheb Sawant ",
        specialization: "MD, CCT (Cardiology), MRCP",
        image: require("../assets/dc3.jpg"),
        location: "Pune",
      },
      {
        name: "Dr. Z A Khan",
        specialization: "MBBS, MD (Internal Medicine)",
        image: require("../assets/dc4.jpg"),
        location: "Pune",
      },
      {
        name: "Dr.Reema Y Kashiva",
        specialization: "M.D (Internal Medicine)",
        image: require("../assets/dc5.png"),
        location: "Pune",
      },
      {
        name: "Dr. Sudhir Halikar",
        specialization: "MS (ENT) ",
        image: require("../assets/dc6.jpg"),
        location: "Pune",
      },
      {
        name: "Dr. Sheetal Uday Harpale",
        specialization: "B.Arch, PGD, MFAAT ",
        image: require("../assets/dc7.jpg"),
        location: "Pune",
      },
      {
        name: "Dr.S. K Raut",
        specialization: "MBBS, AFIH, DIH, PGCEOH",
        image: require("../assets/dc8.jpg"),
        location: "Pune",
      },
      {
        name: "Dr.R. Chandrashekhar",
        specialization: "MCh, DNB (Neuro Surgery), MS (General Surgery)",
        image: require("../assets/dc9.jpg"),
        location: "Pune",
      },
      {
        name: "Dr. Rajendra Gandhi",
        specialization: "M.Ch., Plastic Surgeon",
        image: require("../assets/dc10.jpg"),
        location: "Pune",
      },
    ];
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      doctors,
      cursor,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  .intro-block {
    text-align: center;
    margin-bottom: 30px;
    .monitor {
      padding: 0;
      max-width: 200px;

      margin: 0 auto;
    }

    svg {
      max-width: 470px;
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      transition: all 0.5s ease-in-out;
      .ekg {
        fill: none;
        stroke: #0063a8;
        stroke-width: 6;
        stroke-linecap: round;
        stroke-linejoin: miter;
        opacity: 0;
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
        animation: ekg 1.5s linear forwards infinite;
      }
    }
  }
  @keyframes ekg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      stroke-dashoffset: 2000;
    }
    99% {
      opacity: 0;
      stroke-dashoffset: 3000;
    }
    100% {
      stroke-dashoffset: 1000;
    }
  }

  .main-container {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;

    .left-section {
      background: #fff;
      border-radius: 12px;
      border-radius: 20px;
      width: 30%;
   
      @media (max-width: 1024px) {
        padding-bottom: 40px;
        width: 100%;
      }
      @media (max-width: 767px) {
        padding: 0px;
        margin-bottom: 40px;
        order: 2;
      }
      .description {
        box-shadow: 1px 1px 7px #cdcdcd;
        padding: 40px;
        border-radius: 20px;
        height: 100%;
        @media (max-width: 767px) {
          padding-left: 20px;
        }
      }
      h3 {
        text-align: left;
      }

      p {
        text-align: left;
        padding: 10px 0px;
      }
      ul {
        padding: 10px 0px;
        padding-left: 2em;
        li {
          line-height: 1.6;

          text-align: left;
        }
      }
    }
    .similar-doctors {
      width: 15%;
      height: 100%;
      @media (max-width: 1280px) {
        width: 20%;
      }
      @media (max-width: 1024px) {
        width: 30%;
      }
      @media (max-width: 767px) {
        width: 100%;
        order: 3;
      }
      h3 {
        padding-bottom: 16px;
        text-align: center;
      }
      .right-section {
        padding: 30px 16px;
        height: 680px;
        border-radius: 20px;
        overflow: auto;
        border: 1px solid #efeded;
        @media (max-width: 1600px) {
          padding: 0px 10px 20px;
          height: 600px;
        }
        @media (max-width: 1280px) {
          padding: 0px 10px 20px;
        }
        @media (max-width: 1024px) {
          // height: 55vh;
        }
        @media (max-width: 767px) {
          padding: 0px 30px 20px;
          // height: 90vh;
          order: 1;
        }
        .all-doctors {
          display: flex;
          flex-direction: column;

          .doc {
            transition: all 0.4s ease-in-out;
            cursor: pointer;
            background-color: #fff;
            box-shadow: 0 3px 17px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin: 20px auto;
            width: 100%;
            &:hover .text-div {
              height: 115px !important;
            }
            &:hover .text-div .wrapper {
              visibility: visible;
              opacity: 1;
              transform: translateY(0px);
            }
            &:hover h5 {
              overflow: visible;
              white-space: normal;
              text-overflow: unset;
            }
            @media (max-width: 767px) {
              height: auto;
              width: 100%;
            }
            &:hover {
              min-height: 250px;
              @media (max-width: 767px) {
                transform: none;
              }
            }
            .img-div {
              width: 100%;
              border-radius: 10px;
            }
            img {
              width: 100%;
              height: 100%;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
              object-fit: cover;
              transition: transform 0.4s ease-in-out;
            }
            .text-div {
              box-sizing: border-box;
              width: 100%;
              height: 45%;
              transition: height 0.4s ease-in-out, padding 0.4s ease-in-out;
              padding: 10px 10px;
              position: relative;
              @media (max-width: 767px) {
                padding: 10px;
              }
              h5 {
                color: #0063a8;
                padding-bottom: 0;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                transition: all 0.4s ease-in-out;
                &:hover {
                  color: #55b89c;
                }
              }
              .wrapper {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                background: #fff;
                visibility: hidden;
                top: 34px;
                position: absolute;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: translateY(-50px);
                transition: visibility 0.4s ease-in-out,
                  opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
                background: #fff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                p {
                  padding-top: 5px;
                }
                .text-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 10px;
                  width: 100%;
                  p {
                    display: flex;
                    margin-bottom: 0px;
                    font-size: 14px;
                  }
                }
              }
              .special {
                margin-bottom: 0px;
              }
              .loc-img {
                height: 14px;
                object-fit: contain;
                padding-right: 3px;
                margin-top: 4px;
              }
              p {
                margin-bottom: 15px;
                text-align: center;
                line-height: 24px;
                @media (max-width: 767px) {
                  margin-bottom: 0px;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  white-space: normal;
                }
              }
              .buttons {
                padding: 0px 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: 767px) {
                  flex-direction: column;
                  margin-bottom: 10px;
                }
                .view-btn {
                  color: #0063a8;
                  font-size: 12px;
                  text-decoration: underline;
                  padding-right: 10px;
                  @media (max-width: 767px) {
                    padding: 10px 10px;
                    font-size: 14px;
                  }
                }
                .appoint-btn {
                  border-radius: 0.25rem;
                  text-align: end;
                  margin: auto;
                  font-size: 13px;
                  color: #0063a8;
                  border: none;
                  cursor: pointer;
                  text-decoration: underline;
                  text-transform: uppercase;
                  font-weight: 500;
                  &:hover {
                    color: #032e53;
                  }
                  @media (max-width: 767px) {
                    width: 137px;
                    padding: 6px;
                  }
                 
                }
              }
            }
          }
        }
      }
    }

    .center-section {
      width: 55%;
      @media (max-width: 1280px) {
        width: 50%;
      }
      @media (max-width: 1024px) {
        width: 70%;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 40px;
      }
      .doc {
        border-radius: 20px;
        box-shadow: 1px 1px 7px #cdcdcd;
        padding: 40px;
        background: #f0fdff;
        height: 100%;
        margin: 0px 30px;
        @media (max-width: 1024px) {
          margin: 0px 30px 0px 0px;
        }
        @media (max-width: 767px) {
          margin: 0px;
        }
        @media (max-width: 767px) {
          padding: 20px;
        }
      }

      .img-div {
        width: 100%;
        margin: auto;

        @media (max-width: 1600px) {
          width: 100%;
          margin: auto;
        }

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
      .text-div {
        padding: 10px;
        box-sizing: border-box;

        padding-bottom: 0px;
        @media (max-width: 767px) {
          padding: 0px;
          width: 100%;
        }

        h3 {
          font-size: 25px;
          color: #0063a8;
          font-weight: 700;

          text-align: center;
          @media (max-width: 767px) {
            font-size: 20px;
          }
          &:hover {
            color: #55b89c;
          }
        }
        p {
          margin-bottom: 15px;

          color: #000;
          text-align: center;
          line-height: 24px;
        }
        .buttons {
          padding: 0px 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 767px) {
            flex-direction: column;
            margin-bottom: 10px;
          }
          .view-btn {
            color: #0063a8;
            font-size: 12px;
            text-decoration: underline;
            padding-right: 10px;

            @media (max-width: 767px) {
              padding: 10px 10px;
              font-size: 14px;
            }
          }
          .appoint-btn {
            border-radius: 0.25rem;
            text-align: center;
            font-size: 16px;
            margin: auto;
            padding: 14px 40px;
            color: #fff;
            background-color: #0063a8;
            border: none;
            cursor: pointer;
            transition: background-color, 0.4s ease-in-out;
            &:hover {
              background: #032e53;
            }
            @media (max-width: 1024px) {
              font-size: 9px;
              padding: 10px 20px;
            }
            @media (max-width: 767px) {
              font-size: 16px;
              padding: 15px 10px;
              width: 80%;
              margin: auto;
            }
             a{
                    color: #fff;
                    text-decoration: none;
                  }
          }
        }
      }
    }
  }
}
/* For WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #0063a8; /* Set the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #efefef; /* Set the color of the scrollbar thumb */
}

/* For Firefox */
/* Note: Firefox currently only supports scrollbar width and scrollbar color */
/* You can't directly style the scrollbar thumb in Firefox */
/* These styles will only change the scrollbar width and color */
/* Other properties may not work in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0063a8 #e2dfdf;
}
</style>
