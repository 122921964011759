<template>
  <!-- cursor effect -->
  <div class="cursor" ref="cursor"></div>
  <!-- contact section start -->
  <div class="contact-page">
     <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Contact Us</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Contact Us</h2>
    </div>
  </div>
</div>
    <div class="para-wrap">
      <p>
        We would love to hear from you! Please fill in the required details and
        our team will get in touch with you.
      </p>
    </div>
    <div class="main-wrapper container">
      <div class="contact-section">
        <h3>Contact Details</h3>
        <div class="contact-detail">
          <div class="img">
            <img src="../assets/phone-call.png" />
          </div>
          <div class="contact-item">
            <p><strong>Phone Number</strong></p>
            <p>+91-22-69315555</p>
          </div>
        </div>
        <div class="contact-detail">
          <div class="img">
            <img src="../assets/share.png" />
          </div>
          <div class="contact-item-follow">
            <p><strong>Follow Us</strong></p>
            <div class="imgs">
              <a target="_blank"
                href="https://www.facebook.com/noblehospitalsandresearchcentre"
              >
                <img src="../assets/fb.png" class="cursoreffect"
              /></a>
              <a target="_blank"
                href="https://www.instagram.com/noblehospitalsresearchcentre/?hl=en"
              >
                <img src="../assets/insta.png" class="cursoreffect"
              /></a>

              <a target="_blank"
                href="https://www.youtube.com/channel/UCPJc26PyakSxH1pi9XZTjew"
              >
                <img src="../assets/utube.png" class="cursoreffect"
              /></a>
              <a target="_blank"
                href="https://in.linkedin.com/company/noble-hospitals-and-research-centre"
                ><img class="cursoreffect" src="../assets/linkedin.png"
              /></a>
              <a href="#" target="_blank"
                ><img class="cursoreffect" src="../assets/twitter.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="feedback-section">
      
          <h3> Enquiry Form</h3>
         
     

        <!-- Enquiry form -->
        <div class="enquiry-form" >
          <div class="query-form">
            <div class="field"></div>

            <div>
              <form>
                <div class="field-wrap">
                  <div class="field">
                    <label>Name *</label>
                    <input
                      type="text"
                      class="inputbox"
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div class="field">
                    <label>Mobile number *</label>
                    <input
                      type="number"
                      class="inputbox"
                      placeholder="Enter Your Mobile Number"
                    />
                  </div>
                  <div class="field">
                    <label>Email *</label>
                    <input
                      type="email"
                      class="inputbox"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div class="field">
                    <label>Hospital *</label>
                    <select
                      class="inputbox time"
                      name="hospital"
                      placeholder="Select Hospital"
                    >
                      <option value="">Select Hospital</option>
                      <option value="1">Noble Gurugram</option>
                      <option value="2">Noble Lucknow</option>
                      <option value="3">Noble Patna</option>
                      <option value="4">Noble Indore</option>
                      <option value="5">Noble Ranchi</option>
                      <option value="7">Noble Mediclinic Subhash Chowk</option>
                      <option value="8">Noble Mediclinic Cybercity</option>
                      <option value="9">Noble Mediclinic South Delhi</option>
                    </select>
                  </div>
                </div>
                <label>Your Message *</label><br />

                <textarea
                  name="feedback_message"
                  placeholder="Enter Your Feedback Here"
                  class="inputbox"
                ></textarea>
                <div class="btn">
                  <button type="submit" class="cursoreffect submitbtn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import EcgComp from "../components/EcgComp.vue";
export default {
  name: "ContactPage",
  components: {
    EcgComp,
  },

  setup() {
  
  
   
    const cursor = ref(null);
    onMounted(() => {
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      cursor,
     
    };
  },
};
</script>
<style lang="less" scoped>
.contact-page {
  background: url("https://www.medanta.org/assets/images/contact-banner-lg.jpg");
  background-size: cover;
  padding: 50px 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    padding: 80px 0px;
  }
  .para-wrap {
    width: 640px;
    max-width: 100%;
    margin: auto;
    @media (max-width: 767px) {
      width: 350px;
      margin: auto;
    }
  }

  h2 {
    color: #fff;
  }
     .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  p {
    line-height: 1.4;

    color: #fff;
    text-align: center;
  }

 

  .main-wrapper {
    display: grid;
    grid-template-columns: auto 60%;
    gap: 40px;
    @media (max-width: 1024px) {
      display: block;
    }
    @media (max-width: 767px) {
      display: block;
    }
    .contact-section {
      background: rgba(255, 255, 255, 0.85);
      border-radius: 30px;
      backdrop-filter: saturate(180%) blur(10px);
      padding: 30px;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
      }
      h2 {
        text-align: left;
        padding-bottom: 15px;
      }
      h3 {
        color: #58595b;
        @media (max-width: 1600px) {
          font-size: 18px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .contact-detail {
        display: flex;

        align-items: center;

        .img {
          width: 48px;
          img {
            width: 100%;
          }
        }
        .contact-item {
          padding-left: 20px;
          padding: 18px 30px;

          p {
            margin-bottom: 8px;

            color: #58595b;
            text-align: left;
            @media (max-width: 1600px) {
              font-size: 16px;
            }
          }
          .imgs {
            display: flex;
            img {
              width: 30px;
              cursor: pointer;
            }
          }
        }
        .contact-item-follow {
          padding-left: 20px;
          padding: 18px 30px;

          p {
            margin-bottom: 8px;

            color: #58595b;
            text-align: left;
          }
          .imgs {
            display: flex;
            a {
              margin-right: 10px;

              img {
                width: 30px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .feedback-section {
      padding: 30px;
      background: rgba(255, 255, 255, 0.85);
      backdrop-filter: saturate(180%) blur(10px);
      border-radius: 20px;
      h3{
        text-align: center;
      }
      ul#tabs-nav {
        list-style: none;
        padding: 5px;
        display: flex;
        margin-bottom: 30px;
        justify-content: center;

        li {
          text-align: center;
          margin: 0px 10px;
          padding: 14px 60px;
          border-radius: 50px;
          cursor: pointer;
          border: none;
          color: #545454;

          border: 1px solid #545454;
          @media (max-width: 1024px) {
            padding: 14px 30px;
          }

          &:hover {
            background: #0063a8;
            color: #fff;
            border: 1px solid #0063a8;
          }
        }
        .active {
          background: #0063a8;
          color: #fff;
          border: 1px solid #0063a8;
        }
      }
     

      .enquiry-form {
        width: 90%;
        margin: auto;
        @media (max-width: 767px) {
          width: 100%;
        }
        h3 {
          color: #58595b;

          margin-bottom: 20px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
        }
        .field {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;

          label {
            color: #58595b;
          }
        }
        form {
          label {
            color: #58595b;
          }
          textarea {
            border: 1px solid #5e5e5e;
            border-radius: 12px;
            padding: 10px 20px;
            height: auto;
            background-color: #fff;
            min-height: 100px;
            resize: none;
            margin-top: 8px;
            width: 100%;
          }
          .field-wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -30px;
            .field {
              display: flex;
              flex-direction: column;
              width: 50%;
              padding: 0 30px;
              margin-bottom: 25px;
              @media (max-width: 767px) {
                width: 100%;
              }
              label {
                color: #58595b;
              }

              .inputbox {
                border: 0;

                outline: 0;
                border-bottom: 1px solid #5e5e5e;
                padding: 0;
                border-radius: 0;

                height: 38px;
                color: #58595b;
                background-color: transparent;
                width: 100%;
                border-radius: 0;
              }
            }
          }
          .btn {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            .submitbtn {
              display: inline-block;
              padding: 14px 18px;
              text-align: center;
              color: #fff;
              font-size: 13px;
              border-radius: 35px;
              text-decoration: none;
              cursor: pointer;

              margin: auto;
              min-width: 150px;

              border: 0;
              outline: 0;
              background-color: #0063a8;
            }
          }
        }
      }
    }
  }
}
</style>
