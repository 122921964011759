<template>
  <div
    class="import-opinion container"
    data-aos="fade-up"
    data-aos-duration="3000"
  >
    <div class="round-image">
      <h2>Taking A Second Opinion Is Important In More Ways Than One</h2>
      <EcgComp />
      <div class="main-wrapper">
        <div class="image" data-aos="zoom-in" data-aos-duration="1000">
          <img src="../assets/circle-image.png" />
        </div>
        <ul class="desktoplist">
          <li>
            <p>
              Assurance of getting the right diagnosis and being on the right
              treatment plan
            </p>
          </li>
          <li>
            <p>
              Peace of mind after case review by expert and experienced doctors
            </p>
          </li>
          <li><p>Ease of choosing between multiple treatment plans</p></li>
          <li><p>Confidence in making an informed decision</p></li>
        </ul>
        <ul class="moblist">
          <li>
            <p>
              Assurance of getting the right diagnosis and being on the right
              treatment plan
            </p>
          </li>
          <li>
            <p>
              Peace of mind after case review by expert and experienced doctors
            </p>
          </li>
          <li><p>Ease of choosing between multiple treatment plans</p></li>
          <li><p>Confidence in making an informed decision</p></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EcgComp from "./EcgComp.vue";
import { onMounted } from "vue";
export default {
  name: "OpinionSection",
  components: {
    EcgComp,
  },
  setup() {
    onMounted(() => {
      document.body.style.overflow = "";
    });
  },
};
</script>
<style lang="less" scoped>
.import-opinion {
  h2 {
    text-align: center;
  }
  .intro-block {
    text-align: center;
    margin-bottom: 20px;
    .monitor {
      padding: 0;
      max-width: 200px;

      margin: 0 auto;
    }

    svg {
      max-width: 470px;
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      transition: all 0.5s ease-in-out;
      .ekg {
        fill: none;
        stroke: #0063a8;
        stroke-width: 6;
        stroke-linecap: round;
        stroke-linejoin: miter;
        opacity: 0;
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
        animation: ekg 1.5s linear forwards infinite;
      }
    }
  }
  @keyframes ekg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      stroke-dashoffset: 2000;
    }
    99% {
      opacity: 0;
      stroke-dashoffset: 3000;
    }
    100% {
      stroke-dashoffset: 1000;
    }
  }
  .main-wrapper {
    position: relative;

    width: 1000px;
    max-width: 100%;
    margin: 30px auto;
    .image {
      width: 500px;
      height: 500px;
      margin: auto;

      padding: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        width: auto;
        height: auto;
      }
      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 767px) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .moblist {
      display: none;
      margin-top: 10px;
      @media (max-width: 1024px) {
        display: block;
      }
      @media (max-width: 767px) {
        display: block;
      }
      li {
        margin-left: 10px;
        max-width: 100%;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
    .desktoplist {
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: none;
      }

      li {
        list-style-type: none;
        position: absolute;
        width: 300px;
        max-width: 100%;
        cursor: pointer;
      }
      li:nth-child(1) {
        left: 2%;
        top: 15%;
        text-align: right;
        padding-right: 50px;
      }
      li:nth-child(2) {
        right: 2%;
        top: 15%;
        padding-left: 50px;
      }
      li:nth-child(4) {
        left: 3%;
        bottom: 15%;
        text-align: right;
        padding-right: 50px;
      }
      li:nth-child(3) {
        right: 3%;
        bottom: 15%;
        padding-left: 50px;
      }
    }
  }
}
</style>
