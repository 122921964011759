import { createApp } from 'vue'
import App from './App.vue'
import HomePage from './pages/HomePage.vue'
import ContactPage from './pages/ContactPage.vue'
import DocdetailPage from "./pages/DocdetailPage.vue";
import SecondOpinion from "./pages/SecondOpinion.vue"
import "aos/dist/aos.css";
import { createRouter, createWebHistory } from 'vue-router'
import AboutPage from "./pages/AboutPage.vue"
import PrivacyPolicy from "./pages/PrivacyPolicy.vue"
import TermsConditions from "./pages/TermsConditions.vue"
import AccessbilityStatement from "./pages/AccessbilityStatement.vue"
import AdvertisingPolicy from "./pages/AdvertisingPolicy.vue"
import CareerPage from './pages/CareerPage.vue'
import JCIpage from "./pages/JCIpage.vue"
import NABLpage from "./pages/NABLpage.vue"
import NABHpage from "./pages/NABHpage.vue"
import InternationalPatientsPage from "./pages/InternationalPatientsPage.vue"
import ResearchDetail from "./pages/ResearchDetail.vue";
import SpecialityPage from "./pages/SpecialityPage.vue"
import SpecialityDetail from "./pages/SpecialityDetail.vue"
import AOS from 'aos';
const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/doctors-detail', component: DocdetailPage },
    
        { path: '/contact-us', component: ContactPage },
        { path: '/second-opinion', component: SecondOpinion },
        { path: '/about-us', component: AboutPage },
        { path: '/privacy-policy', component: PrivacyPolicy },
        { path: '/terms-conditions', component: TermsConditions },
        { path: '/accessibility-statement', component: AccessbilityStatement },
        { path: '/advertising-policy', component: AdvertisingPolicy },
        { path: '/careerpage', component: CareerPage },
        { path: '/international-patients', component: InternationalPatientsPage },
        { path: '/JCI', component: JCIpage },
        { path: '/NABH', component: NABHpage },
        { path: '/NABL', component: NABLpage },
        { path: '/researchdetail', component: ResearchDetail },
        { path: '/specialities', component: SpecialityPage },
        { path: '/speciality-detail', component: SpecialityDetail },
    ]
});
const app = createApp(App)

app.use(router);
app.use(AOS.init());

app.mount('#app')
