<template>
  <div class="form" data-aos="fade-up" data-aos-duration="2000">
    <div class="query-form">
      <div class="heading"><h2>Query Form</h2></div>
      <div>
        <form>
          <div></div>
          <div class="field">
            <label>Name *</label>
            <input type="text" class="inputbox" placeholder="Enter Your Name" />
          </div>
          <div class="field">
            <label>Mobile number *</label>
            <input
              type="number"
              class="inputbox"
              placeholder="Enter Your Mobile Number"
            />
          </div>
          <div class="field">
            <label>Email *</label>
            <input
              type="email"
              class="inputbox"
              placeholder="Enter Your Email"
            />
          </div>

          <button type="submit" class="submitbtn cursoreffect">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QueryForm",
  setup() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.form {
  width: 33.33%;
  padding: 25px 35px;
  background-color: #fff;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
  .heading {
    letter-spacing: 0.5px;
    line-height: 1.2;
    color: #545454;

    margin-bottom: 20px;
  }
  .field {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    label {
      color: #545454;
      letter-spacing: 0.5px;
      @media (max-width: 767px) {
      }
    }
    .inputbox {
      border: 0;
      letter-spacing: 0.5px;
      outline: 0;
      border-bottom: 1px solid #5e5e5e;
      padding: 0;
      border-radius: 0;

      height: 38px;
      color: #58595b;
      background-color: transparent;
      width: 100%;
      border-radius: 0;
    }
  }
  .submitbtn {
    display: inline-block;
    padding: 14px 18px;
    text-align: center;
    color: #fff;

    border-radius: 35px;
    text-decoration: none;
    cursor: pointer;
    width: 100%;

    border: 0;
    outline: 0;
    background-color: #0063a8;
  }
}
</style>
