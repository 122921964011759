<template>
 <!-- cursor effect -->
 <div class="cursor" ref="cursor"></div>
 <!-- about section start -->
  <div class="secondopinion-div">
    <div class="back-img">
      <div class="opinion-section container">
        <div class="text-div">Get A Second Opinion From Anywhere Anytime</div>
        <div class="form">
          <div class="query-form">
            <h2>Request a Callback</h2>
            <div>
              <form>
                <div></div>
                <div class="field">
                  <label>Name *</label>
                  <input
                    type="text"
                    class="inputbox"
                    placeholder="Enter Your Name"
                  />
                </div>
                <div class="field">
                  <label>Mobile number *</label>
                  <input
                    type="number"
                    class="inputbox"
                    placeholder="Enter Your Mobile Number"
                  />
                </div>
                <div class="field">
                  <label>Email *</label>
                  <input
                    type="email"
                    class="inputbox"
                    placeholder="Enter Your Email"
                  />
                </div>
                <div class="field">
                  <label>Message *</label>
                  <input
                    type="text"
                    class="inputbox"
                    placeholder="Enter Your Message"
                  />
                </div>
                <button   type="submit" class="submitbtn cursoreffect">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- about opinion section -->
    <div class="abt-section container" data-aos="fade-up" data-aos-duration="3000">
      <div class="about-section">
        <h2>About Second Opinion</h2>
        <p>
          Prioritising your health means understanding all aspects of the
          condition and proposed treatment before making any decisions. If
          you're concerned about interpreting your medical reports accurately,
          we have got you covered. With our Second Opinion programme, India's
          top clinical experts will provide a thorough review and personalised
          guidance based on your reports. Access some of the best minds in
          healthcare through our verified opinions and ensure informed
          decision-making.Prioritising your health means understanding all
          aspects of the condition and proposed treatment before making any
          decisions. understanding all aspects of the condition and proposed
          treatment before making any decisions. If you're concerned about
          interpreting your medical reports accurately, we have got you covered.
          With our Second Opinion programme, India's top clinical experts will
          provide a thorough review and personalised guidance based on your
          reports. Access some of the best minds in healthcare through our
          verified opinions and ensure informed decision-making.
        </p>
      </div>
    </div>
    <!-- important second opinion section -->
    <OpinionSection />
  </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from "vue";
import OpinionSection from "../components/OpinionSection.vue";
export default {
  components: { OpinionSection },
  setup() {
    const cursor = ref(null);
    onMounted(() => {
        document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return{
cursor
    }
  },
};
</script>
<style lang="less">
.secondopinion-div {
  .abt-section{
    padding-bottom: 0px;
  }
  .back-img {
    background-image: url("../assets/opinion-banners.png");

    .opinion-section {
  
      display: flex;
      background-size: cover;
      align-items: center;
   
      @media (max-width: 767px) {
        display: block;
     
        padding-top: 65px;
      }
      .text-div {
        width: 60%;
        padding: 0 35px;
        margin: 0 0 20px 0;
        font-size: 58px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        @media (max-width: 1600px) {
          font-size: 38px;
        }
        @media (max-width: 1024px) {
          width: 50%;
          font-size: 30px;
        }
        @media (max-width: 767px) {
          width: 100%;
          font-size: 20px;
        }
        img {
          width: 100%;
        }
      }
      .form {
        width: 40%;
        padding: 25px 35px;
        background-color: #fff;
        border-radius: 20px;
        @media (max-width: 1024px) {
          width: 50%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
        h2 {
          margin-bottom: 20px;
        }
        .field {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          label {
            font-size: 16px;
            font-weight: 600;
            color: #545454;
            letter-spacing: 0.5px;
            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
          .inputbox {
            border: 0;
            letter-spacing: 0.5px;
            outline: 0;
            border-bottom: 1px solid #5e5e5e;
            padding: 0;
            border-radius: 0;
            font-weight: 500;
            font-size: 12px;
            height: 38px;
            color: #58595b;
            background-color: transparent;
            width: 100%;
            border-radius: 0;
            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
        .submitbtn {
          display: inline-block;
          padding: 14px 18px;
          text-align: center;
          color: #fff;
          font-size: 13px;
          border-radius: 35px;
          text-decoration: none;
          cursor: pointer;
          width: 100%;

          font-weight: 700;
          border: 0;
          outline: 0;
          background-color: #0063a8;
        }
      }
    }
  }
  .about-section {
    h2 {
      text-align: left;
    }
    h4 {
      font-size: 14px;
      color: #0063a8;
      margin-bottom: 8px;
      text-transform: uppercase;
    }

    p {
      font-size: 14px;
      color: #545454;
      line-height: 1.5;
      margin-bottom: 15px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
}
</style>
