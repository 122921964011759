<template>
  <!-- for desktop -->
    <div
      class="backdrop"
      @click="closevideohandler"
      :class="{ active: showvideo }"
    ></div>
    <!-- for mobile accordion -->
    <div
      class="backdrop"
      @click="closevideohandler2"
      :class="{ active: showvideo2 }"
    ></div>
    <div class="doctor-biodata">
      <!-- left section starts here -->
      <div class="left-section">
        <div class="bio-wrapper" id="Biographical">
          <h2>Biographical summary</h2>
          <p>
            Omar F. Abou-Ezzeddine, M.D.C.M., M.S., is an Associate Professor of
            Medicine, noble Clinic College of Medicine and Science and is a
            Consultant in the Department of Cardiovascular Medicine with a dual
            appointment in the Division of Circulatory Failure and the Division of
            Nuclear Cardiology. He is also Director of the Cardiac Sarcoidosis
            Clinic, Division of Circulatory Failure, Department of Cardiovascular
            Medicine. His clinical and research focuses include:
          </p>
          <ul>
            <li>Heart failure with preserved ejection fraction</li>
            <li>Cardiac sarcoidosis</li>
            <li>Cardiac amyloidosis</li>
            <li>
              Nuclear imaging techniques to assess infiltrative/inflammatory
              cardiomyopathies, cardiac energetics and myocardial blood flow
            </li>
            <li>
              Biomarker-based strategies for heart failure prevention, diagnosis,
              prognosis and therapeutic assessment
            </li>
            <li>Acute decompensated heart failure</li>
          </ul>
        </div>
        <div class="cont-wrapper" id="Conditions">
          <h2>Conditions Treated</h2>
          <ul>
            <li>Cardiac amyloidosis</li>
            <li>Cardiac sarcoidosis</li>
            <li>Cardiomyopathy</li>
            <li>Decompensated heart failure</li>
            <li>Heart failure</li>
            <li>Pulmonary hypertension</li>
            <li>Sarcoidosis</li>
          </ul>
        </div>
        <div class="cont-wrapper" id="Procedures">
          <h2>Procedures performed</h2>
          <ul>
            <li>Imaging procedure</li>
            <li>Nuclear stress test</li>
          </ul>
        </div>
        <div class="cont-wrapper" id="Interests">
          <h2>Interests</h2>
          <ul>
            <li>Special Interest in Spine Infection</li>
            <li>Spine Osteoporotic Fractures</li>
            <li>Sacroiliac Fixation</li>
          </ul>
        </div>
        <div id="locations">
          <h2>Doctor Introduction</h2>
          <ul>
            <li>
              <div class="image">
                <img src="../assets/dc1.jpg" />
                <div class="play-icon" @click="videohandler">
                  <img class="cursoreffect" src="../assets/play-btn.png" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="cont-wrapper" id="Education">
          <h2>Education</h2>
          <h5>2016</h5>
          <p>
            Advanced Fellowship - Advanced Heart Failure and Transplant Cardiology
            Fellow noble Clinic College of Medicine, Department of Education
            Administration
          </p>
          <p>
            Master of Science - Clinical and Translational Science. Thesis: Heart
            Failure Toolbox-Clinical Instruments for the Prediction of Heart
            Failure Incidence, Course and Prognosis noble Graduate School, noble
            Clinic College of Medicine
          </p>
          <h5>2015</h5>
          <p>
            Fellow - Cardiology noble Clinic College of Medicine, Department of
            Education Administration
          </p>
        </div>
        <div class="cont-wrapper" id="Activities">
          <h2>Activities and honors</h2>
          <p>Health Icon Award in Pune in field of Orthopaedic 2021</p>
          <p>Times Health Icon as Best Orthopaedic Surgeon in Pune 2022</p>
        </div>
        <!-- youtube video section -->
  
        <div class="video-wrapper" v-show="showvideo">
          <div class="video-section">
            <div class="close-icon cursoreffect">
              <img @click="closevideohandler" src="../assets/close.png" />
            </div>
            <iframe
              src="https://www.youtube.com/embed/TJA7V1Yx1XM?si=rMemSg5sC0hWFYOn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
  
      <!-- right section starts here -->
      <div class="right-section">
        <div class="sidebar">
          <ul>
            <li
              class="cursoreffect"
              v-for="item in listdata"
              :key="item.id"
              :class="{ active: activeSection === item.id }"
              @click="scrollToSection(item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
  
      <!-- mobile accordion starts here -->
      <div class="left-section-mob">
        <div class="bio-wrapper" id="Biographical">
          <div class="head-wrapp" @click="showcontenthandler">
            <h2>Biographical summary</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content }" />
          </div>
          <div class="content" v-if="content">
            <p>
              Omar F. Abou-Ezzeddine, M.D.C.M., M.S., is an Associate Professor of
              Medicine, noble Clinic College of Medicine and Science and is a
              Consultant in the Department of Cardiovascular Medicine with a dual
              appointment in the Division of Circulatory Failure and the Division
              of Nuclear Cardiology. He is also Director of the Cardiac
              Sarcoidosis Clinic, Division of Circulatory Failure, Department of
              Cardiovascular Medicine. His clinical and research focuses include:
            </p>
            <ul>
              <li>Heart failure with preserved ejection fraction</li>
              <li>Cardiac sarcoidosis</li>
              <li>Cardiac amyloidosis</li>
              <li>
                Nuclear imaging techniques to assess infiltrative/inflammatory
                cardiomyopathies, cardiac energetics and myocardial blood flow
              </li>
              <li>
                Biomarker-based strategies for heart failure prevention,
                diagnosis, prognosis and therapeutic assessment
              </li>
              <li>Acute decompensated heart failure</li>
            </ul>
          </div>
        </div>
        <div class="cont-wrapper" id="Conditions">
          <div class="head-wrapp"    @click="showcontenthandler2">
            <h2>Conditions Treated</h2>
            <img
              src="../assets/arrowhead-up.png"
           
              :class="{ active: content2 }"
            />
          </div>
          <div class="content" v-if="content2">
            <ul>
              <li>Cardiac amyloidosis</li>
              <li>Cardiac sarcoidosis</li>
              <li>Cardiomyopathy</li>
              <li>Decompensated heart failure</li>
              <li>Heart failure</li>
              <li>Pulmonary hypertension</li>
              <li>Sarcoidosis</li>
            </ul>
          </div>
        </div>
        <div class="cont-wrapper" id="Procedures">
          <div class="head-wrapp" @click="showcontenthandler3">
            <h2>Procedures performed</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content3 }" />
          </div>
          <div class="content" v-if="content3">
            <ul>
              <li>Imaging procedure</li>
              <li>Nuclear stress test</li>
            </ul>
          </div>
        </div>
        <div class="cont-wrapper" id="Interests">
          <div class="head-wrapp" @click="showcontenthandler4">
            <h2>Interests</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content4 }" />
          </div>
          <div class="content" v-if="content4">
            <ul>
              <li>Acute decompensated heart failure</li>
              <li>Advanced heart failure</li>
              <li>Biomarkers</li>
              <li>Cardiac amyloidosis</li>
              <li>Cardiac sarcoidosis</li>
              <li>Heart failure</li>
              <li>Heart failure prevention</li>
              <li>Heart failure with preserved ejection fraction</li>
              <li>Myocardial energetics</li>
              <li>Nuclear imaging</li>
              <li>Risk stratification</li>
              <li>PET imaging</li>
            </ul>
          </div>
        </div>
        <div id="locations">
          <div class="head-wrapp" @click="showcontenthandler5">
            <h2>Doctor Introduction</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content5 }" />
          </div>
          <div class="content" v-if="content5">
            <ul>
              <li>
                <div class="image">
                  <img src="../assets/dc1.jpg" />
                  <div class="play-icon" @click="videohandler2">
                    <img class="cursoreffect" src="../assets/play-btn.png" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="cont-wrapper" id="Education">
          <div class="head-wrapp" @click="showcontenthandler6">
            <h2>Education</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content6 }" />
          </div>
          <div class="content" v-if="content6">
            <h5>2016</h5>
            <p>
              Advanced Fellowship - Advanced Heart Failure and Transplant
              Cardiology Fellow noble Clinic College of Medicine, Department of
              Education Administration
            </p>
            <p>
              Master of Science - Clinical and Translational Science. Thesis:
              Heart Failure Toolbox-Clinical Instruments for the Prediction of
              Heart Failure Incidence, Course and Prognosis noble Graduate School,
              noble Clinic College of Medicine
            </p>
            <h5>2015</h5>
            <p>
              Fellow - Cardiology noble Clinic College of Medicine, Department of
              Education Administration
            </p>
          </div>
        </div>
        <div class="cont-wrapper" id="Activities">
          <div class="head-wrapp" @click="showcontenthandler7">
            <h2>Activities and honors</h2>
            <img src="../assets/arrowhead-up.png" :class="{ active: content7 }" />
          </div>
          <div class="content" v-if="content7">
            <h5>2017</h5>
            <p>Nuclear Cardiology Certification Board of Nuclear Cardiology</p>
            <p>
              Master of Science - Clinical and Translational Science. Thesis:
              Heart Failure Toolbox-Clinical Instruments for the Prediction of
              Heart Failure Incidence, Course and Prognosis noble Graduate School,
              noble Clinic College of Medicine
            </p>
            <h5>2016</h5>
            <p>
              Advanced Heart Failure and Transplant Cardiology American Board of
              Internal Medicine
            </p>
          </div>
        </div>
        <div class="video-wrapper" v-show="showvideo2">
          <div class="video-section">
            <div class="close-icon cursoreffect">
              <img @click="closevideohandler2" src="../assets/close.png" />
            </div>
            <iframe
              src="https://www.youtube.com/embed/TJA7V1Yx1XM?si=rMemSg5sC0hWFYOn"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref, onMounted, onUnmounted } from "vue";
  export default {
    setup() {
      const listdata = reactive([
        { name: "Biographical summary", id: "Biographical" },
        { name: "Conditions treated", id: "Conditions" },
        { name: "Procedures performed", id: "Procedures" },
        { name: "Interests", id: "Interests" },
        { name: "Noble Clinic locations", id: "locations" },
        { name: "Education", id: "Education" },
        { name: "Activities and honors", id: "Activities" },
      ]);
      const content = ref(false);
      const content2 = ref(false);
      const content3 = ref(false);
      const content4 = ref(false);
      const content5 = ref(false);
      const content6 = ref(false);
      const content7 = ref(false);
  
      const activeSection = ref("Biographical");
  
      const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      };
  
      const handleScroll = () => {
        const sections = listdata.map((item) => document.getElementById(item.id));
        const scrollPos = window.scrollY + window.innerHeight / 2;
  
        for (const section of sections) {
          if (
            section.offsetTop <= scrollPos &&
            section.offsetTop + section.offsetHeight > scrollPos
          ) {
            activeSection.value = section.id;
            break;
          }
        }
      };
  
      onMounted(() => {
        window.addEventListener("scroll", handleScroll);
      });
  
      onUnmounted(() => {
        window.removeEventListener("scroll", handleScroll);
      });
      
      const toggleContent = (contentRef) => {
        const isOpen = contentRef.value;
        content.value = false;
        content2.value = false;
        content3.value = false;
        content4.value = false;
        content5.value = false;
        content6.value = false;
        content7.value = false;
        if (!isOpen) {
          contentRef.value = true;
        }
      };
  
      const showcontenthandler = () => {
        toggleContent(content);
      };
      const showcontenthandler2 = () => {
        toggleContent(content2);
      };
      const showcontenthandler3 = () => {
        toggleContent(content3);
      };
      const showcontenthandler4 = () => {
        toggleContent(content4);
      };
      const showcontenthandler5 = () => {
        toggleContent(content5);
      };
      const showcontenthandler6 = () => {
        toggleContent(content6);
      };
      const showcontenthandler7 = () => {
        toggleContent(content7);
      };
      const showvideo = ref(false);
      const showvideo2 = ref(false);
      const videohandler = () => {
        showvideo.value = true;
        document.body.style.overflow = "hidden";
      };
      const closevideohandler = () => {
        showvideo.value = false;
        document.body.style.overflow = "";
      };
      const videohandler2 = () => {
        showvideo2.value = true;
        document.body.style.overflow = "hidden";
      };
      const closevideohandler2 = () => {
        showvideo2.value = false;
        document.body.style.overflow = "";
      };
      return {
        listdata,
        scrollToSection,
        showcontenthandler,
        showcontenthandler2,
        showcontenthandler3,
        showcontenthandler4,
        showcontenthandler5,
        showcontenthandler6,
        showcontenthandler7,
        activeSection,
        showvideo,
        videohandler,
        closevideohandler,
        showvideo2,
        videohandler2,
        closevideohandler2,
        content,
        content2,
        content3,
        content4,
        content5,
        content6,
        content7,
      };
    },
  };
  </script>
  
  <style scoped lang="less">
  .doctor-biodata {
    display: flex;
    justify-content: space-between;
    margin: 80px 0px;
    border-top: 1px solid #d6d6d6;
    @media (max-width: 1024px) {
      display: block;
    }
    .left-section {
      width: 70%;
      @media (max-width: 1024px) {
        width: 100%;
        display: none;
      }
      .bio-wrapper {
        padding: 40px 0px;
  
        ul {
          padding-left: 40px;
          li {
            line-height: 1.6;
          }
        }
      }
      h2 {
        text-align: left;
      }
      .cont-wrapper {
        border-top: 1px solid #d6d6d6;
        padding: 40px 0px;
  
        ul {
          //  list-style: none;
          padding-left: 40px;
  
          li {
            line-height: 1.6;
          }
        }
      }
      #locations {
        border-top: 1px solid #d6d6d6;
        padding: 40px 0px;
  
        ul {
          list-style: none;
  
          li {
            line-height: 1.6;
            .image {
              width: 300px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
              }
              .play-icon {
                position: absolute;
                top: 8px;
                left: 7px;
  
                img {
                  width: 100%;
                  cursor: pointer;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .video-section {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        .close-icon {
          position: absolute;
          top: -34px;
          right: -32px;
          width: 20px;
          height: 20px;
          @media (max-width: 767px) {
            top: -28px;
            right: -5px;
          }
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        iframe {
          width: 900px;
          height: 700px;
          @media (max-width: 1600px) {
            width: 700px;
            height: 500px;
          }
          @media (max-width: 1024px) {
            width: 700px;
            height: 700px;
          }
          @media (max-width: 767px) {
            width: auto;
            height: 315px;
          }
        }
      }
    }
    // mobile accordion
  
    .left-section-mob {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
      .head-wrapp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          margin-bottom: 10px;
        }
        .active {
          transform: rotate(180deg);
        }
      }
      .bio-wrapper {
        padding: 20px 0px;
        ul {
          padding-left: 40px;
          list-style: disc;
          li {
            line-height: 1.6;
          }
        }
      }
      h2 {
        text-align: left;
      }
      .cont-wrapper {
        border-top: 1px solid #d6d6d6;
        padding: 20px 0px;
  
        ul {
          list-style: disc;
          padding-left: 40px;
  
          li {
            line-height: 1.6;
          }
        }
      }
      #locations {
        border-top: 1px solid #d6d6d6;
        padding: 20px 0px;
        .image {
          width: 300px;
          position: relative;
          
          img {
            width: 100%;
            height: 100%;
          }
          .play-icon {
            position: absolute;
            top: 8px;
            
            left: 7px;
            img {
              width: 100%;
              height: 100%;
                cursor: pointer;
            }
          }
        }
        .content{
   ul {
            padding-left: 0px;
            li {
              list-style: none;
            }
          }
        }
       
      }
      .video-section {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        .close-icon {
          position: absolute;
          top: -34px;
          right: -32px;
          width: 20px;
          height: 20px;
          @media (max-width: 767px) {
            top: -28px;
            right: -5px;
          }
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        iframe {
          width: 900px;
          height: 700px;
          @media (max-width: 1600px) {
            width: 700px;
            height: 500px;
          }
          @media (max-width: 1024px) {
            width: 700px;
            height: 700px;
          }
          @media (max-width: 767px) {
            width: auto;
            height: 315px;
          }
        }
      }
    }
  
    .right-section {
      width: 30%;
      display: flex;
      justify-content: center;
      padding: 40px 0px;
      position: sticky;
      top: 70px;
      height: 450px;
      @media (max-width: 1024px) {
        display: none;
      }
      ul {
        list-style: none;
        padding-left: 40px;
  
        li {
          line-height: 1.6;
          padding: 10px;
          &:hover {
            color: #0063a8;
            cursor: pointer;
          }
          &.active {
            color: #0063a8;
            border-left: 4px solid black;
          }
        }
      }
    }
  }
  //   backdrop css
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }
  
  .backdrop.active {
    display: block;
  }
  </style>
  