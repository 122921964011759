<template>
 <div class="banner">
            <img src="../assets/research-bann.png">
        </div>

<div class="research-section container">
<!-- left side section -->
    <div class="research-detail ">
      
       <h2>About Clinical Trials</h2>
          
       <p>Since 2005, through the Clinical Research Program established at MHC, the Office of research has been able to successfully initiate more than 400 sponsored clinical trials of which around 300 have been completed and currently 80 are on-going till date.  The trials have been conducted in all specialties, mainly cardiology, nephrology, neurology, oncology and many the Office of research has been able to successfully initiate more than 400 sponsored clinical trials of which around 300 have been completed and currently 80 are on-going till date.  The trials have been conducted in all specialties, mainly cardiology, nephrology, neurology, oncology and many others. 
</p>
  <h2>Our objectives</h2>
<p>Our research objectives are to:</p>
<p>
    <ul>
      <li>  Promote the RMH and the Parkville biomedical precinct as a leading centre for research</li>
<li>Encourage and support health care workers to participate in research activity</li>
<li>Optimise translation of research into clinical practice and health policy</li>
<li>Foster collaborative relationships to optimise opportunities for research</li>
    </ul>

</p>
  <div class="banner">
            <img src="../assets/clinical.avif">
        </div>
    </div>

    </div>
</template>
<script>
export default {
  name: "ResearchDetail",
  setup() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.banner {
  @media (max-width: 767px) {
    margin-top: 55px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.research-section {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
    padding-top: 20px;
  }
  .research-detail {
    h2 {
      text-align: left;

      margin-bottom: 12px;

      @media (max-width: 1024px) {
        margin-bottom: 13px;
      }

      @media (max-width: 767px) {
        margin-bottom: 14px;
        margin-top: 14px;
      }
    }

    p {
      line-height: 1.5;
      margin-bottom: 15px;

      letter-spacing: 0.5px;
    }
    ul {
      padding-left: 18px;

      li {
      }
    }
    .banner {
      margin-top: 30px;
      box-shadow: 2px 2px 12px #cccccc;
      width: 700px;
      @media (max-width: 767px) {
        width: auto;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
