<template>
  <div class="statistics container">
     <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Statistics</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Statistics</h2>
    </div>
  </div>
</div>
    <div class="statistics-wrapper">
      <div class="text-container">
        <h3>Modern equipments and specialists</h3>
        <div ref="text">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            cum maiores accusamus sunt impedit neque officia odio amet inventore
            exercitationem, rerum et facere tempora obcaecati fugit ea ad unde
            ab totam aliquid reiciendis! Laborum reiciendis dolorem eos magni
            beatae! Consequatur facilis ipsam ea alias quidem! A aut architecto
            sed nemo corrupti et quod, error, provident labore nostrum voluptate
            delectus repellendus, harum sint vitae! Mollitia eius repudiandae
            molestias, temporibus sit delectus cum voluptatem reiciendis
            recusandae ullam accusantium suscipit, magni illo? Id at fugit sed
            reprehenderit assumenda suscipit eveniet, necessitatibus et
            blanditiis officiis libero vero quasi eaque enim molestias maiores
            sit nam.
            <br /><br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            cum maiores accusamus sunt impedit neque officia odio amet inventore
            exercitationem, rerum et facere tempora obcaecati fugit ea ad unde
            ab totam aliquid reiciendis! Laborum reiciendis dolorem eos magni
            beatae! Consequatur facilis ipsam ea alias quidem! A aut architecto
            sed nemo corrupti et quod, error, provident labore nostrum voluptate
            delectus repellendus, harum sint vitae! Mollitia eius repudiandae
            molestias, temporibus sit delectus cum voluptatem reiciendis
            recusandae ullam accusantium suscipit, magni illo? Id at fugit sed
            reprehenderit assumenda suscipit eveniet, necessitatibus et
            blanditiis officiis libero vero quasi eaque enim molestias maiores
            sit nam.
          </p>
        </div>
        <div class="btn">
          <button v-show="showToggle" @click="toggleText" class="cursoreffect">
            {{ toggleButtonText }}
          </button>
        </div>
      </div>
      <div class="percentage-container">
        <div class="skillbar clearfix" data-percent="86%">
          <div class="skillbar-bar" style="background: #eee">
            <span>Dental</span>
          </div>
          <div class="skill-bar-percent Count">90</div>
        </div>

        <div class="skillbar clearfix" data-percent="82%">
          <div class="skillbar-bar" style="background: #eee">
            <span>Cardio</span>
          </div>
          <div class="skill-bar-percent Count">85</div>
        </div>

        <div class="skillbar clearfix" data-percent="75%">
          <div class="skillbar-bar" style="background: #eee">
            <span>Neurologie</span>
          </div>
          <div class="skill-bar-percent Count">75</div>
        </div>

        <div class="skillbar clearfix" data-percent="78%">
          <div class="skillbar-bar" style="background: #eee">
            <span>Orthopedy</span>
          </div>
          <div class="skill-bar-percent Count">80</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import $ from "jquery";
import EcgComp from "../components/EcgComp.vue";

export default {
  name: "StatisticsComp",
  components: { EcgComp },
  setup() {
    const text = ref(null);
    const showToggle = ref(false);
    const toggleButtonText = ref("Read More");
    const isTextExpanded = ref(false);
    const originalText = ref("");
    const toggleText = () => {
      if (isTextExpanded.value) {
        $(text.value).slideUp(() => {
          text.value.innerHTML =
            originalText.value.split(/\s+/).slice(0, 100).join(" ") + "...";
          toggleButtonText.value = "Read More";
          $(text.value).slideDown();
        });
      } else {
        $(text.value).slideUp(() => {
          text.value.innerHTML = originalText.value;
          toggleButtonText.value = "Read Less";
          $(text.value).slideDown();
        });
      }
      isTextExpanded.value = !isTextExpanded.value;
    };
    const initializeSkillbar = (el) => {
      $(el)
        .find(".skillbar-bar")
        .animate(
          {
            width: $(el).attr("data-percent"),
          },
          6000
        );
    };

    const initializeCounters = (el) => {
      const $this = $(el).find(".Count");
      $({ Counter: 0 }).animate(
        { Counter: $this.text() },
        {
          duration: 6000,
          easing: "swing",
          step: function () {
            $this.text(Math.ceil(this.Counter));
          },
        }
      );
    };

    onMounted(() => {
      document.querySelectorAll(".skillbar").forEach((skillbar) => {
        initializeSkillbar(skillbar);
        initializeCounters(skillbar);
      });

      originalText.value = text.value.innerHTML.trim();
      const wordCount = originalText.value.split(/\s+/).length;

      if (wordCount > 100) {
        showToggle.value = true;
        text.value.innerHTML =
          originalText.value.split(/\s+/).slice(0, 100).join(" ") + "...";
      }
    });

    onBeforeUnmount(() => {
      $(".skillbar").stop(true, true);
      $(".Count").stop(true, true);
    });

    return { text, showToggle, toggleText, toggleButtonText };
  },
};
</script>

<style lang="less" scoped>
.statistics{
  .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }

.statistics-wrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: block;
  }
  .text-container {
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
    h3 {
      margin-bottom: 30px;
    }
    .btn {
      padding-top: 25px;
      @media (max-width: 1024px) {
        padding: 25px 0px;
      }
      button {
        border: 2px solid #64c698;
        background: #64c698;
        border-radius: 50px;
        padding: 13px 25px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .percentage-container {
    width: 50%;
    padding-left: 50px;
    @media (max-width: 1024px) {
      width: 100%;
      padding-left: 0px;
    }
    .Count:after {
      content: "%";
    }
    .skillbar {
      position: relative;
      display: block;
      margin-bottom: 15px;
      width: 100%;
      border-radius: 130px;
      transition: 0.4s linear;
      transition-property: width, background-color;
      .skillbar-bar {
        width: 0px;
        background: #6adcfa;
        border-radius: 130px;
        padding: 20px 0px;
        span {
          font-weight: bold;
          font-size: 13px;
          padding: 0 50px;
          font-size: 16px;
          display: block;
        }
      }
    }
    .skill-bar-percent {
      position: absolute;
      right: 10px;
      top: 0;
      font-weight: 600;
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      color: #64c698;
    }
  }
}
}
</style>
