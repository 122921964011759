<template>
  <div class="center_of_excellence_section">
    <div class="container">
      <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
        <EcgComp />
        <div class="desktop-heading">
          <h2>Center of Excellence</h2>
        </div>
        <div class="mob-marquee">
          <div
            class="marquee-content"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <h2>Center of Excellence</h2>
          </div>
        </div>
      </div>
      <div class="excellence_items">
        <div class="excellence_card" v-for="item in items" :key="item">
          <div class="excellence_card_box">
            <div class="image">
              <img :src="item.image" alt="" />
            </div>
            <div class="content">
              <h5>{{ item.title }}</h5>
              <p>Learn More</p>
            </div>
          </div>
        </div>

        <div class="button-wrap">
          <button id="execellence_load_more" class="cursoreffect">
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { reactive, onMounted } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "SpecialityList",
  components: {
    EcgComp,
  },

  setup() {
    const items = reactive([
      {
        image: require("../assets/logo/breast-care.png"),
        title: "Breast Care",
      },
      {
        image: require("../assets/logo/critical-care.png"),
        title: "Critical Care",
      },
      {
        image: require("../assets/logo/internal-medicine.png"),
        title: "Internal Medicine",
      },
      {
        image: require("../assets/logo/laparoscopic-and-minimally-invasive-surgery-(mis).png"),
        title: "Laparoscopic And Minimally Invasive Surgery (MIS)",
      },
      {
        image: require("../assets/logo/pediatrics-surgery.png"),
        title: "Pediatrics Surgery",
      },
      {
        image: require("../assets/logo/orthopedics.png"),
        title: "Orthopedics",
      },
      {
        image: require("../assets/logo/obstetrics-gynecology.png"),
        title: "Obstetrics & Gynecology",
      },
      {
        image: require("../assets/logo/fertility-medicine.png"),
        title: "Fertility Medicine",
      },
      {
        image: require("../assets/logo/ophthalmology.png"),
        title: "Ophthalmology",
      },
      {
        image: require("../assets/logo/neurology.png"),
        title: "Neurology",
      },
      {
        image: require("../assets/logo/gastroenterology.png"),
        title: "Gastroenterology",
      },
      {
        image: require("../assets/logo/psychiatry.png"),
        title: "Psychiatry",
      },
      {
        image: require("../assets/logo/dental-maxillofacial-surgery.png"),
        title: "Dental & Maxillofacial Surgery",
      },
      {
        image: require("../assets/logo/dermatology.png"),
        title: "Dermatology",
      },
      {
        image: require("../assets/logo/ent.png"),
        title: "ENT",
      },
      {
        image: require("../assets/logo/endocrinology.png"),
        title: "Endocrinology",
      },
      {
        image: require("../assets/logo/hematology.png"),
        title: "Hematology",
      },
      {
        image: require("../assets/logo/rheumatology.png"),
        title: "Rheumatology",
      },
      {
        image: require("../assets/logo/nutrition-&-dietetics.png"),
        title: "Nutrition & Dietetics",
      },
      {
        image: require("../assets/logo/plastic-reconstr-ctive-surgery.png"),
        title: "Plastic & Reconstructive Surgery",
      },
      {
        image: require("../assets/logo/infectious-diseases.png"),
        title: "Infectious Diseases",
      },
      {
        image: require("../assets/logo/oncology.png"),
        title: "Onco Surgery",
      },
      {
        image: require("../assets/logo/interventional-radiology.png"),
        title: "Interventional Radiology",
      },
      {
        image: require("../assets/logo/anesthesiology.png"),
        title: "Anesthesiology",
      },
      {
        image: require("../assets/logo/physiotherapy.png"),
        title: "Physiotherapy",
      },
      {
        image: require("../assets/logo/pulmonology.png"),
        title: "Pulmonology",
      },
      {
        image: require("../assets/logo/robotic-knee-joint-replacement.png"),
        title: "Robotic Knee Joint Replacement",
      },
      {
        image: require("../assets/logo/pediatrics.png"),
        title: "Pediatrics",
      },
      {
        image: require("../assets/logo/orthopedics.png"),
        title: "Orthopaedics & Spine Surgeon",
      },
      {
        image: require("../assets/logo/emergency-and-trauma.png"),
        title: "Emergency And Trauma",
      },
      {
        image: require("../assets/logo/vascular-surgery.png"),
        title: "Vascular Surgery",
      },
      {
        image: require("../assets/logo/diabetes.png"),
        title: "Diabetes",
      },
      {
        image: require("../assets/logo/neuro-interventional-radiology.png"),
        title: "Neuro-Interventional Radiology",
      },
      {
        image: require("../assets/logo/surgical-oncology.png"),
        title: "Surgical Oncology",
      },
      {
        image: require("../assets/logo/neuro-surgery.png"),
        title: "Neuro Surgery",
      },
      {
        image: require("../assets/logo/cognitive-behavioural-psychotherapy.png"),
        title: "Cognitive-Behavioural Psychotherapy",
      },
      {
        image: require("../assets/logo/pain-management-clinic.png"),
        title: "Pain Management Clinic",
      },
      {
        image: require("../assets/logo/neonatal-icu.png"),
        title: "Neonatal ICU",
      },
      {
        image: require("../assets/logo/pediatric-endocrinology.png"),
        title: "Pediatric Endocrinology",
      },
      {
        image: require("../assets/logo/pediatric-neurology.png"),
        title: "Pediatric Neurology",
      },
      {
        image: require("../assets/logo/preventive-medicine.png"),
        title: "Preventive Medicine",
      },
      {
        image: require("../assets/logo/pediatrics.png"),
        title: "Paediatrics Main",
      },
      {
        image: require("../assets/logo/pediatric-nephrology.png"),
        title: "Pediatric Nephrology",
      },
      {
        image: require("../assets/logo/surgical-gastroenterology-liver-hpb-surgery.png"),
        title: "Surgical Gastroenterology",
      },
      {
        image: require("../assets/logo/shoulder-arthroscopy-sports-injuries.png"),
        title: "Shoulder Arthroscopy & Sports Injuries",
      },
      {
        image: require("../assets/logo/oncology.png"),
        title: "Oncology",
      },
      {
        image: require("../assets/logo/surgical-gastroenterology-liver-hpb-surgery.png"),
        title: "Surgical Gastroenterology, Liver & HPB Surgery",
      },
      {
        image: require("../assets/logo/nephrology.png"),
        title: "Nephrology",
      },
      {
        image: require("../assets/logo/urology.png"),
        title: "Urology",
      },
      {
        image: require("../assets/logo/gastroenterology.png"),
        title: "Gastro Sciences",
      },
      {
        image: require("../assets/logo/obesity.png"),
        title: "Obesity",
      },
      {
        image: require("../assets/logo/mental-health.png"),
        title: "Mental Health",
      },
      {
        image: require("../assets/logo/laboratory-medicine.png"),
        title: "Laboratory Medicine",
      },
      {
        image: require("../assets/logo/radiology.png"),
        title: "Radiology",
      },
      {
        image: require("../assets/logo/fetal-medicine.png"),
        title: "Fetal Medicine",
      },
    ]);
    onMounted(() => {
      $(document).ready(function () {
        $(".excellence_card").slice(0, 20).show();
        $("#execellence_load_more").click(function (e) {
          e.preventDefault();
          $(".excellence_card:hidden").slice(0, 5).fadeIn("slow");

          if ($(".excellence_card:hidden").length == 0) {
            $("#execellence_load_more").fadeOut("slow");
          }
        });
      });
    });
    return {
      items,
    };
  },
};
</script>

<style lang="less" scoped>
.center_of_excellence_section {
     .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;

          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .marquee-content {
        display: inline-block;
        white-space: nowrap;
        animation: marquee 10s linear infinite;
      }
      @keyframes marquee {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    }
  .excellence_items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .excellence_card {
      width: 20%;
      display: none;
      padding: 10px;
      @media screen and (max-width: 1500px) {
        width: 25%;
      }
      @media screen and (max-width: 1200px) {
        width: 33.33%;
      }
      @media screen and (max-width: 768px) {
        width: 50%;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
        padding: 10px 0px;
      }
      .excellence_card_box {
        width: 100%;
        height: 100%;
        box-shadow: 1px 1px 7px #ccc;
        border-radius: 5px;
        padding: 10px 10px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {
          padding: 22px 10px;
        }
        .image {
          img {
            padding-right: 15px;
            padding-left: 7px;
            @media screen and (max-width: 768px) {
              padding-right: 5px;
              padding-left: 0px;
            }
            @media screen and (max-width: 500px) {
              padding-right: 15px;
              padding-left: 7px;
            }
          }
        }
        .content {
          h5 {
            color: #4f4f4f;
          }
          p {
            padding-top: 2px;
            color: #64c698;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
    .button-wrap {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      button {
        background-color: #64c698;
        border: none;
        color: #fff;
        padding: 12px 35px;
        font-size: 16px;
        border-radius: 354px;
        cursor: pointer;
      }
    }
  }
}
</style>
