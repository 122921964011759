<template>
  <div class="overview-container">
    <div class="container">
        <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Overview</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Overview</h2>
    </div>
  </div>
</div>
      <div class="about">
        <div class="text">
          <h3>HEALTHCARE AT ITS VERY BEST</h3>
          <div ref="text">
            <p>
              Noble Hospitals & Research Centre is a 350-bedded, quaternary care
              hospital in Hadapsar, Pune — including a chain of polyclinics —
              that was started with an aim to provide high-quality integrated
              healthcare to patients at an affordable cost. Currently, the
              hospital has over 66 ICU beds, 130+ doctors, 30+ clinical
              departments, 8 certified Green operation theatre complexes, 24x7
              Emergency care (backed by the latest radio-diagnostic technology),
              and 32 outpatient consultation rooms. In the next three years, we
              will be rapidly expanding the bed capacity to 1,000 patient beds
              with 3 new greenfield hospitals.<br /><br />
              Today — 16 years since inception — our key clinical drivers
              include the entire gamut of services in Cardiac Sciences, Neuro
              Sciences, Robotic Joint Replacement Surgery, Orthopedics, Gastro
              Sciences and HPB Surgery, Minimal Access Surgery, Renal Sciences,
              Oncology, Obstetrics and Gynecology, Pediatrics and Neonatology,
              Emergency and Trauma Care, and Interventional Radiology. Governed
              by our motto "Life is precious, we care for it", we at Noble
              Hospitals & Research Centre intend to revolutionise the patient
              experience by pairing exceptional medical services with
              intelligence and compassion. Our combined expertise of a team of
              surgeons, physicians, nurses, technicians, paramedics, and
              administrative and maintenance staff — led by our visionary
              Managing Director and Clinician Dr Dileep Mane — ensures highly
              personalised care that our patients deserve. Noble Hospitals &
              Research Centre offers many technological advancements, including
              cutting-edge technology such as a 3 Tesla MRI scanner
              (first-of-its-kind in Pune). The hospital is focused on setting
              the benchmark in healthcare practices by achieving unmatched
              standards of excellence through innovation, technology and
              compassionate patient care. Noble Hospitals & Research Centre
              strives to leverage technology and innovation to deliver advanced
              diagnostic solutions, while maintaining complete transparency
              pertaining to the treatment. The cornerstones of Noble’s legacy
              are its unstinting focus on clinical excellence, affordable costs,
              modern technology and evidence-based practices. As a responsible
              corporate citizen, Noble Hospitals takes the spirit of leadership
              well beyond business and embraces the responsibility of keeping
              India healthy every day. To further our mission, we also undertake
              several CSR activities and contribute towards society in a
              meaningful manner. Noble Hospitals is not limited merely to
              treating illnesses but aims to foster an environment conducive to
              wellness too.
            </p>
          </div>
          <button
            v-show="showToggle"
            @click="toggleText"
            class="read cursoreffect"
          >
            {{ toggleButtonText }}
          </button>
        </div>
        <div class="image-slider">
          <OverviewSlider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverviewSlider from "../components/OverviewSlider";
import EcgComp from "../components/EcgComp.vue";
import { ref, onMounted } from "vue";
import $ from "jquery";

export default {
  components: { EcgComp, OverviewSlider },
  name: "AboutOverview",
  setup() {
    const text = ref(null);
    const showToggle = ref(false);
    const toggleButtonText = ref("Read More");
    const isTextExpanded = ref(false);
    const originalText = ref("");

    const toggleText = () => {
      if (isTextExpanded.value) {
        $(text.value).slideUp(() => {
          text.value.innerHTML =
            originalText.value.split(/\s+/).slice(0, 150).join(" ") + "...";
          toggleButtonText.value = "Read More";
          $(text.value).slideDown();
        });
      } else {
        $(text.value).slideUp(() => {
          text.value.innerHTML = originalText.value;
          toggleButtonText.value = "Read Less";
          $(text.value).slideDown();
        });
      }
      isTextExpanded.value = !isTextExpanded.value;
    };

    onMounted(() => {
      originalText.value = text.value.innerHTML.trim();
      const wordCount = originalText.value.split(/\s+/).length;

      if (wordCount > 150) {
        showToggle.value = true;
        text.value.innerHTML =
          originalText.value.split(/\s+/).slice(0, 150).join(" ") + "...";
      }
    });

    return { text, showToggle, toggleText, toggleButtonText };
  },
};
</script>

<style lang="less" scoped>
.overview-container {
  background: #fafafa;
   .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .about {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
      display: block;
    }

    .text {
      padding-right: 20px;
      max-width: 60%;
      @media (max-width: 1024px) {
        padding: 0px 5px;
        max-width: 100%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        padding: 0px;
      }
      h3 {
        padding-bottom: 10px;
      }
      h2 {
        text-align: left;
      }
    }

    .image-slider {
      max-width: 40%;
      @media (max-width: 1024px) {
        padding: 0px 5px;
        max-width: 100%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        padding: 0px;
        margin-top: 20px;
      }
      h3 {
        padding: 10px 0px;
      }
    }

    .read {
      margin-top: 25px;
      border: 2px solid #64c698;
      background: #64c698;
      border-radius: 50px;
      padding: 13px 25px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
