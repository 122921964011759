<template>
  <div class="upcoming-events" data-aos="fade-up" data-aos-duration="3000">
    <div class="scroll-div">
      <div class="sticky-div">
        <h2>Upcoming Events</h2>
        <ul class="media-tabs-ul" id="tabs-nav">
          <li
            class="cursoreffect"
            :class="{ active: selectedEventType.value === 'CME' }"
            @click="cmeHandler"
          >
            <h4>Important Events</h4>
          </li>
          <li
            class="cursoreffect"
            :class="{ active: selectedEventType.value === 'OPD' }"
            @click="opdHandler"
          >
            <h4>View All Events</h4>
          </li>
        </ul>
      </div>

      <div class="new-med cursoreffect">
        <div
          class="news-media"
          v-for="event in filteredEvents"
          :key="event.title"
        >
          <div class="media-image">
            <span class="media-date">{{ event.date }}</span>
            <span class="mediamonth">{{ event.month }}</span>
          </div>
          <div class="media-text">
            <h5>{{ event.title }}</h5>
            <p class="loc">
              <img src="../assets/location.png" /><span>{{ event.loc }}</span>
            </p>
            <div class="common-button">
              <p class="anchor-button cursoreffect">
                View Details <span><img src="../assets/next.png" /></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";

export default {
  name: "UpComingEvents",
  setup() {
    const events = reactive([
      {
        loc: "Gurgaon",
        month: "May",
        date: "20",
        title: "First CME annual conference of female pelvic pain association",
      },
      {
        loc: "Pune",
        month: "Mar",
        date: " 14",
        title:
          "Noble unveils OPD the first edition of its annual women’s conclave",
      },
      {
        loc: "Delhi",
        month: "feb",
        date: " 2",
        title: "Noble Super  OPD Specialty Hospital Hosts ",
      },
      {
        loc: "Pune",
        month: "May",
        date: "20",
        title: "First annual conference of OPD female pelvic pain association",
      },
      {
        loc: "Delhi",
        month: "Mar",
        date: " 14",
        title: "Noble unveils the first edition of its annual women’s conclave",
      },
      {
        loc: "Delhi",
        month: "feb",
        date: " 2",
        title: "Noble Super Specialty OPD Hospital Hosts ",
      },
      {
        loc: "Delhi",
        month: "May",
        date: "20",
        title: "First annual conference of female pelvic pain association",
      },
      {
        loc: "Pune",
        month: "Mar",
        date: " 14",
        title:
          "Noble unveils the first OPD edition of its annual women’s conclave",
      },
      {
        loc: "Gurgaon",
        month: "feb",
        date: " 2",
        title: "Noble Super Webinar Specialty Hospital Hosts ",
      },
      {
        loc: "Pune",
        month: "Mar",
        date: " 14",
        title:
          "Noble unveils Webinar the first CME edition of its annual women’s conclave",
      },
      {
        loc: "Gurgaon",
        month: "feb",
        date: " 2",
        title: "Noble Super Webinar Specialty Hospital Hosts ",
      },
    ]);

    const selectedEventType = reactive({ value: "CME" });

    const cmeHandler = () => {
      selectedEventType.value = "CME";
    };

    const opdHandler = () => {
      selectedEventType.value = "OPD";
    };

    const filteredEvents = computed(() => {
      return events.filter((event) =>
        event.title.includes(selectedEventType.value)
      );
    });

    return {
      cmeHandler,
      opdHandler,

      selectedEventType,
      filteredEvents,
    };
  },
};
</script>

<style lang="less" scoped>
.upcoming-events {
  flex: 0 0 35%;
  height: 549px;

  padding: 30px 30px;
  padding-top: 0px;
  border-radius: 20px;
  overflow-y: scroll;
  width: 35%;
  border-radius: 20px;
  overflow-x: auto;

  background: #f0f8ff;

  @media (max-width: 1024px) {
    flex: 0 0 100%;
    width: 100%;
    height: 400px;
    padding-top: 0px;
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    width: 100%;
    height: 400px;
    padding: 10px;
    padding-top: 0px;
  }
  .sticky-div {
    position: sticky;
    padding-top: 30px;
    top: -1px;
    background: #f0f8ff;
    /* margin-top: 0px; */

    h2 {
      text-align: left;
      padding-bottom: 10px;
    }

    ul#tabs-nav {
      list-style: none;
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      gap: 5px;
      @media (max-width: 767px) {
        padding: 5px 0px;
      }
      li {
        text-align: center;
        padding: 10px 50px;
        border-radius: 50px;
        cursor: pointer;
        border: none;
        color: #545454;
        transition: background-color, 0.4s ease-in-out;
        border: 1px solid #545454;

        @media (max-width: 1600px) {
          padding: 12px 30px;
        }

        @media (max-width: 1024px) {
          padding: 10px 50px;
        }
        @media (max-width: 767px) {
          padding: 10px 16px;
        }
        &:hover {
          background: #0063a8;
          color: #fff;
          border: 1px solid #0063a8;
        }
      }
      .active {
        background: #0063a8;
        color: #fff;
        border: 1px solid #0063a8;
      }
    }
  }
  .news-media {
    display: flex;
    padding-bottom: 20px;
    gap: 16px;
    align-items: center;

    margin-top: 20px;
    border-bottom: 1px solid #dddddd;
    .media-image {
      display: block;

      background: #fff;
      border-radius: 10px;
      text-align: center;
      width: 35%;
      padding: 28px;
      @media (max-width: 1600px) {
        width: 120px;
        height: 116px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      @media (max-width: 1024px) {
        padding: 28px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 116px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      span.media-date {
        font-size: 40px;
        color: #58595b;
        font-weight: 700;
      }
      span.mediamonth {
        color: #58595b;
        font-size: 17px;
        font-weight: 700;
      }
    }
    .media-text {
      width: 65%;
      @media (max-width: 1600px) {
        width: 80%;
      }
      @media (max-width: 767px) {
        width: 80%;
      }
      h5 {
        color: #58595b;
      }
      span {
        color: #58595b;
      }
      .loc {
        padding: 10px 0;
        display: flex;

        align-items: center;

        span {
          color: #58595b;
        }
      }

      .anchor-button {
        font-family: "FuturaMediumBT";
        display: inline-flex;
        gap: 10px;

        align-items: center;
        cursor: pointer;
        color: #58595b;
        &:hover {
          color: #0063a8;
        }
        span {
          line-height: 18px;
          width: 18px;
          height: 18px;

          img {
            background-color: #0063a8;
            border-radius: 50%;
            width: 100%;
          }
        }
      }
    }
  }
}
/* For WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #0063a8; /* Set the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #efefef; /* Set the color of the scrollbar thumb */
}

/* For Firefox */
/* Note: Firefox currently only supports scrollbar width and scrollbar color */
/* You can't directly style the scrollbar thumb in Firefox */
/* These styles will only change the scrollbar width and color */
/* Other properties may not work in Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0063a8 #e2dfdf;
}
</style>
