import { reactive } from 'vue';

const listeners = reactive({});

export const EventBus = {
  emit(event, data) {
    if (listeners[event]) {
      listeners[event].forEach(callback => callback(data));
    }
  },
  on(event, callback) {
    if (!listeners[event]) {
      listeners[event] = [];
    }
    listeners[event].push(callback);
  },
  off(event, callback) {
    if (!listeners[event]) return;
    listeners[event] = listeners[event].filter(cb => cb !== callback);
  }
};
