<template>
  <div class="treatment-journey">
    <div class="container">
      <h2>Your Treatment Journey At Noble Hospitals</h2>
      <div class="cards">
        <Carousel
          v-bind="settings"
          :breakpoints="breakpoints"
          :wrapAround="true"
        >
          <Slide class="card" v-for="card in cards" :key="card">
            <div class="card-wrapper">
              <div class="header-wrapper">
                <div class="card-header">{{ card.count }}</div>
                <div class="title">{{ card.title }}</div>
              </div>
              <div class="para-wrap">
                <p>
                  {{ card.content }}
                </p>
              </div>
              <div class="icon">
                <img :src="card.image" />
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const settings = {
      itemsToShow: 1,
      snapAlign: "center",
    };
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    const breakpoints = {
      // 700px and up
      768: {
        itemsToShow: 2,
        snapAlign: "left",
      },
      // 1024 and up

      1024: {
        itemsToShow: 2.5,
        snapAlign: "left",
      },
    };
    const cards = reactive([
      {
        title: "Case Assessment",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour1.png"),
        count: "1.",
      },
      {
        title: "Teleconsultation (if required)",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour2.png"),
        count: "2.",
      },

      {
        title: "Appointment Assistance",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour3.png"),
        count: "3.",
      },
      {
        title: "Visa Assistance",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour4.png"),
        count: "4.",
      },
      {
        title: "Complementary Pick-up and Drop ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour5.png"),
        count: "5.",
      },

      {
        title: "Foreign Language Interpreter Service ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour6.png"),
        count: "6.",
      },

      {
        title: "Express Check-In at International Patient's Lounge ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour7.png"),
        count: "7.",
      },

      {
        title: "Dedicated Buddy/Staff Allocation ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour8.png"),
        count: "8.",
      },

      {
        title: "Food Arrangements ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour9.png"),
        count: "9.",
      },

      {
        title: " Guest House Arrangement (if required) ",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour10.png"),
        count: "10.",
      },

      {
        title: "Ambulance Pick-Up (if required)",
        content:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi quisquam placeat ad voluptate inventore perferendis veritatis libero, eos, eum quis vitae. Praesentium facere porro amet. Ipsa incidunt quasi vel velit nihil esse mollitia assumenda ea rem s quas, aspernatur, cumque alias ipsam officia. Officia magnam nostrum, fugit quibusdam beatae nobis.",
        image: require("../assets/jour11.png"),
        count: "11.",
      },
    ]);
    return {
      cards,
      settings,
      breakpoints,
      Carousel,
      Slide,
      Navigation,
    };
  },
};
</script>
<style scoped lang="less">
.treatment-journey {
  background-color: #f9f9f9;
  h2 {
    text-align: center;
  }
  .intro-block {
    text-align: center;
    margin-bottom: 20px;
    .monitor {
      padding: 0;
      max-width: 200px;

      margin: 0 auto;
    }

    svg {
      max-width: 470px;
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      transition: all 0.5s ease-in-out;
      .ekg {
        fill: none;
        stroke: #0063a8;
        stroke-width: 6;
        stroke-linecap: round;
        stroke-linejoin: miter;
        opacity: 0;
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
        animation: ekg 1.5s linear forwards infinite;
      }
    }
  }
  @keyframes ekg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      stroke-dashoffset: 2000;
    }
    99% {
      opacity: 0;
      stroke-dashoffset: 3000;
    }
    100% {
      stroke-dashoffset: 1000;
    }
  }
  .card {
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 20px; /* Add margin-bottom to create space between cards */
    .card-wrapper {
      box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 30px;
      .header-wrapper {
        display: flex;
        align-items: center;

        .card-header {
          font-size: 42px;
          line-height: 1.2;
          color: #fff;
          font-weight: 600;
          background-color: #0063a8;
          border-radius: 12px;
          min-width: 70px;
          color: #fff;
          text-align: center;
          padding: 7px;
          line-height: 1.1;
        }
        .title {
          font-size: 20px;
          color: #454545;
          margin-left: 15px;
        }
      }

      p {
        text-align: left;
        line-height: 1.6;
        word-break: break-word;
        margin: 15px 0px;
      }
      .icon {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        img {
          filter: invert(1);
        }
      }
    }
  }
}
:deep(.carousel__prev ){
  left: -76px;
  @media (max-width: 1024px) {
    left: -44px;
  }
  @media (max-width: 767px) {
    left: -21px;
  }
}
:deep(.carousel__icon) {
  width: 54px;
  height: 29px;
  border-radius: 50%;
  background-color: #0063a8;
  color: #fff;
}
:deep(.carousel__next) {
  right: -76px;
  @media (max-width: 1024px) {
    right: -44px;
  }
  @media (max-width: 767px) {
    right: -21px;
  }
}
</style>