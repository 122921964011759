<template>
  <div class="update-container">
    <div class="container">
          <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Our Centres Of Excellence</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Our Centres Of Excellence</h2>
    </div>
  </div>
</div>
 
    <div class="prevarrow">
<img class="cursoreffect" src="../assets/previousbtn.png">
    </div>
      <div class="carousel-frame" ref="container">
        <ul>
          <li class="carousel-item" v-for="slide in slides" :key="slide">
            <div class="card-slide">
              <img class="img" :src="slide.img" />
              <h3>{{ slide.heading }}</h3>
              <div class="content">
                <div>
                  <p>{{ slide.title }}</p>
                </div>

                <div class="common-button">
                  <p class="anchor-button cursoreffect">Know More ...</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
         <div class="nextarrow">
<img class="cursoreffect" src="../assets/nextbtn.png">
    </div>
    </div>
  </div>
  <!-- <div id="speed">Speed: {{ speed }}</div>
  <div id="scroll">Scroll: {{ scroll }}</div> -->
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "UpdatesComp",
  components: {
    EcgComp,
  },
  setup() {
    const speed = ref(0);
    const scroll = ref(0);
    const container = ref(null);
  const scrollLeft = ref(null);
    const onMouseMove = (e) => {
      if (container.value) {
        const mouseX = e.pageX - container.value.getBoundingClientRect().left;
        const mousePerc = (100 * mouseX) / container.value.offsetWidth;
        speed.value = mousePerc - 50;
      }
    };

    const onMouseLeave = () => {
      speed.value = 0;
    };

    const updateScroll = () => {
      if (container.value && speed.value !== 0) {
        scroll.value += speed.value / 5;
        if (scroll.value < 0) scroll.value = 0;
        container.value.scrollLeft = scroll.value;
      }
      window.requestAnimationFrame(updateScroll);
    };

    onMounted(() => {
      if (container.value) {
        container.value.addEventListener("mousemove", onMouseMove);
        container.value.addEventListener("mouseleave", onMouseLeave);
        window.requestAnimationFrame(updateScroll);
      }
    });

    onBeforeUnmount(() => {
      if (container.value) {
        container.value.removeEventListener("mousemove", onMouseMove);
        container.value.removeEventListener("mouseleave", onMouseLeave);
      }
    });
    const slides = reactive([
      {
        img: require("../assets/fac1.png"),
        heading: "Cardiac Sciences",
        title:
          "Noble hospital Cardiac Department provides end-to end cardiac care, comprising of Interventional Cardiology, Non-Interventional Cardiology, and Intensive Cardiac Care Unit.",
      },
      {
        img: require("../assets/face2.png"),
        heading: "Diabetes",
        title:
          "“Centre of Excellence for Diabetes and Obesity” includes many steps in the treatment as Diabetes and Obesity can’t just be controlled by drugs. This Requires Complete Teamwork which ensures the prevention of complications of Diabetes and Obesity.",
      },
      {
        img: require("../assets/face3.png"),
        heading: "Fertility Medicine",
        title:
          "Fertility Medicine is a branch of medicine that encompasses treating a variety of reproductive conditions — causing infertility — as well as preventing them. A diagnosis of infertility means you haven’t been able to get pregnant after a year of trying to conceive.",
      },
      {
        img: require("../assets/fac1.png"),
        heading: "Facility Launched",
        title:
          "Noble hospital Cardiac Department provides end-to end cardiac care, comprising of Interventional Cardiology, Non-Interventional Cardiology, and Intensive Cardiac Care Unit.",
      },
      {
        img: require("../assets/face2.png"),
        heading: "Diabetes",
        title:
          "“Centre of Excellence for Diabetes and Obesity” includes many steps in the treatment as Diabetes and Obesity can’t just be controlled by drugs. This Requires Complete Teamwork which ensures the prevention of complications of Diabetes and Obesity.",
      },
      {
        img: require("../assets/face3.png"),
        heading: "Fertility Medicine",
        title:
          "Fertility Medicine is a branch of medicine that encompasses treating a variety of reproductive conditions — causing infertility — as well as preventing them. A diagnosis of infertility means you haven’t been able to get pregnant after a year of trying to conceive.",
      },
    ]);

    return {
      speed,
      scroll,
      container,
      slides,
      scrollLeft
    };
  },
};
</script>

<style lang="less" scoped>
.update-container {
  background: linear-gradient(45deg, #186fbc70, #64c6987d);
  position: relative;
   .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .prevarrow {
    position: absolute;
    cursor: pointer;
    left: 90px;
    /* left: 50%; */
    top: 60%;
     @media(max-width:1600px){
      left: 36px;
    }
      @media(max-width:1024px){
     display: none;
    }
}
.nextarrow {
  cursor: pointer;
    position: absolute;
   right: 90px;
    /* left: 50%; */
    top: 60%;
    @media(max-width:1600px){
      right: 36px;
    }
    @media(max-width:1024px){
     display: none;
    }
}
  .carousel-frame {
    width: 100%;
    margin-bottom: 0.5em;
    padding-bottom: 1em;
    position: relative;
    //   overflow-x: scroll;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .carousel-frame ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
  }
  .carousel-frame li.carousel-item {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
    width: 33%;
    @media (max-width: 1024px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .card-slide {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
    padding: 30px 40px;
    text-align: center;
    margin: 10px;
    @media (max-width: 1600px) {
      padding: 30px 30px;
    }
    @media (max-width: 1024px) {
      padding: 30px 40px;
    }
    @media (max-width: 767px) {
      padding: 30px 10px;
    }
    .img {
      width: 82px;
      height: 82px;
      object-fit: contain;
      filter: invert(1);
    }
p{
    line-height: 1.6;
    white-space: pre-line;
    color: #58595b;
    margin: 18px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
    .content {
      .anchor-button {
        display: inline-flex;
        gap: 10px;
        padding-top: 0px;
        font-family: "FuturaMediumBT";
        align-items: center;
        cursor: pointer;
        color: #0063a8;
        &:hover {
          color: #0063a8;
        }
        span {
          line-height: 18px;
          width: 25px;
          height: 25px;

          img {
            background-color: #0063a8;
            border-radius: 50%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
