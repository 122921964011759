<template>
  <div class="technology-slider">
    <div class="container">
     <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Technology</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Technology</h2>
    </div>
  </div>
</div>
      <div class="heading_content">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
          voluptates provident aspernatur id laborum blanditiis.
        </p>
      </div>

      <div class="wrapper">
        <div class="center-slider" ref="carousel">
          <div class="slide-item" v-for="(slide, index) in slides" :key="index">
            <div class="img">
              <img :src="slide.img" alt="" />
            </div>

            <div class="title">
              <h3>{{ slide.title }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="description" v-if="selectedSlide !== null">
        <div class="title">
          <h3>{{ slides[selectedSlide].title }}</h3>
          <p>
            {{ slides[selectedSlide].description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import EcgComp from "../components/EcgComp.vue";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";

export default {
  name: "TechnologyComp",
  components: { EcgComp },
  setup() {
    const carousel = ref(null);
    const slides = reactive([
      {
        img: require("../assets/technology_slider1.jpg"),
        title: "Artis-Zeego Endovascular Surgical Cath Lab",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
      {
        img: require("../assets/technology_slider2.jpg"),
        title: "256 Slice CT",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
      {
        img: require("../assets/technology_slider3.jpg"),
        title: "3.0 Tesla MRI",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
      {
        img: require("../assets/technology_slider1.jpg"),
        title: "Artis-Zeego Endovascular Surgical Cath Lab",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
      {
        img: require("../assets/technology_slider2.jpg"),
        title: "256 Slice CT",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
      {
        img: require("../assets/technology_slider3.jpg"),
        title: "3.0 Tesla MRI",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem totam aspernatur incidunt obcaecati nobis voluptates ipsam, accusantium, ullam voluptatibus voluptatem nemo odio assumenda cumque pariatur, ducimus officia veniam hic dignissimos?",
      },
    ]);

    const selectedSlide = ref(0); // Set to the first slide by default
    const showDescription = (index) => {
      if (index < 0) {
        selectedSlide.value = slides.length - 1; // Show last slide's description
      } else if (index >= slides.length) {
        selectedSlide.value = 0; // Show first slide's description
      } else {
        selectedSlide.value = index; // Show description of the clicked slide
      }
    };

    onMounted(() => {
      $(carousel.value).slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        centerPadding: "0px",
        autoplaySpeed: 5000,
        autoplay: 2000,
        speed: 300,
        responsive: [
          {
            breakpoint: 768, // Mobile breakpoint
            settings: {
              slidesToShow: 1, // Show 1 slide on mobile
              centerMode: false, // Disable center mode on mobile
              centerPadding: "0px", // No padding on mobile
            },
          },
        ],
      });

      $(carousel.value).on("afterChange", (event, slick, currentSlide) => {
        let actualIndex = currentSlide % slides.length; // Adjust index for cloned slides
        showDescription(actualIndex);
      });

      // Show the first slide by default
      showDescription(0);
    });

    return {
      carousel,
      slides,
      selectedSlide,
      showDescription,
    };
  },
};
</script>


<style lang="less" scoped>
.technology-slider {
  background-color: #f4f4f4;
     .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .slide-item .title {
    position: relative;
    padding: 34px;
    @media screen and (max-width: 1024px) {
      padding: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 18px;
    }
  }
  // :deep .slide-item .title::after {
  //   width: 28px;
  //   height: 28px;
  //   background: url("../assets/tech-down.png") no-repeat center center;
  //   content: "";
  //   position: absolute;
  //   right: 15px;
  //   top: 50%;
  //   margin-top: -14px;
  //   border-radius: 50%;
  //   display: block;
  //   @media screen and (max-width: 767px){
  //     right: 10px;
  //   }
  // }
  .description {
    padding: 20px;
    @media (max-width: 767px) {
      padding: 0px;
    }
    h3 {
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
    }
  }
  .slide-item {
    h3 {
      white-space: pre-line;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      @media (max-width: 992px) {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .heading_content p {
    width: 100%;
    padding-bottom: 40px;
    text-align: center;
  }
  :deep(.slick-slide div .slide-item) {
    width: 100% !important;
    box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
  }
  :deep(.slick-slide div) {
    width: 100% !important;
  }
  :deep(.slick-slide div .img) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  :deep(.slick-slide div img) {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  :deep(.slick-slider .slick-track),
 :deep(.slick-slider .slick-list) {
    margin-top: 50px;
    margin-bottom: 50px;
    transform: translate3d(0, 0, 0);
    @media screen and (max-width: 767px) {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
  :deep(.center-slider .slick-slide) {
    color: #fff;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
    transition: all 0.4s ease-in-out;
  }

 :deep(.center-slider .slick-slide),
:deep(.center-slider .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"]) {
  transform: scale(0.8, 0.8);
  transition: all 0.4s ease-in-out;
}

:deep(.center-slider .slick-center),
:deep(.center-slider .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"]) {
  transform: scale(1.1);
}

  :deep(.center-slider .slick-current.slick-active) {
    transform: scale(1.1);
    @media (max-width: 767px) {
      transform: none;
    }
  }
  :deep(.slick-slide div img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  :deep(.slick-next,
  .slick-prev) {
    z-index: 5;
  }
  :deep(.slick-prev:before) {
    content: url("../assets/previousbtn.png");
  }
  :deep(.slick-next:before) {
    content: url("../assets/nextbtn.png");
  }
  :deep(.slick-next) {
    right: -5px;
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  :deep(.slick-prev ){
    // left: 15px;

    @media (max-width: 767px) {
      display: none !important;
    }
  }
  :deep(.slick-next:before),
 :deep(.slick-prev:before){
    color: #0063a8;
    font-size: 36px;
    opacity: initial;
  }
}
</style>
