<template>
  <div>
    <div class="cursor" ref="cursor"></div>
    <PromotionsComp />
    <AboutComp />
    <DoctorsComp />
    <div class="wrapper">
      <div class="ParallaxVideo">
        <video autoplay muted loop playsinline>
          <source src="../assets/video3.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="spacer"></div>
    </div>
    <div :class="videodata ? 'patient_wrapper_active' : 'patient-wrapper'">
      <PatientStory
        @showvideodata="showvideodata"
        @closevideodata="closevideodata"
      />
      <InternationalPatients />
    </div>

    <div class="wrapper">
      <div class="ParallaxImg">
        <img src="../assets/parallel.jpg" />
      </div>

      <div class="spacer"></div>
    </div>
    <div class="patient-wrapper">
      <ServicesComp />
    </div>

    <div class="wrapper updatewrapper">
      <div class="ParallaxVideo2">
        <video autoplay muted loop playsinline>
          <source src="../assets/video2.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="spacer"></div>
    </div>
    <div class="patient-wrapper2">
      <UpdatesComp />

      <NobleMiles />
    </div>
    <NewsEvent />
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import AboutComp from "../components/AboutComp.vue";

import PromotionsComp from "../components/PromotionsComp.vue";
import DoctorsComp from "../components/DoctorsComp.vue";
import PatientStory from "../components/PatientStory.vue";
import NewsEvent from "../components/NewsEvent.vue";
import NobleMiles from "../components/NobleMiles.vue";
import UpdatesComp from "../components/UpdatesComp.vue";
import ServicesComp from "../components/ServicesComp.vue";
import InternationalPatients from "../components/InternationalPatients.vue";

export default {
  name: "HomePage",
  components: {
    AboutComp,
    PromotionsComp,
    DoctorsComp,
    PatientStory,
    NewsEvent,
    UpdatesComp,
    ServicesComp,
    NobleMiles,
    InternationalPatients,
  },
  setup() {
    const cursor = ref(null);
    const videodata = ref(false);

    const showvideodata = (newData) => {
      videodata.value = newData;
    };
    const closevideodata = (newData) => {
      videodata.value = newData;
    };
    onMounted(() => {
      const cursorinner = cursor.value;
      const navLinksElements = document.querySelectorAll(".cursoreffect");

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow");
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow");
        });
      });
    });

    return {
      cursor,
      showvideodata,
      closevideodata,
      videodata,
    };
  },
};
</script>
<style scoped lang="less">
.patient_wrapper_active {
  z-index: 99999;
  position: relative;
  background: #fff;
}
// cursor effect
.cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #edeef0;
  opacity: 0.6;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  transform-origin: 65% 65%;
  z-index: 99999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-grow {
  transform: scale(2.8);
  background-color: #64c698;
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.2;
  transition: all 0.01s linear;
  z-index: 99999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-grow1 {
  transform: scale(4);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.8;
  transition: all 0.01s linear;
  z-index: 99999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-shadow {
  transform: scale(4);
  background-color: #edeef0;
  box-shadow: 0px 0px 3px 0.5px #888888;
  opacity: 0.2;
  z-index: 99999999;
  transition: all 0.3s linear;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
// cursor effect
.updatewrapper {
  margin-bottom: -80vh !important;
}
.wrapper {
  margin-bottom: -100vh;
  z-index: 1;
  position: relative;
  width: 100%;
}
.ParallaxVideo {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
}
.ParallaxVideo2 {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.ParallaxImg {
  width: 100%;
  position: sticky;
  top: 0;
}
video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.spacer {
  z-index: 2;
  height: 100vh;
  position: relative;
  width: 100%;
}
.patient-wrapper {
  z-index: 2;
  position: relative;
  // height: 100vh;
  background: #fff;
  @media (max-width: 767px) {
    height: 100%;
  }
}
.patient-wrapper2 {
  z-index: 2;
  position: relative;

  background: #fff;
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media (max-width: 767px) {
    height: 100vh;
  }
}
</style> 