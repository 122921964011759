<template>
       <div class="static-pages container">
        <h2>Accessibility Statement</h2>
        <div class="text-container">
      <p><b>Noble Clinic is dedicated to making our website as accessible as possible for all.</b></p>
<p>In the spirit of this commitment, we have ongoing efforts to meet WCAG version 2.1 level AA criteria.</p>
<p>If you need any patient portal assistance, please contact noble Clinic Customer Assistance at <span ><a href="tel:+91-22-69315555 " >+91-22-69315555</a></span>. Our hours of operations are Monday – Friday, 7&nbsp;a.m. to 7&nbsp;p.m., Central time zone.</p>
<p>If you need additional assistance regarding another topic, please <a href="/contact-us" >contact us</a>.</p>

        </div>
       </div>
</template>
<script>
export default{
    setup(){
        return{
            
        }
    }
}
</script>
<style scoped>

</style>
