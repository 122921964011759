<template>
  <div class="treatments-section">
    <div class="container">
            <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Treatments</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Treatments</h2>
    </div>
  </div>
</div>
      <div class="heading_content">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
          voluptates provident aspernatur id laborum blanditiis.
        </p>
      </div>
      <div class="main-slider">
        <div class="right">
          <button
            v-for="tab in tabs"
            :key="tab.name"
            class="btn"
            :class="{ active: activeTab === tab.name }"
            @click="setActiveTab(tab.name)"
          >
            <div class="btn-content">
              <p>{{ tab.name }}</p>
              <img src="../assets/green-right-arrow.png" alt="" />
            </div>
          </button>
        </div>
        <div class="left">
          <div class="items-content" v-for="tab in tabs" :key="tab.name">
            <div
              class="item"
              v-if="activeTab === tab.name"
              :class="{ active: activeTab === tab.name }"
            >
              <div class="content">
                <div class="image"><img :src="tab.image" alt="" /></div>
                <p>{{ tab.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MOBILE DEVICE NAV AND TAB SECTION -->
      <div class="left-section-mob">
        <div
          v-for="(item, index) in sections"
          :key="index"
          :class="item.wrapperClass"
          :id="item.id"
        >
          <div class="head-wrapp" @click="showContentHandler(index)">
            <h2>{{ item.title }}</h2>
            <img
              src="../assets/arrowhead-up.png"
              :class="{ active: activeContent === index }"
            />
          </div>
          <div class="content" v-if="activeContent === index">
            <div class="image">
              <img :src="item.mob_image" alt="" />
            </div>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import EcgComp from "./EcgComp.vue";

export default {
  name: "TreatmentSpeciality",
  components: {
    EcgComp,
  },
  setup() {
    const activeTab = ref(
      "Transcatheter aortic valve implantation (TAVI) First"
    );

    const tabs = reactive([
      {
        name: "Transcatheter aortic valve implantation (TAVI) First",
        content:
          "1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!",
        image: require("../assets/speciality_treatment.png"),
      },
      {
        name: "Transcatheter aortic valve implantation (TAVI)2",
        content:
          "2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!",
        image: require("../assets/speciality_treatment.png"),
      },
      {
        name: "Transcatheter aortic valve implantation (TAVI)3",
        content:
          "3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!",
        image: require("../assets/speciality_treatment.png"),
      },
      {
        name: "Transcatheter aortic valve implantation (TAVI)4",
        content:
          "4 Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae voluptate ipsam, facilis nobis non natus. Voluptatibus rerum saepe praesentium molestias molestiae beatae atque in modi, corrupti itaque vero aut veniam!",
        image: require("../assets/speciality_treatment.png"),
      },
    ]);

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    // mobile content start
    const activeContent = ref(null);
    const sections = [
      {
        wrapperClass: "bio-wrapper",
        id: "Biographical",
        title: "Transcatheter aortic valve implantation (TAVI)1",
        content:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum reprehenderit temporibus voluptas repellendus rem officiis itaque blanditiis dolorum nihil consequatur, alias quae laborum tempora voluptatum magni ratione sit cum amet minima quis eligendi aliquam sunt. Recusandae deleniti aperiam et debitis voluptates, iure commodi voluptate eaque nam ab, sequi, tenetur eius saepe consequatur quam rem ad accusamus nesciunt dolorem! Quod, adipisci!",
        mob_image: require("../assets/speciality_treatment.png"),
      },
      {
        wrapperClass: "cont-wrapper",
        id: "Conditions",
        title: "Transcatheter aortic valve implantation (TAVI)2",
        content:
          "2Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum reprehenderit temporibus voluptas repellendus rem officiis itaque blanditiis dolorum nihil consequatur, alias quae laborum tempora voluptatum magni ratione sit cum amet minima quis eligendi aliquam sunt. Recusandae deleniti aperiam et debitis voluptates, iure commodi voluptate eaque nam ab, sequi, tenetur eius saepe consequatur quam rem ad accusamus nesciunt dolorem! Quod, adipisci!",
        mob_image: require("../assets/speciality_treatment.png"),
      },
      {
        wrapperClass: "cont-wrapper",
        id: "Procedures",
        title: "Transcatheter aortic valve implantation (TAVI)3",
        content:
          "3Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum reprehenderit temporibus voluptas repellendus rem officiis itaque blanditiis dolorum nihil consequatur, alias quae laborum tempora voluptatum magni ratione sit cum amet minima quis eligendi aliquam sunt. Recusandae deleniti aperiam et debitis voluptates, iure commodi voluptate eaque nam ab, sequi, tenetur eius saepe consequatur quam rem ad accusamus nesciunt dolorem! Quod, adipisci!",
        mob_image: require("../assets/speciality_treatment.png"),
      },
      {
        wrapperClass: "cont-wrapper",
        id: "Interests",
        title: "Transcatheter aortic valve implantation (TAVI)4",
        content:
          "4Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum reprehenderit temporibus voluptas repellendus rem officiis itaque blanditiis dolorum nihil consequatur, alias quae laborum tempora voluptatum magni ratione sit cum amet minima quis eligendi aliquam sunt. Recusandae deleniti aperiam et debitis voluptates, iure commodi voluptate eaque nam ab, sequi, tenetur eius saepe consequatur quam rem ad accusamus nesciunt dolorem! Quod, adipisci!",
        mob_image: require("../assets/speciality_treatment.png"),
      },
    ];

    const showContentHandler = (index) => {
      activeContent.value = activeContent.value === index ? null : index;
    };

    return {
      activeTab,
      tabs,
      setActiveTab,
      activeContent,
      sections,
      showContentHandler,
    };
  },
};
</script>

<style lang="less" scoped>
.treatments-section {
  margin: 0 auto;
   .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .heading_content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -20px;
    padding-bottom: 20px;
    p {
      width: 100%;
      max-width: 400px;
      text-align: center;
    }
  }
  .main-slider {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .left {
      width: 70%;
      .items-content {
        .item {
          display: none;
          padding: 0.75rem;
          font-size: 1.25rem;
          .content {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            p {
              width: 50%;
            }
            .image {
              width: 50%;
              display: flex;
              justify-content: center;
              padding: 30px;
              align-items: center;
              @media screen and (max-width: 1280px) {
                padding: 0px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          &.active {
            display: block;
          }
        }
      }
    }
    .right {
      width: 30%;

      .btn {
        display: block;
        width: 100%;
        // padding: 0.75rem;
        border-radius: 3px;
        font-size: 1.25rem;
        margin: 20px 0;
        outline: none;
        cursor: pointer;
        border: none;
        background: none;
        background-color: rgba(0, 0, 0, 0);
        .btn-content {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          align-items: center;
          width: 100%;
          border: 1px solid #68c79b;
          border-radius: 30px;
          background: #f4f4f4;
          @media screen and (max-width: 1280px) {
            padding: 10px 5px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }

        &.active .btn-content {
          background: #fff;
        }
      }
    }
  }
}

// mobile accordion

.left-section-mob {
  // .active {
  //   transform: rotate(180deg);
  // }
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
  .head-wrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0px;
      @media screen and (max-width: 576px) {
        font-size: 17px;
        font-weight: 500;
      }
    }

    .active {
      transform: rotate(180deg);
    }
  }

  .bio-wrapper,
  .cont-wrapper {
    border: 1px solid #d6d6d6;
    padding: 15px 10px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 5px;
    .image {
      width: 50%;
      height: 250px;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
      @media screen and (max-width: 480px) {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    p {
      width: 50%;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }
}
</style>
