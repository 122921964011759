<template>
    <div class="doctor_details_section">
      <div class="container">
        <div class="doctor_detail">
          <div class="left">
            <div class="content">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
              eum autem quos iste, non ut sed similique sequi aut quibusdam id, ex
              aliquid optio, laborum ratione culpa saepe sunt veritatis sapiente
              amet officia delectus. Earum soluta quis eaque aut, possimus
              molestias cumque ratione natus neque quidem error, quibusdam animi
              et incidunt hic expedita in nulla modi? Doloremque ratione explicabo
              accusamus sunt asperiores enim, alias inventore quos repudiandae,
              delectus at voluptatum officia ab ut aspernatur dolores fugit
              quaerat, eligendi possimus voluptatem?
            </div>
          </div>
          <div class="mid">
            <div class="image">
              <img src="../assets/dileep_mane.png" alt="" />
            </div>
          </div>
          <div class="right">
            <div class="details">
              <h2>Dr. Dileep Mane</h2>
              <h3>Founder, Chairman And Managing Director</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  
  export default {
    name: "ChairmanDetail",
   
  };
  </script>
  
  <style lang="less" scoped>
 
  .doctor_details_section {
    .doctor_detail {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .left {
        width: 30%;
        @media screen and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 40px;
        }
      }
      .mid {
        width: 40%;
        @media screen and (max-width: 1280px) {
          width: 50%;
        }
        @media screen and (max-width: 1024px) {
          width: 60%;
        }
        @media screen and (max-width: 576px) {
          width: 100%;
        }
        .image {
          padding: 0px 60px;
          @media screen and (max-width: 1280px) {
            padding: 0px 20px;
          }
          @media screen and (max-width: 1024px) {
            height: 320px;
          }
          @media screen and (max-width: 576px) {
            width: 100%;
            height: auto;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .right {
        width: 30%;
        @media screen and (max-width: 1280px) {
          width: 20%;
        }
        @media screen and (max-width: 1024px) {
          width: 40%;
        }
        @media screen and (max-width: 576px) {
          width: 100%;
          text-align: center;
        }
        .details {
          h2 {
            text-align: start;
            @media screen and (max-width: 576px) {
              width: 100%;
              text-align: center;
            }
          }
          h3 {
            @media screen and (max-width: 1024px) {
              padding: 0px;
            }
          }
        }
      }
    }
  }
 
  </style>
  