<template>
  <div class="updates">
 
        <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Our latest storiest</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Our latest storiest</h2>
    </div>
  </div>
</div>
    <div
      class="image-slider container"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        :transition="transition"
        :wrapAround="true"
        @mouseenter="stopAutoplay"
        @mouseleave="startAutoplay"
      >
        <Slide v-for="(slide, index) in slides" :key="index" class="card">
          <div class="card-slide">
            <div class="img-container">
                <div class="img">
          
              <img class="img" :src="slide.img" />
                        
                </div>
              <div class="content2">
                <div class="icon">
                  <img class="cursoreffect" src="../assets/add.png" />
                </div>
                <div class="content">
                  <p>{{ slide.title }}</p>
                  <h5>{{ slide.heading }}</h5>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <!-- <Pagination /> -->
          <Navigation class="cursoreffect" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
// import { Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import EcgComp from "./EcgComp.vue";

export default {
  name: "LatestStories",
  components: {
    // Pagination,
    Carousel,
    Slide,
    Navigation,
    EcgComp,
  },
  setup() {
    const settings = {
      itemsToShow: 1,
      snapAlign: "center",
    };
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    const breakpoints = {
      // 700px and up
      330: {
        itemsToShow: 1,
        snapAlign: "left",
      },
      576: {
        itemsToShow: 2,
        snapAlign: "left",
      },
      // 1600 and up
      1500: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      1280: {
        itemsToShow: 3,
        snapAlign: "start",
      },
    };
    const slides = reactive([
      {
        img: require("../assets/story1.jpg"),
        heading:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, laborum.",
        title: "1St May 2018",
      },
      {
        img: require("../assets/story2.jpg"),
        heading:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, laborum.",
        title: "1St May 2018",
      },
      {
        img: require("../assets/story3.jpg"),
        heading:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, laborum.",
        title: "1St May 2018",
      },
      {
        img: require("../assets/story4.jpg"),
        heading:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, laborum.",
        title: "1St May 2018",
      },
      {
        img: require("../assets/story1.jpg"),
        heading:
          "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, laborum.",
        title: "1St May 2018",
      },
    ]);

    const autoplayInterval = ref(4000);

    const stopAutoplay = () => {
      autoplayInterval.value = 0;
    };

    const startAutoplay = () => {
      autoplayInterval.value = 4000;
    };

    return {
      breakpoints,
      settings,
      Carousel,
      Slide,
      slides,
      autoplayInterval,
      stopAutoplay,
      startAutoplay,
    };
  },
};
</script>

<style lang="less" scoped>
.updates {
  background: #fafafa;
  padding-top: 60px;
   .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .image-slider {
    padding-bottom: 60px;
    padding-top: 0px;
    .card {
      display: block;
      cursor: pointer;
      .card-slide {
        position: relative;
        border-radius: 20px;
        padding: 30px 5px;
        text-align: center;
        margin: 10px;

        @media (max-width: 767px) {
          padding: 0px;
        }
        .img-container {
          position: relative;
          border-radius: 20px;
          width: 100%;
          height: 100%;
        
          .img {
    height: 426px;
    width: 100%;
    border-radius: 20px;
  
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
        }

        .content2 {
          position: absolute;
          bottom: 0px;
          border-radius: 20px;
          width: 100%;
          background: rgba(0, 0, 0, 0.3); /* semi-transparent background */
          color: white;
          padding: 10px;
          box-sizing: border-box;
          height: 100%;
          text-align: left;
          &:hover {
            background: rgba(87, 84, 84, 0.3); /* semi-transparent background */
          }
          &:hover .icon {
            visibility: visible;
          }
          &:hover .content {
            display: none;
          }
          .icon {
            visibility: hidden;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          .content {
            position: absolute;
            bottom: 24px;
            color: #fff;
            h5 {
              color: #fff;
            }
            p {
              padding: 20px 0px;
              color: #fff;
            }
          }
        }
      }
      .carousel__icon {
        cursor: pointer;
      }
    }
  }

  :deep(.carousel__next) {
    color: #0e0d0d !important;
    right: -40px;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  :deep(.carousel__icon) {
    fill: #fff4f4;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    background: #0063a8;
    width: 100%;
    height: 100%;
  }
  :deep(.carousel__prev ){
    color: #1d1b1b !important;
    left: -40px;

    @media (max-width: 1024px) {
      display: none;
    }
  }
}


</style>