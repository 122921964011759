<template>
  <div class="awards">
  <div class=" container">
    <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Awards</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Awards</h2>
    </div>
  </div>
</div>
      <div class="awards-section">
      


            <div class="awards-content">
        <p>We have received many awards for the hospital.</p><br>
        <p>Apart from the above milestones the Hospital and Our Team has been honoured with many forms of appreciations and are humbled with each recognition. We try our best to stay humbled and continue in our efforts to improvise further.</p>
   <div class="awards-boxes">
    <div class="box" v-for="card in cards" :key="card">
<div class="img">
<img src="../assets/award.png">
</div>
<div class="content">
<p v-html="card.content"></p>
</div>
    </div>
   </div>
           </div>
   
    </div>
      </div>
  </div>
</template>
<script>
import EcgComp from "../components/EcgComp.vue";
import {reactive} from "vue"
export default{
    name:"AwardsComp",
      components: { EcgComp },
      
    setup(){
        const cards=reactive([
      {
      
        content:"Recognized as a Power Brand by TImes of India Group and Awarded - <b>The Best Multispecialty Hospital in Pune</b>"
      },
       {
      
        content:"Leading Tertiary Care Hospital chain by <b>Elite Health Care</b>"
      },
      {
        content:"Best Multispecialty Hospital in Pune by <b>Times Cyber</b>"
      },
        {
        content:"Best Multispecialty Hospital in Pune at 6th <b>Global Health Care Excellence.</b>"
      },
        {
        content:"Most Promising Robotic Assisted Joint Replacement Centre of the year by <b> World Health Care Achievers</b>"
      },
        {
        content:"Men of the Year by TIMES POWER Group to <b>Dr Dileep Mane</b>"
      },
        {
        content:"Women of the Year by TIMES POWER Group to <b>Dr Reema Kashiva</b>"
      },
        {
        content:"Best Nephrologists of the Year by Times Group - <b>Dr Avinash Ignatius</b>"
      },
       {
        content:"Best Robotic Surgeon of the Year by Times Group –<b> Dr Rohit Luthra</b>"
      },
       {
        content:"Best Neuro surgeon of the Year by Times Group – <b>Dr Chandrashekhar Raman</b>"
      },
       {
        content:"Leadership Excellence Award 2019"
      },
       {
        content:"Health Care Leadership Award 2019- 5th Edition"
      },
       {
        content:"Navbharat Awards – Dr Z A Khan, Dr Avinash Ignatius, Dr chandrashekhar and BEST Multispeciality award"
      },
       {
        content:"Times Health Icon Award Dr Duggal, Dr Pote, Dr Shrikant Londhe Patil, Dr Vishal choudhari"
      },
       {
        content:"Pudhari Award to Dr Girija Patil and Dr Aniket Machindra Patil"
      },
       {
        content:"Lokmat Gaurav Vaidikiya Seva Award for <b>Noble Hospitals</b>"
      },
       {
        content:"COVID 19 Frontline Women Warrior Award to Dr Reema and Sr Tripti Nanda."
      },
         {
        content:"Lokmat Health Care Award to Hospital Dr Sale Received"
      },
       {
        content:"Gurupoornima Awards – Dr Mane , Dr Sale , Dr Khan, Dr Reema, Dr Raut, Dr Lingayat, Dr Dravid, Dr Abnave"
      },
         {
        content:"Navbharat Awards- Life time achievement Award –<b> Dr Sale</b>"
      },
       {
        content:"Navbharat Awards- Best Multispecialty Covid Centre -<b> Dr Mane Sir</b>"
      },
       {
        content:"Quality Certificate - Infusion Nurses Society - India Recognizes"
      },
       {
        content:"Lifetime Achievement Award by the Indian Association of Leprologists - <b>Dr Vitthal Jadhav,</b> HOD Dermatology (2022)"
      },
       {
        content:"<b>Dr HK Sale,</b> Executive Director and Director - Department of Anesthesiology, took over as President of Indian Society of Anesthesiology (Maharashtra chapter) (2022)"
      },
      {
       content:"Iconic Multispecialty Hospital Award, instituted by NavaBharat Group (2022)" 
      }
      ])
        return{
cards
        }
    }
}
</script>
<style lang="less" scoped>
.awards{
    .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }

.awards-section{
    .awards-content{
        background: #0063a8;
            color: #fff;
                padding: 60px 40px;
p{
    text-align: center;
        color: #fff;
}
.awards-boxes{
    padding-top: 60px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .box {
    border: 2px solid #fff;
    display: flex;
    flex-direction: column;
  border-radius: 10px;
    align-items: center;
    width: 23%;
    margin: 15px 0px;
    @media(max-width:1024px){
        width: 45%;
    }
      @media(max-width:767px){
        width: 100%;
    }
    .content {
    padding: 20px;
    p{
      color: #fff;
    }

}
}
}
    }
}
}
</style>
