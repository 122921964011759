<template>
  <div
    class="backdrop"
    @click="closevideohandler"
    :class="{ active: showvideo }"
  ></div>
  <div class="patientstory-section">
    <div class="awards container" data-aos="fade-up" data-aos-duration="2000">
      <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
        <EcgComp />
        <div class="desktop-heading">
          <h2>Patient Testimonials</h2>
        </div>
        <div class="mob-marquee">
          <div
            class="marquee-content"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <h2>Patient Testimonials</h2>
          </div>
        </div>
      </div>

      <Carousel
        :itemsToShow="3"
        :autoplay="autoplayInterval"
        :wrapAround="true"
        :transition="transition"
        @mouseenter="stopAutoplay"
        @mouseleave="startAutoplay"
      >
        <Slide v-for="(slide, index) in slides" :key="index">
          <div class="carousel__item">
            <div class="card cursoreffect" @click="videohandler">
              <video
                class="media-element"
                playsinline
                ref="videos"
                :src="slide.video"
                :poster="slide.poster"
                type="video/mp4"
                muted
                @mouseover="playVideo($event, index)"
                @mouseout="pauseVideo($event, index)"
              ></video>
              <div class="play-img" v-if="utubeicon[index]">
                <img src="../assets/play-icon.png" />
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Navigation class="cursoreffect" />
        </template>
      </Carousel>
    </div>
    <!-- youtube video section -->

    <div class="video-wrapper" v-if="showvideo">
      <div class="video-section">
        <div class="overlay-content-video">
          <div class="close-icon">
            <img @click="closevideohandler" src="../assets/close.png" />
          </div>
          <iframe
            src="https://www.youtube.com/embed/TJA7V1Yx1XM?autoplay=1&si=rMemSg5sC0hWFYOn"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { reactive, ref, nextTick } from "vue";
import "vue3-carousel/dist/carousel.css";
import EcgComp from "./EcgComp.vue";

export default {
  name: "PatientStory",
  components: {
    Carousel,
    Slide,
    Navigation,
    EcgComp,
  },
  setup(props, { emit }) {
    const showvideo = ref(false);
    const slides = reactive([
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
      {
        poster: require("../assets/Story.png"),
        video: require("../assets/video-story.mp4"),
      },
    ]);

    const utubeicon = ref(slides.map(() => true));
    const videoRefs = ref([]);

    const videohandler = () => {
      showvideo.value = true;
      document.body.style.overflow = "hidden";
      emit("showvideodata", showvideo.value);
    };

    const closevideohandler = () => {
      showvideo.value = false;
      document.body.style.overflow = "";
      emit("closevideodata", showvideo.value);
    };

    const playVideo = (event, index) => {
      const video = event.target;
      video.play();
      utubeicon.value[index] = false;
    };

    const pauseVideo = async (event, index) => {
      const video = event.target;
      setTimeout(async () => {
        video.pause();
        utubeicon.value[index] = true;
        // Force video to reload to show poster image
        video.load();
        await nextTick();
      }, 100); // Adding a small delay to avoid play-pause conflict
    };
    const autoplayInterval = ref(4000);

    const stopAutoplay = () => {
      autoplayInterval.value = 0;
    };

    const startAutoplay = () => {
      autoplayInterval.value = 4000;
    };
    return {
      slides,
      playVideo,
      pauseVideo,
      utubeicon,
      showvideo,
      videohandler,
      closevideohandler,
      videoRefs,
      autoplayInterval,
      stopAutoplay,
      startAutoplay,
    };
  },
};
</script>
<style scoped lang="less">
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}

.backdrop.active {
  display: block;
}

.awards {
  h3 {
    text-align: left;
  }
  .ecg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    .desktop-heading {
      display: block;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .mob-marquee {
      display: none;
      @media (max-width: 767px) {
        display: block;

        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        box-sizing: border-box;
      }
    }
    .marquee-content {
      display: inline-block;
      white-space: nowrap;
      animation: marquee 10s linear infinite;
    }
    @keyframes marquee {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
}

.video-section {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  .close-icon {
    position: absolute;
    top: -34px;
    right: -32px;
    width: 20px;
    height: 20px;
    @media (max-width: 767px) {
      top: -28px;
      right: -5px;
    }
    img {
      width: 100%;
      cursor: pointer;
      filter: invert(1);
    }
  }
  iframe {
    width: 900px;
    height: 700px;
    @media (max-width: 1600px) {
      width: 700px;
      height: 500px;
    }
    @media (max-width: 1024px) {
      width: 700px;
      height: 700px;
    }
    @media (max-width: 767px) {
      width: auto;
      height: 315px;
    }
  }
}

.card {
  border-radius: 20px;
  video {
    cursor: pointer;
    clip-path: fill-box;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 20px;
    border: none;
    display: block;
    background-color: #ffffff;
    @media (max-width: 767px) {
      margin-top: 0px;
      margin-bottom: 0px;
      width: 100%;
    }
  }
  .play-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.card-slide {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
  .image {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }
  .text {
    padding: 40px 32px;
    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: #4c4c4d;
      text-align: left;
    }
    .designation {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #7d8398;
      text-align: left;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: #4c4c4d;
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }
    @media (max-width: 1024px) {
      padding: 30px 19px;
    }
    @media (max-width: 767px) {
      padding: 20px 20px;
    }
    h3 {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .content {
      .para {
        p {
          font-size: 14px;
          line-height: 1.6;
          font-weight: 500;
          color: #58595b;
          text-align: left;
          padding: 18px 0;
        }
        img {
          width: 100%;
        }
      }
      .common-button {
        text-align: left;
        .anchor-button {
          padding: 0px 0px 0px 0px;
          font-weight: 600;
          display: inline-flex;
          gap: 10px;
          font-size: 14px;
          align-items: center;
          cursor: pointer;
          color: #0063a8;
          &:hover {
            color: #0063a8;
          }
          span {
            line-height: 18px;
            width: 25px;
            height: 25px;
            img {
              background-color: #0063a8;
              border-radius: 50%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

:deep(.carousel__next) {
  right: 0px;
  @media (max-width: 1280px) {
    right: 14px;
  }
  @media (max-width: 767px) {
    right: 0px;
    display: none;
  }
}
:deep(.carousel__prev) {
  @media (max-width: 767px) {
    display: none;
  }
}

:deep(.carousel__icon) {
  fill: #fff4f4;
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: #0063a8;
  width: 100%;
  height: 100%;
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
  filter: grayscale(1);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  &:hover {
    transform: rotateY(0) scaleX(1.5) scaleY(1.5);
    padding: 0px 30px;
    z-index: 10;
    filter: grayscale(0);
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.carousel__item {
  width: 100%;
  // margin: 20px 0px;
  @media (max-width: 767px) {
    margin: 0px 0px;
  }
}

.carousel__slide--next {
  opacity: 1;
  &:hover {
    transform: rotateY(0) scaleX(1.5) scaleY(1.5);
    padding: 0px 30px;
    z-index: 9;
    filter: grayscale(0);
  }
  @media (max-width: 1600px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0px !important;
  }
}

.carousel__slide--active {
  // padding: 0px 30px;
  opacity: 1;
  // transform: rotateY(0) scaleX(1.5) scaleY(1.5);

  &:hover {
    transform: rotateY(0) scaleX(1.5) scaleY(1.5);
    padding: 0px 30px;
    z-index: 9;
    filter: grayscale(0);
  }
  @media (max-width: 1600px) {
    padding: 0px;
  }
  @media (max-width: 1024px) {
    padding: 0px 14px;
  }
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0px 64px;
  }
}
</style>
