<template>
  <div class="doctors-list">
    <div class="main container">
      <div class="profile-box" v-for="doctor in doctors" :key="doctor">
        <div class="profile-card">
          <div class="img" @click="gotodetail">
            <div class="image-content">
              <img :src="doctor.image" class="cursoreffect" />
            </div>
            <h5 class="cursoreffect">{{ doctor.name }}</h5>
          </div>
          <div class="caption">
                 <h5 class="doc_name cursoreffect">{{ doctor.name }}</h5>
            <p>{{ doctor.specialization }}</p>
            <div class="appoinment-btn">
              <div class="buttons">
                <div class="appoint-btn cursoreffect">
                  <a href="https://noblehrc.com/patient-portal" target="_blank"
                    >Book an Appointment</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "DoctorsList",
  setup() {
    const router = useRouter();
    const gotodetail = () => {
      router.push("doctors-detail");
      window.scrollTo(0, 0);
    };
    const doctors = [
      {
        name: "Dr. Vishal Chaudhari",
        specialization: "DNB (Orthopedics ) & Spine Surgery Specialist ",
        image: require("../assets/dc9.png"),
      },
      {
        name: "Dr.Pallavi Butiyani",
        specialization: "MD (Anesthesiology)",
        image: require("../assets/dc2.png"),
      },
      {
        name: "Dr. Rahul Dadasaheb Sawant ",
        specialization: "MD, CCT (Cardiology), MRCP",
        image: require("../assets/dc8.png"),
      },
      {
        name: "Dr. Z A Khan",
        specialization: "MBBS, MD (Internal Medicine)",
        image: require("../assets/dc7.png"),
      },
      {
        name: "Dr.Reema Y Kashiva",
        specialization: "M.D (Internal Medicine)",
        image: require("../assets/dc10.jpg"),
      },
      {
        name: "Dr. Sudhir Halikar",
        specialization: "MS (ENT) ",
        image: require("../assets/dc6.png"),
      },
      {
        name: "Dr. Sheetal Uday Harpale",
        specialization: "B.Arch, PGD, MFAAT ",
        image: require("../assets/dc5.png"),
      },
      {
        name: "Dr.S. K Raut",
        specialization: "MBBS, AFIH, DIH, PGCEOH",
        image: require("../assets/dc4.png"),
      },
      {
        name: "Dr.R. Chandrashekhar",
        specialization: "MCh, DNB (Neuro Surgery), MS (General Surgery)",
        image: require("../assets/dc3.png"),
      },
      {
        name: "Dr. Rajendra Gandhi",
        specialization: "M.Ch., Plastic Surgeon",
        image: require("../assets/dc1.png"),
      },
    ];
    return {
      doctors,
      gotodetail,
    };
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 0px;
  .profile-box {
    width: 20%;
    min-height: 342px;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1400px) {
      width: 25%;
    }
    @media screen and (max-width: 1200px) {
      width: 33.33%;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    .profile-card {
      position: relative;

      width: 250px;
      height: 275px;
      background: #bbc1c5;
      padding: 20px 10px 0px 10px;
      border-radius: 10px;
      // box-shadow: 0 0 22px #3336;
      transition: 0.6s;
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 992px) {
        height: 305px;
      }

      &:hover {
        border-radius: 10px;
        height: 308px;
      }
      &:hover .appoinment-btn {
        opacity: 1;
      }

      .img {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-content {
          position: relative;
          width: 180px;
          height: 180px;
          transition: 0.6s;
          border-radius: 10px;
          @media screen and (max-width: 992px) {
            width: 180px;
            height: 180px;
            transform: translateY(-60px);
          }
          @media screen and (max-width: 767px) {
            width: 180px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 10px;
            // box-shadow: 0 0 22px #3336;
            transition: 0.6s;
            @media screen and (max-width: 992px) {
              border-radius: 50%;
            }
          }
        }
        h5 {
          text-align: center;
          color: #fff;
          cursor: pointer;
          padding: 10px 0px;
          line-height: 1.2;
          transition: 0.6s;
          @media screen and(max-width: 992px) {
            transform: translateY(-60px);
            opacity: 0;
          }
        }
      }

      &:hover {
        .image-content {
          transform: translateY(-60px);

          img {
            border-radius: 50%;
            box-shadow: 0px -1px 0px #cacaca;
          }
        }
        .caption {
          opacity: 1;
        }
      }
      &:hover {
        .img {
          h5 {
            transform: translateY(-60px);
            opacity: 0;
          }
        }
      }
    }
    .caption {
      text-align: center;
      width: 100%;
      transform: translateY(-80%);
      opacity: 0;
      transition: 0.6s;
      @media screen and (max-width: 992px) {
        opacity: 1;
      }

      .doc_name {
        text-align: center;
        color: #fff;
        cursor: pointer;
        padding: 0px 0px;
        line-height: 1.2;
        transition: 0.6s;
        opacity: 1;
        @media screen and(max-width: 992px) {
          transform: translateY(0px);
        }
      }

      p {
        font-size: 15px;
        color: #fff;

        margin: 2px 0 9px 0;
        line-height: 1.6;
        width: 100%;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .appoinment-btn {
        background-color: #fff;
        padding: 9px 12px;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0;
        transition: background-color, 0.9s ease-in-out;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        color: #0c52a1;
        @media screen and (max-width: 992px) {
          opacity: 1;
        }
        a {
          color: #0063a8;
          text-decoration: none;
        }
      }
    }
  }
}
</style>