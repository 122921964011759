<template>
  <div class="services-container">
    <div class="container">
      <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
        <EcgComp />
        <div class="desktop-heading">
          <h2>Services</h2>
        </div>
        <div class="mob-marquee">
          <div
            class="marquee-content"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <h2>Services</h2>
          </div>
        </div>
      </div>
      <!-- <div class="prevarrow">
        <img class="cursoreffect" src="../assets/previousbtn.png" />
      </div> -->
      <div class="carousel-frame" ref="container">
        <ul>
          <li class="carousel-item card" v-for="slide in slides" :key="slide">
            <div class="card-slide">
              <div class="image">
                <img class="img" :src="slide.img" />
              </div>

              <div class="slider-content">
                <h3>{{ slide.heading }}</h3>
                <div class="content">
                  <div class="para">
                    <h3>{{ slide.title }}</h3>
                  </div>

                  <div class="common-button">
                    <p class="anchor-button cursoreffect">
                      <img src="../assets/right-arrow.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="nextarrow">
        <img class="cursoreffect" src="../assets/nextbtn.png" />
      </div> -->
    </div>
  </div>
  <!-- <div id="speed">Speed: {{ speed }}</div>
  <div id="scroll">Scroll: {{ scroll }}</div> -->
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "ServicesComp",
  components: {
    EcgComp,
  },
  setup() {
    const speed = ref(0);
    const scroll = ref(0);
    const container = ref(null);
    const scrollLeft = ref(null);
    const onMouseMove = (e) => {
      const mouseX = e.pageX - container.value.getBoundingClientRect().left;
      const mousePerc = (100 * mouseX) / container.value.offsetWidth;
      speed.value = mousePerc - 50;
    };

    const onMouseLeave = () => {
      speed.value = 0;
    };

    const updateScroll = () => {
      if (speed.value !== 0) {
        scroll.value += speed.value / 5;
        if (scroll.value < 0) scroll.value = 0;
        container.value.scrollLeft = scroll.value;
      }
      window.requestAnimationFrame(updateScroll);
    };

    onMounted(() => {
      container.value.addEventListener("mousemove", onMouseMove);
      container.value.addEventListener("mouseleave", onMouseLeave);
      window.requestAnimationFrame(updateScroll);
    });

    onBeforeUnmount(() => {
      container.value.removeEventListener("mousemove", onMouseMove);
      container.value.removeEventListener("mouseleave", onMouseLeave);
    });
    const slides = reactive([
      {
        img: require("../assets/service1.jpg"),
        heading: "Homecare Services",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
      {
        img: require("../assets/service2.jpg"),
        heading: "E-ICU",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
      {
        img: require("../assets/service3.jpg"),
        heading: "TeleMedicine",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
      {
        img: require("../assets/service4.jpg"),
        heading: "Air Ambulance",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
      {
        img: require("../assets/service3.jpg"),
        heading: "TeleMedicine",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
      {
        img: require("../assets/service4.jpg"),
        heading: "Air Ambulance",
        title: "Why young adults need a primary care provider",
        para: "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi modi itaque, debitis aperiam ratione commodi. Vel quasi temporibus maiores facilis!",
      },
    ]);
    return {
      speed,
      scroll,
      container,
      slides,
      scrollLeft,
    };
  },
};
</script>

<style lang="less" scoped>
.services-container {
  .container {
    max-width: 100%;

    .prevarrow {
      cursor: pointer;
      position: absolute;
      left: 90px;

      @media (max-width: 1600px) {
        left: 36px;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .nextarrow {
      cursor: pointer;
      position: absolute;
      right: 90px;
      top: 55%;
      @media (max-width: 1600px) {
        right: 36px;
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .carousel-frame {
      width: 100%;
      margin-bottom: 0.5em;
      padding-bottom: 1em;
      position: relative;
      //   overflow-x: scroll;
      overflow-x: hidden;
      white-space: nowrap;
    }
    .carousel-frame ul {
      margin: 0;
      padding: 0;
      height: 100%;
      list-style: none;
    }
    .carousel-frame li.carousel-item {
      cursor: pointer;
      display: inline-block;
      // margin: 0 5px 0 0;
      padding: 0;
      width: 25%;
      @media (max-width: 1024px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      &:hover
        .card-slide
        .slider-content
        .content
        .common-button
        .anchor-button {
        border: 1px solid transparent;
      }
      &:hover
        .card-slide
        .slider-content
        .content
        .common-button
        .anchor-button
        img {
        filter: invert(1);
      }
      &:hover .card-slide .slider-content .content .para p,
      &:hover .card-slide .slider-content h3 {
        color: #fff;
      }
    }
    .carousel-frame li.carousel-item:nth-child(1) {
      &:hover .card-slide .slider-content {
        background-color: rgba(161, 8, 65, 0.5);
      }
    }
    .carousel-frame li.carousel-item:nth-child(2) {
      &:hover .card-slide .slider-content {
        background-color: rgba(0, 163, 212, 0.5);
      }
    }
    .carousel-frame li.carousel-item:nth-child(3) {
      &:hover .card-slide .slider-content {
        background-color: rgba(0, 185, 80, 0.5);
      }
    }
    .carousel-frame li.carousel-item:nth-child(4) {
      &:hover .card-slide .slider-content {
        background-color: rgba(161, 8, 65, 0.5);
      }
    }
    .carousel-frame li.carousel-item:nth-child(5) {
      &:hover .card-slide .slider-content {
        background-color: rgba(0, 163, 212, 0.5);
      }
    }
    .carousel-frame li.carousel-item:nth-child(6) {
      &:hover .card-slide .slider-content {
        background-color: rgba(0, 185, 80, 0.5);
      }
    }

    .card {
      border-radius: 20px;
    }
    .card-slide {
      border-radius: 20px;
      background-color: #fff;
      // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
      position: relative;
      // margin: 10px;
      .image {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-right: 1px solid gray;
          // border-top-left-radius: 20px;
          // border-top-right-radius: 20px;
        }
      }
      .slider-content {
        padding: 30px 40px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 99%;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.6);
        transition: background-color, 0.4s ease-in-out;
        @media (max-width: 1600px) {
          padding: 30px 30px;
        }
        @media (max-width: 1024px) {
          padding: 30px 19px;
        }
        @media (max-width: 767px) {
          padding: 20px 20px;
        }
        h3 {
          padding-top: 0px;
          padding-bottom: 0px;
          font-size: 13px;
          font-weight: 600;
          color: #4f4f4f;
        }
        .content {
          .para {
            h3 {
              white-space: pre-line;
              color: #4f4f4f;
              text-align: left;
              padding: 17px 0px;
              font-size: 30px;
              font-weight: 300;
              transition: color, 0.4s ease-in-out;
            }

            img {
              width: 100%;
            }
          }
          .common-button {
            text-align: left;
            .anchor-button {
              padding: 0px 0px 0px 0px;

              display: inline-flex;
              gap: 10px;

              align-items: center;
              cursor: pointer;
              color: #0063a8;
              width: 60px;
              border: 2px solid #000;
              padding: 4px 15px;
              border-radius: 36px;
              img {
                width: 100%;
                height: 100%;
              }

              &:hover {
                color: #0063a8;
              }
              span {
                line-height: 18px;
                width: 25px;
                height: 25px;

                img {
                  background-color: #0063a8;
                  border-radius: 50%;
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;

          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          box-sizing: border-box;
        }
      }
      .marquee-content {
        display: inline-block;
        white-space: nowrap;
        animation: marquee 10s linear infinite;
      }
      @keyframes marquee {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    }
  }
}
</style>