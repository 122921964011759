<template>
  <div class="about container">
    <div class="text">
      <h2>Life is precious, we care for it</h2>
      <p title="Gotham Light">
        With a vision to achieve standards of excellence which become a
        benchmark of healthcare practice and a mission to provide superior
        quality integrated healthcare to everybody at an affordable cost.
        <br />
        <br />
        Noble Hospitals Pune was inaugrated on 7th July 2007. Thanks to the
        tireless efforts of our honorable M.D. Dr. Dileep Mane and his team a
        medical facility of this scale and magnitude turned into a reality.
      </p>

      <div class="btn">
        <router-link to="/about-us">
          <button class="cursoreffect">Know More</button></router-link
        >
      </div>
    </div>
    <div class="image">
      <img src="../assets/aboutimg.jpg" />
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutComp",
  setup() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.about {
  margin-top: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1600px) {
    margin-top: 40px;
  }
  @media (max-width: 1024px) {
    display: block;
    margin-top: 20px;
  }
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
  .text {
    max-width: 50%;
    margin: auto;
    padding: 0px 120px;
    @media (max-width: 1280px) {
      max-width: 50%;
      margin: auto;
      padding: 0px 100px;
    }
    @media (max-width: 1024px) {
      padding: 0px 5px;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding: 0px;
    }
    h2 {
      text-align: left;
    }
    p{
      font-family: Gotham Light;
    }
  }
  .image {
    max-width: 50%;
    margin: auto;
    border-radius: 20px;
    @media (max-width: 1024px) {
      padding: 0px 5px;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding: 0px;
    }
    img {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
    }
  }
  .btn {
    padding-top: 25px;
    @media (max-width: 1024px) {
      padding: 25px 0px;
    }
    button {
      border: 2px solid #0063a8;
      background: #ffff;
      border-radius: 50px;
      padding: 13px 25px;
      color: #000;
      cursor: pointer;
      &:hover {
        background: #0063a8;
        color: #fff;
      }
    }
  }
}
</style>
