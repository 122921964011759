<template>
  <div class="noble-container container">
        <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Noble Milestones</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Noble Milestones</h2>
    </div>
  </div>
</div>
    <div class="milestone-container">
      <div class="prevarrow">
        <img class="cursoreffect" src="../assets/previousbtn.png" />
      </div>
      <div class="carousel-frame" ref="container">
        <ul>
          <li class="carousel-item" v-for="slide in slides" :key="slide">
            <div class="card-slide">
              <h3>{{ slide.date }}</h3>
              <div class="content">
                <div>
                  <img
                    src="https://www.medanta.org/assets/images/leaf-left.png"
                    alt="awards-and-recognitions"
                    width="50"
                    height="108"
                  />
                </div>
                <div>
                  <p v-html="slide.title"></p>
                </div>
                <div>
                  <img
                    src="https://www.medanta.org/assets/images/leaf-right.png"
                    alt="awards-and-recognitions"
                    width="50"
                    height="108"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="nextarrow">
        <img class="cursoreffect" src="../assets/nextbtn.png" />
      </div>
    </div>
  </div>
  <!-- <div id="speed">Speed: {{ speed }}</div>
  <div id="scroll">Scroll: {{ scroll }}</div> -->
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "NobleMiles",
  components: {
    EcgComp,
  },
  setup() {
    const speed = ref(0);
    const scroll = ref(0);
    const container = ref(null);
    const scrollLeft = ref(null);

    const onMouseMove = (e) => {
      if (container.value) {
        const mouseX = e.pageX - container.value.getBoundingClientRect().left;
        const mousePerc = (100 * mouseX) / container.value.offsetWidth;
        speed.value = mousePerc - 50;
      }
    };

    const onMouseLeave = () => {
      speed.value = 0;
    };

    const updateScroll = () => {
      if (container.value && speed.value !== 0) {
        scroll.value += speed.value / 5;
        if (scroll.value < 0) scroll.value = 0;
        container.value.scrollLeft = scroll.value;
      }
      window.requestAnimationFrame(updateScroll);
    };

    onMounted(() => {
      if (container.value) {
        container.value.addEventListener("mousemove", onMouseMove);
        container.value.addEventListener("mouseleave", onMouseLeave);
        window.requestAnimationFrame(updateScroll);
      }
    });

    onBeforeUnmount(() => {
      if (container.value) {
        container.value.removeEventListener("mousemove", onMouseMove);
        container.value.removeEventListener("mouseleave", onMouseLeave);
      }
    });
    const slides = [
      {
        date: 2023,
        title:
          " Pune's First and only Multi-Speciality Tertiary Care Centre certified by the Joint Commission International's (JCI) Gold Seal's of Approval<br> NABL",
      },
      {
        date: 2022,
        title:
          "NABH<br>Nursing Excellence<br>Dedicated call centre<br>Diabetes wallet card<br>Noble Mangaldeep. Cancer Foundation",
      },
      {
        date: 2021,
        title: "3 TESLA MRI Magnetom Vida",
      },
      {
        date: 2020,
        title:
          "Noble Fitness Club<br>Brain Stroke Unit<br>Comprehensive Brain Stroke Unit<br> Dedicated Stroke Ambulance<br>Covid Centre",
      },
      {
        date: 2019,
        title: " Noble health guide<br> Dedicated NICU<br> Ambulance",
      },
      {
        date: 2019,
        title:
          " Noble Purandhar<br> Noble Diabeauty Contest<br> Pregnancy Carnival<br>SAKAL foundation for flood relief",
      },
      {
        date: 2019,
        title:
          " Laser machine <br>4D Echo Machine<br> 4D Sonography Machine<br> Obstetric ICU",
      },
      {
        date: 2018,
        title:
          "Robotics Assisted Joint replacement<br> Noble Diabeauty Contest 2018<br>CPS Teaching course<br>Noble Samruddhi",
      },
      {
        date: 2017,
        title:
          "Diabeauty Queen Contest 2017<br>Magazine Inauguration<br>Green OT reaccreditation",
      },
      {
        date: 2016,
        title:
          "Burns ICU<br>Central Medical Store<br> NABH Accreditation<br>Centre of Excellence of Diabetes and Obesity",
      },
      {
        date: 2016,
        title:
          "Cath lab upgraded <br> Advance USG Machine NABH Reaccreditation <br>Green OT certification IVF Center",
      },
      {
        date: 2015,
        title: "Burns ICU",
      },
      {
        date: 2014,
        title: "Central Medical Store",
      },
      {
        date: 2013,
        title: "NABH Accreditation",
      },
      {
        date: 2012,
        title: "Orthotics and Prosthesis (In house)",
      },
      {
        date: 2011,
        title: " Replaced CT 128 slice and MRI 1.5 Tesla (advanced)",
      },
      {
        date: 2009,
        title:
          " Diabeauty Queen Contest 2017<br>Magazine Inauguration <br>Green OT reaccreditation",
      },
      {
        date: 2007,
        title: "Inauguration July",
      },
    ];

    return {
      speed,
      scroll,
      container,
      slides,
      scrollLeft,
    };
  },
};
</script>

<style lang="less" scoped>
.noble-container {
  position: relative;
    .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .carousel-frame {
    width: 100%;
    margin-bottom: 0.5em;
    padding-bottom: 1em;
    position: relative;
    //   overflow-x: scroll;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .carousel-frame ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
  }
  .carousel-frame li.carousel-item {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
    width: 33%;
    @media (max-width: 1024px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .milestone-container {
    position: relative;
    .prevarrow {
      cursor: pointer;
      position: absolute;
      left: -46px;

      bottom: 45%;
      @media (max-width: 1600px) {
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .nextarrow {
      cursor: pointer;
      position: absolute;
      right: -39px;
      /* left: 50%; */
      bottom: 45%;
      @media (max-width: 1600px) {
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .card-slide {
      position: relative;
      border-radius: 20px;
      background-color: #fff;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
      padding: 30px 40px;
      text-align: center;
      margin: 10px;

      @media (max-width: 1600px) {
        padding: 30px 20px;
      }

      @media (max-width: 1024px) {
        padding: 30px 40px;
      }
      @media (max-width: 767px) {
        padding: 20px 10px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 166px;
        @media (max-width: 1600px) {
          height: 210px;
        }

        img {
          filter: invert(1);
        }
      }
      h3 {
        padding-top: 10px;
      }
      p {
        line-height: 1.6;
        white-space: pre-line;
        color: #58595b;
        padding: 18px 0;
      }
    }
  }
}
</style>
