<template>
  <div>
    <!-- cursor effect -->
    <div class="cursor" ref="cursor"></div>
    <div
      class="backdrop"
      @click="
        showform
          ? closeformhandler()
          : showdetailform
          ? closedetailhandler()
          : null
      "
      :class="{ active: showform || showdetailform }"
    ></div>
    <div class="explore-div container">
      <h2>Explore Opportunities</h2>
      <EcgComp />

      <div class="boxes">
        <div class="opportunity-box" v-for="item in cards" :key="item">
          <div class="img">
            <img :src="item.image" alt="Clinician" />
          </div>

          <div class="text">
            <div>
              <h3>{{ item.title }}</h3>
            </div>
            <div class="buttons">
              <div class="button-outer cursoreffect" @click="showapplyform">
                <a> Apply</a>
              </div>
              <div class="button-outer cursoreffect" @click="showdetailhandler">
                <a> View Detail</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- job detail form -->
    <div class="job_content" v-show="showdetailform">
      <div class="job_form">
        <div class="close cursoreffect" @click="closedetailhandler">
          <img src="../assets/close.png" />
        </div>

        <h3>Job Description</h3>
        <p>
          Timely installation, commissioning of machines. <br />
          Provide Service support and technical support to customers<br />
          Spares enquiry generation<br />
          Payment collection<br />
          Ensure customer satisfaction <br /><br />
        </p>
        <h3>Required Skills :</h3>
        <ul>
          <li>Knowledge of Machine installation</li>
          <li>PLC Programming Knowledge</li>
          <li>HMI Programming Knowledge</li>
          <li>Knowledge of I and C closure Reporting and preparing MOM</li>
          <li>Willingness and flexibility for extensive travelling</li>
          <li>Time Management</li>
        </ul>
        <br />

        <p></p>
        <br />

        <div class="job_bottom_bar" @click="showapplyform">
          <a class="apply_modal_btn cursoreffect">Apply Now</a>
        </div>
      </div>
    </div>
    <!-- apply form start -->
    <div class="form" v-show="showform">
      <div class="query-form">
        <div class="close cursoreffect" @click="closeformhandler">
          <img src="../assets/close.png" />
        </div>
        <div class="heading">Query Form</div>
        <div>
          <form>
            <div></div>
            <div class="field">
              <label>Name *</label>
              <input
                type="text"
                class="inputbox"
                placeholder="Enter Your Name"
              />
            </div>
            <div class="field">
              <label>Mobile number *</label>
              <input
                type="number"
                class="inputbox"
                placeholder="Enter Your Mobile Number"
              />
            </div>
            <div class="field">
              <label>Email *</label>
              <input
                type="email"
                class="inputbox"
                placeholder="Enter Your Email"
              />
            </div>
            <div class="field">
              <label>Resume (accepted only pdf, docx) *</label>
              <input type="file" class="inputbox" />
            </div>
            <button type="submit" class="submitbtn cursoreffect">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "ExploreOpportunity",
  components: {
    EcgComp,
  },
  setup() {
    const showform = ref(false);
    const showdetailform = ref(false);

    const cards = reactive([
      {
        title: "Doctors",

        image: require("../assets/explore3.png"),
      },
      {
        title: "Nursing",
        image: require("../assets/explore1.png"),
      },
      {
        title: "Paramedics",
        image: require("../assets/explore4.png"),
      },
      {
        title: "Administrative Staff",
        image: require("../assets/explore2.png"),
      },
    ]);
    const showapplyform = () => {
      showform.value = true;
      showdetailform.value = false;
      document.body.style.overflow = "hidden";
    };
    const closeformhandler = () => {
      showform.value = false;
      document.body.style.overflow = "";
    };
    const showdetailhandler = () => {
      showdetailform.value = true;
      document.body.style.overflow = "hidden";
    };
    const closedetailhandler = () => {
      showdetailform.value = false;
      document.body.style.overflow = "";
    };
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });
    return {
      cards,
      showform,
      showapplyform,
      closeformhandler,
      showdetailform,
      showdetailhandler,
      closedetailhandler,
      cursor,
    };
  },
};
</script>
<style scoped lang="less">
.explore-div {
  .intro-block {
    text-align: center;
    margin-bottom: 20px;
    .monitor {
      padding: 0;
      max-width: 200px;

      margin: 0 auto;
    }

    svg {
      max-width: 470px;
      margin-left: 20px;
      margin-right: 20px;
      position: relative;
      transition: all 0.5s ease-in-out;
      .ekg {
        fill: none;
        stroke: #0063a8;
        stroke-width: 6;
        stroke-linecap: round;
        stroke-linejoin: miter;
        opacity: 0;
        stroke-dashoffset: 1000;
        stroke-dasharray: 1000;
        animation: ekg 1.5s linear forwards infinite;
      }
    }
  }
  @keyframes ekg {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      stroke-dashoffset: 2000;
    }
    99% {
      opacity: 0;
      stroke-dashoffset: 3000;
    }
    100% {
      stroke-dashoffset: 1000;
    }
  }
  h2 {
    margin-bottom: 12px;

    text-align: center;
  }

  .boxes {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 50px;
    @media (max-width: 1400px) {
      grid-template-columns: auto auto;
      grid-gap: 20px;
    }
    @media (max-width: 767px) {
      grid-template-columns: auto auto;
      grid-gap: 18px;
    }
    .opportunity-box {
      border-radius: 10px;
      background: #fff;
      box-shadow: 1px 1px 7px #d8d4d4;

      .img {
        img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .text {
        box-sizing: border-box;
        padding: 25px;
        word-break: break-word;
        @media (max-width: 1600px) {
          padding: 30px 10px;
        }
        @media (max-width: 767px) {
          padding: 10px;
        }

        h3 {
          text-align: center;
          margin-bottom: 20px;
          @media (max-width: 1024px) {
          }
          @media (max-width: 767px) {
            margin-bottom: 0px;
          }
        }
        p {
          color: #fff;
          font-size: 13px;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          display: block;
        }
        .button-outer {
          padding: 11px 14px;
          background-color: #0063a8;
          border: none;
          width: 48%;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
          @media (max-width: 1600px) {
            padding: 12px 4px;
          }

          @media (max-width: 767px) {
            display: block;
            text-align: center;
            margin: 10px 0px;
            padding: 7px 5px;
            width: 100%;
          }
          &:hover {
            background: #032e53;
          }
          a {
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            text-align: center;
            cursor: pointer;

            transition: all 0.5s ease-out;
            -webkit-appearance: none;
            appearance: none;
            width: 100%;

            @media (max-width: 1024px) {
              font-size: 14px;

              margin-top: 10px;
            }
            @media (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.form {
  border-radius: 7px;
  z-index: 99999;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .query-form {
    padding: 30px 30px;
    width: 600px;
    max-width: 100%;
    position: relative;
    @media (max-width: 767px) {
      width: 340px;
    }
    .close {
      display: flex;
      justify-content: end;
      width: 20px;
      cursor: pointer;
      position: absolute;
      right: 4%;
      /* background: grey; */
      img {
        width: 100%;
        height: 100%;
      }
    }
    .heading {
      font-size: 28px;
      letter-spacing: 0.5px;
      line-height: 1.2;
      color: #545454;
      font-weight: 600;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    .field {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      label {
        font-size: 18px;
        font-weight: 600;
        color: #545454;
        letter-spacing: 0.5px;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      .inputbox {
        border: 0;
        letter-spacing: 0.5px;
        outline: 0;
        border-bottom: 1px solid #5e5e5e;
        padding: 0;
        border-radius: 0;
        font-weight: 500;
        font-size: 12px;
        height: 38px;
        color: #58595b;
        background-color: transparent;
        width: 100%;
        border-radius: 0;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .submitbtn {
      display: inline-block;
      padding: 14px 18px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      border-radius: 35px;
      text-decoration: none;
      cursor: pointer;
      width: 100%;

      font-weight: 700;
      border: 0;
      outline: 0;
      background-color: #0063a8;
    }
  }
}

.job_content {
  cursor: pointer;
  z-index: 99999;
  background-color: #fff;
  position: fixed;
  top: 50%;
  border-radius: 7px;
  left: 50%;
  transform: translate(-50%, -50%);
  .job_form {
    position: relative;
    padding: 30px 30px;
    width: 700px;
    max-width: 100%;
    height: 600px;
    overflow-y: auto;
    @media (max-width: 767px) {
      width: 340px;
    }
    .close {
      display: flex;
      justify-content: end;
      width: 20px;
      cursor: pointer;
      position: absolute;
      right: 5%;
      top: 3%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-size: 16px;
      color: #58595b;
      line-height: 1.6;
    }
    ul {
      padding-left: 20px;
      li {
        font-size: 16px;
        line-height: 1.6;
        color: #58595b;
      }
    }
    .apply_modal_btn {
      padding: 12px 40px;
      background-color: #0063a8;
      font-size: 16px;
      color: #fff;
      font-weight: 600;

      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      border-radius: 35px;
      transition: all 0.5s ease-out;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;

      @media (max-width: 1024px) {
        font-size: 14px;

        margin-top: 10px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
      }
      &:hover {
        background: #032e53;
      }
    }
  }
}
//   backdrop css
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}

.backdrop.active {
  display: block;
}
</style>
