<template>
  <div class="update-container">
    <div class="container">
         <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Cardiac Care</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Cardiac Care</h2>
    </div>
  </div>
</div>
      <div class="heading_content">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
          voluptates provident aspernatur id laborum blanditiis.
        </p>
      </div>

      <div class="prevarrow">
        <img class="cursoreffect" src="../assets/previousbtn.png" />
      </div>
      <div class="carousel-frame" ref="container">
        <ul>
          <li class="carousel-item" v-for="slide in slides" :key="slide">
            <div class="card-slide">
              <img class="img" :src="slide.img" />
              <!-- <h3>{{ slide.heading }}</h3> -->
              <div class="content">
                <div>
                  <p>{{ slide.title }}</p>
                </div>

                <!-- <div class="common-button">
                  <p class="anchor-button cursoreffect">Know More ...</p>
                </div> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="nextarrow">
        <img class="cursoreffect" src="../assets/nextbtn.png" />
      </div>
      <div class="carousel_bottom_content">
        <p>
          <strong>Heart Team: </strong>Surgeons, Clinical and Interventional
          Cardiologists Work Together Seamlessy
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onBeforeUnmount } from "vue";
import EcgComp from "./EcgComp.vue";
export default {
  name: "UpdatesComp",
  components: {
    EcgComp,
  },
  setup() {
    const speed = ref(0);
    const scroll = ref(0);
    const container = ref(null);
    const scrollLeft = ref(null);
    const onMouseMove = (e) => {
      if (container.value) {
        const mouseX = e.pageX - container.value.getBoundingClientRect().left;
        const mousePerc = (100 * mouseX) / container.value.offsetWidth;
        speed.value = mousePerc - 50;
      }
    };

    const onMouseLeave = () => {
      speed.value = 0;
    };

    const updateScroll = () => {
      if (container.value && speed.value !== 0) {
        scroll.value += speed.value / 5;
        if (scroll.value < 0) scroll.value = 0;
        container.value.scrollLeft = scroll.value;
      }
      window.requestAnimationFrame(updateScroll);
    };

    onMounted(() => {
      if (container.value) {
        container.value.addEventListener("mousemove", onMouseMove);
        container.value.addEventListener("mouseleave", onMouseLeave);
        window.requestAnimationFrame(updateScroll);
      }
    });

    onBeforeUnmount(() => {
      if (container.value) {
        container.value.removeEventListener("mousemove", onMouseMove);
        container.value.removeEventListener("mouseleave", onMouseLeave);
      }
    });
    const slides = reactive([
      {
        img: require("../assets/speciality_slider1.png"),
        heading: "Facility Launched",
        title:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae necessitatibus ipsa alias cumque veritatis amet earum  odio maiores natus officiis.",
      },
      {
        img: require("../assets/speciality_slider2.png"),
        heading: "Elder Care Program",
        title:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae necessitatibus ipsa alias cumque veritatis amet earum odio maiores natus officiis.",
      },
      {
        img: require("../assets/speciality_slider3.png"),
        heading: "e-ICU",
        title:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae necessitatibus ipsa alias cumque veritatis amet earum odio maiores natus officiis.",
      },
      {
        img: require("../assets/speciality_slider4.png"),
        heading: "Triple Swap Liver Transplant",
        title:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae necessitatibus ipsa alias cumque veritatis amet earum odio maiores natus officiis.",
      },
      {
        img: require("../assets/speciality_slider1.png"),
        heading: "Lung Transplant",
        title:
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae necessitatibus ipsa alias cumque veritatis amet earum odio maiores natus officiis.",
      },
    ]);

    return {
      speed,
      scroll,
      container,
      slides,
      scrollLeft,
    };
  },
};
</script>

<style lang="less" scoped>
.update-container {
  background: #f4f4f4;
  position: relative;
     .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;
  padding-bottom: 30px;
      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  .heading_content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -20px;
    padding-bottom: 20px;
    p {
      width: 100%;
      max-width: 400px;
      text-align: center;
    }
  }
  .prevarrow {
    position: absolute;
    cursor: pointer;
    left: 90px;
    /* left: 50%; */
    top: 60%;
    @media (max-width: 1600px) {
      left: 36px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .nextarrow {
    cursor: pointer;
    position: absolute;
    right: 90px;
    /* left: 50%; */
    top: 60%;
    @media (max-width: 1600px) {
      right: 36px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .carousel-frame {
    width: 100%;
    margin-bottom: 0.5em;
    padding-bottom: 1em;
    position: relative;
    //   overflow-x: scroll;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .carousel-frame ul {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
  }
  .carousel-frame li.carousel-item {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
    width: 25%;
    @media (max-width: 1024px) {
      width: 33.33%;
      margin: auto
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .card-slide {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.14);
    padding: 0px;
    text-align: center;
    margin: 10px;

    @media (max-width: 576px) {
      margin: 0px;
    }
    .img {
      width: 100%;
      height: 390px;
      object-fit: cover;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    p {
      height: 115px;
      width: 100%;
      line-height: 1.6;
      white-space: pre-line;
      color: #58595b;
      padding: 8px 5px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @media screen and (max-width: 576px){
        height: 92px;
        padding: 8px 15px 15px;
      }
    }
    .content {
      .anchor-button {
        display: inline-flex;
        gap: 10px;
        padding-top: 0px;
        font-family: "FuturaMediumBT";
        align-items: center;
        cursor: pointer;
        color: #0063a8;
        &:hover {
          color: #0063a8;
        }
        span {
          line-height: 18px;
          width: 25px;
          height: 25px;

          img {
            background-color: #0063a8;
            border-radius: 50%;
            width: 100%;
          }
        }
      }
    }
  }
  .carousel_bottom_content {
    padding: 0px 15px;
    text-align: center;
    line-height: 1.6;
    color: #58595b;
    p {
      font-size: 18px;
    }
  }
}
</style>
