<template>
  <!-- cursor effect -->
  <div class="cursor" ref="cursor"></div>
  <div class="career-div">
    <div class="career-section">
      <div class="overlay">
        <div class="text-div">
          <h2>Join us in our mission to save lives</h2>
          <p>Want to work with us?</p>
          <button class="cursoreffect">Apply Now</button>
        </div>
      </div>
    </div>
    <!-- explore opportunities -->
    <ExploreOpportunity />
    <!-- benefit section start -->
    <div class="benefit-section container">
      <h2>Perks & Benefits</h2>
      <EcgComp />

      <div class="benefit-div">
        <div class="boxes" v-for="item in benefits" :key="item">
          <div class="box">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";

import ExploreOpportunity from "../components/ExploreOpportunity.vue";
import EcgComp from "@/components/EcgComp.vue";
export default {
  components: {
    ExploreOpportunity,
    EcgComp,
  },
  setup() {
    const benefits = reactive([
      {
        title: "RELAX, WE'VE GOT YOU COVERED",
        content:
          "We take care of our employees in more ways than one. Our competitive benefits package includes premium medical, dental, and vision coverage for employees and their dependents. We also offer up to 18 weeks of paid parental leave, daily gourmet lunches, strong cold brew, and seasonal wellness programming, including meditation, and nutritionist sessions.",
      },
      {
        title: "WORK-LIFE BALANCE",
        content:
          "We believe that quality time outside the office is vital to our employees satisfaction at work. Thats why our flexible PTO plan encourages team members to take time off so they can come back refreshed. Employees also get two free lifetime Squarespace sites to pursue their creative passions and side hustles.",
      },
      {
        title: "STAY INFORMED",
        content:
          "We are dedicated to ensuring all employees have the information they need to do their jobs well. Our monthly All Hands meetings, email newsletters, and CEO Office Hours are just a few of the programs we run to bring our three offices together and share key aspects of the business across the entire organization.",
      },
    ]);
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      benefits,
      cursor,
    };
  },
};
</script>
<style scoped lang="less">
.career-div {
  .career-section {
    background-image: url("../assets/noble_banner.webp");

    display: flex;
    object-fit: contain;
    height: 350px;
    object-fit: contain;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    position: relative;
    background-position: center;

    @media (max-width: 767px) {
      display: block;
      padding: 20px;
      margin-top: 50px;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }
    .text-div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;
      height: 100%;
      h2 {
        text-align: center;
        font-size: 40px;
        color: #fff;
        @media (max-width: 1024px) {
          font-size: 30px;
        }
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }

      p {
        color: #fff;
        font-size: 18px;
        margin-bottom: 16px;
        text-align: center;
      }
      button {
        color: #fff;
        background: #64c698;
        padding: 10px 40px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 300;
        border: none;
        display: inline-block;
      }
      img {
        width: 100%;
      }
    }
  }

  .benefit-section {
    // background: #f8f8f8;
    h2 {
      margin-bottom: 12px;

      text-align: center;
    }
    .benefit-div {
      display: grid;
      grid-template-columns: auto auto auto;
      grid-gap: 30px;
      padding: 30px;
      @media (max-width: 1024px) {
        grid-template-columns: auto auto;
      }

      @media (max-width: 767px) {
        grid-template-columns: auto;
        padding: 14px;
      }

      .box {
        height: 200px;
        border-radius: 12px;
        background: #f6fcff;
        text-align: center;
        border: solid 1px #e4e4eb;
        padding: 20px;
        @media (max-width: 1600px) {
          height: 282px;
        }
        @media (max-width: 1024px) {
          height: 340px;
        }
        @media (max-width: 767px) {
          height: auto;
        }
        h3 {
          /* margin: 10px 0px; */
          margin-bottom: 10px;
        }
      }
    }
  }
}
.intro-block {
  text-align: center;
  margin-bottom: 20px;
  .monitor {
    padding: 0;
    max-width: 200px;

    margin: 0 auto;
  }

  svg {
    max-width: 470px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    transition: all 0.5s ease-in-out;
    .ekg {
      fill: none;
      stroke: #0063a8;
      stroke-width: 6;
      stroke-linecap: round;
      stroke-linejoin: miter;
      opacity: 0;
      stroke-dashoffset: 1000;
      stroke-dasharray: 1000;
      animation: ekg 1.5s linear forwards infinite;
    }
  }
}
@keyframes ekg {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 2000;
  }
  99% {
    opacity: 0;
    stroke-dashoffset: 3000;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}
</style>
