<template>
  <div class="abt-container">
  <img src="../assets/lab.jpg" alt="Snow" style="width:100%;">
  <div class="text-container container">


 <div class="centered  ">



  <h2>ABOUT US</h2>
  <h3>HOME/<span>ABOUT US</span></h3>
 </div>
   </div>
   </div>

</template>
<script>
export default {
  name: "AboutBanner",
};
</script>
<style lang="less" scoped>
.abt-container {
  position: relative;
  text-align: center;
  color: white;
  @media(max-width:767px){
    margin-top: 50px;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .text-container{
padding: 0px;
  .centered {
  position: absolute;
  top: 50%;
transform: translate(0%, -50%);

h2,h3{
  color: #0063a8;
  text-align: left;
    @media(max-width:767px){
padding: 0;
margin: 0;
  }
  span{
    color: #000;
  }
}
  }

}
}








</style>
