<template>
  <div class="doctor-section"  id="one">
    <div class="container">
       <div class="ecg-container" data-aos="fade-right" data-aos-duration="1000">
  <EcgComp />
  <div class="desktop-heading">
    <h2>Healers, Innovators, Guardians of Life</h2>
  </div>
  <div class="mob-marquee">
    <div
      class="marquee-content"
      data-aos="fade-right"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >
      <h2>Healers, Innovators, Guardians of Life</h2>
    </div>
  </div>
</div>

      <div class="metacontent">
        <p title=" Montserrat, sans-serif">
          Our superspecialist doctors provide the highest quality of care
          through a team-based, doctor-led model. Trained at some of the world's
          most renowned institutions, our highly experienced doctors are
          distinguished experts in their respective specialities. Our doctors
          work full-time and exclusively across Noble hospitals. In addition to
          offering superspecialised care in their own field, the Noble
          organisational structure enables every doctor to help create a culture
          of collaboration and multispecialty care integration.
        </p>
      </div>
      <form>
        <div class="selector" data-aos="fade-up" data-aos-duration="2000">
          <!-- New Location Dropdown -->
          <div class="main-wrapper">
            <div class="wrapper1">
              <div class="location-Dropdown">
                <select v-model="selectedOption3" class="selected-option">
                  <option
                    class="option"
                    v-for="(option3, index) in Locationoptions"
                    :key="index"
                    :value="option3"
                  >
                    {{ option3 }}
                  </option>
                </select>
              </div>

              <div class="speciality-dropdown">
                <select v-model="selectedOption2" class="selected-option">
                  <option
                    class="option"
                    v-for="(option2, index) in Speciality"
                    :key="index"
                    :value="option2"
                  >
                    {{ option2 }}
                  </option>
                </select>
              </div>
            </div>
            <div class="wrapper2">
              <div class="doctor-Dropdown">
                <select v-model="selectedOption" class="selected-option">
                  <option
                    class="option"
                    v-for="(option, index) in Doctoroptions"
                    :key="index"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>

              <div @click="gotodetail" class="go-btn cursoreffect">Go</div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <DoctorsList />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import DoctorsList from "../components/DoctorsList.vue";
import { useRouter } from "vue-router";
import EcgComp from "./EcgComp.vue";

import { EventBus } from '../eventBus';
export default {
  components: {
    DoctorsList,
    EcgComp,
  },
  setup() {
 const scrollToSection = () => {
      const element = document.getElementById('one');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    onMounted(() => {
      EventBus.on('scrollToDoctorSection', scrollToSection);
    });

    onBeforeUnmount(() => {
      EventBus.off('scrollToDoctorSection', scrollToSection);
    });
    const router = useRouter();
    const Doctoroptions = [
      "Select Doctor",
      "Dr. Vishal Chaudhari",
      "Dr.Shailesh S Patil",
      "Dr.Ramprasad ",
      "Dr. Abhijit  Ranaware",
    ];
    const Speciality = [
      "Select Speciality",

      "Breast Care Center",
      "Cardiac Sciences",
      "Cognitive-Behavioural Psychotherapy",
      "Critical Care",
      " Dental & Maxillofacial Surgery",
      "Dermatology ",
      " Diabetes",
      " Emergency and Trauma",
      " Endocrinology",

      "ENT ",
      " Fertility Medicine",
      " Fetal Medicine",
      " Gastroenterology",
      "Hematology ",
      " Infectious Diseases",
      "Internal Medicine  ",
      "Interventional Radiology ",
      " Laboratory Medicine",
      "Laparoscopic and Minimally Invasive Surgery (MIS) ",
      "Mental Health ",
      " Neonatal ICU",
      " Nephrology",
      " Neuro surgery",
      " Neuro-interventional Radiology",
      " Neurology",
      " Nutrition & Dietetics",
      "Obesity ",
      "Obstetrics & Gynecology ",
      " Oncology",
      " Ophthalmology",
      "Orthopedics ",
      " Pain Management Clinic",
      "Pediatric Endocrinology ",
      "Pediatric Nephrology  ",
      " Pediatric Neurology",
      " Pediatrics",
      "Pediatrics  Surgery ",
      "Physiotherapy ",
      "Plastic & Reconstructive Surgery ",
      " Preventive Medicine",
      " Psychiatry",
      " Pulmonology",
      "Radiology ",
      "Rheumatology ",
      "Robotic Knee Joint Replacement  ",
      "Shoulder Arthroscopy & Sports Injuries ",
      " Surgical Gastroenterology, Liver & HPB Surgery",
      "Surgical Oncology ",
      " Urology",
      "Vascular Surgery ",
    ];
    const Locationoptions = [
      "Pune",
      "Magarpatta",
      "Amanora",
      "Sasanenagar",
      "Saswad",
    ];
    const selectedOption = ref(Doctoroptions[0]);
    const selectedOption2 = ref(Speciality[0]);
    const selectedOption3 = ref(Locationoptions[0]);

    const doctorDropdown = ref(null);
    const specialityDropdown = ref(null);
    const locationDropdown = ref(null);

    const gotodetail = () => {
      router.push("doctors-detail");
      window.scrollTo(0, 0);
    };

   

    return {
      Doctoroptions,
      gotodetail,
      selectedOption,

      selectedOption2,
      selectedOption3,

      Speciality,
      Locationoptions,
      doctorDropdown,
      specialityDropdown,
      locationDropdown,
    };
  },
};
</script>

<style lang="less" scoped>
.doctor-section {
  background: #fafafa;
  @media (max-width: 767px) {
    padding: 0px;
  }
  .container {
    @media (max-width: 480px) {
      padding-top: 60px;
    }
  .ecg-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .desktop-heading {
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .mob-marquee {
        display: none;
        @media (max-width: 767px) {
          display: block;
        
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
        }
      }
      .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
  }
   @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    }
  }
  .metacontent {
    width: 900px;
    max-width: 100%;
    margin: auto;
    @media (max-width: 1024px) {
      width: 700px;
    }

    p {
      line-height: 1.6;
font-family: Montserrat, sans-serif;
      // color: #58595b;
      text-align: center;
     
      margin-bottom: 20px;
    }
  }
  .selector {
    padding-top: 60px;
    z-index: 2; /* Set a higher z-index for the selector container */
    display: flex;
    justify-content: center;
    position: relative; /* Ensure position is set for z-index to take effect */
    @media (max-width: 1024px) {
      width: 100%;
      display: flex;
    }
    .main-wrapper {
      display: flex;
      @media (max-width: 1024px) {
        display: block;
      }
      .wrapper1 {
        display: flex;
        align-items: center;
      }
      .wrapper2 {
        display: flex;
        align-items: center;
      }
    }
    .go-btn {
      margin-left: 15px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      font-family: "FuturaBookBT";
      font-weight: 600;

      border: 0;

      outline: 0;
      background: #0063a8;

      cursor: pointer;
      &:hover {
        background: #032e53;
      }
      @media (max-width: 1024px) {
        margin-top: 10px;
      }
      @media (max-width: 480px) {
        margin-left: 2px !important;
        line-height: 22px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        font-family: "FuturaBookBT";
        font-weight: 500;
        border: 0;
        outline: 0;
        background: #0063a8;
        cursor: pointer;
        padding: 9px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.banner {
  img {
    width: 100%;
  }
}

.doctor-Dropdown {
  position: relative;
  margin: 0px 15px;

  @media (max-width: 767px) {
    margin: 3px 8px;
  }
  @media (max-width: 480px) {
    margin: 3px 4px;
  }
  .selected-option {
    background-color: #0063a8;
    height: 50px;
    line-height: 50px;
    width: 240px;
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0px 25px;

    color: #fff;
    border-radius: 50px;
    font-size: 16px;
    font-family: "FuturaBookBT";
    font-weight: 600;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 14px;
      width: 14px;
      transform: rotate(90deg);
    }

    @media (max-width: 767px) {
      width: 170px;
      padding: 0px 15px;
    }
    @media (max-width: 480px) {
      font-size: 12px;
      font-weight: 400;
      width: 170px;
      padding: 0px 10px !important;
    }
  }

  .options {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;

    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option {
    font-family: "FuturaBookBT";
    color: #000;
    background: #fff;
    padding: 10px;
    cursor: pointer;
    width: 240px;
    word-break: break-word;
    border-bottom: 1px solid #d1d1d1;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option:hover {
    background-color: #0063a8;
    color: #fff;
  }

  .selected-option i {
    margin-left: 10px;

    @media (max-width: 767px) {
      margin-left: 5px;
      padding: 3px 15px;
    }
    @media (max-width: 480px) {
      padding: 3px 10px;
    }
  }
    &::after {
    content: url(../assets/white-down.png);
    position: absolute;
    top: 17px;
    right: 19px;
    height: 12px;
    width: 12px;
    object-fit: contain;
    display: flex;
  }
}
.speciality-dropdown {
  position: relative;
  margin: 0px 15px;
  @media (max-width: 1024px) {
    margin: 10px 15px;
  }
  @media (max-width: 767px) {
    margin: 10px 10px;
  }
  @media (max-width: 480px) {
    .speciality-dropdown[data-v-f20b5e3a] {
      margin: 3px 0px;
    }
  }
  .selected-option {
    background-color: #fff;

    height: 50px;
    line-height: 50px;
    width: 240px;
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #000;
    padding: 0px 25px;

    color: #000;
    border-radius: 50px;
    font-size: 16px;
    font-family: "FuturaBookBT";
    font-weight: 600;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 14px;
      width: 14px;
      transform: rotate(180deg);
    }
    @media (max-width: 767px) {
      width: 170px;
      padding: 0px 5px;
    }
    @media (max-width: 480px) {
      font-size: 12px;
      font-weight: 400;
      width: 170px;
    }
  }

  .options {
    height: 20vh;
    overflow-y: scroll;
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;

    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option {
    word-break: break-word;
    padding: 10px;
    cursor: pointer;
    font-family: "FuturaBookBT";
    width: 240px;
    border-bottom: 1px solid #d1d1d1;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option:hover {
    background-color: #0063a8;
    color: #fff;
  }

  .selected-option i {
    margin-left: 20px;
  }
    &::after {
    content: url(../assets/black-down.png);
    position: absolute;
    top: 17px;
    right: 19px;
    height: 12px;
    width: 12px;
    object-fit: contain;
    display: flex;
  }
}

/* Add styles for the location dropdown */
.location-Dropdown {
  position: relative;
  margin: 0px 15px;

  @media (max-width: 767px) {
    margin: 3px 8px;
  }

  .selected-option {
    background-color: #0063a8;
    height: 50px;
    line-height: 50px;
    width: 240px;
    background-position: 100%;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    padding: 0px 25px;

    color: #fff;
    border-radius: 50px;
    font-size: 16px;
    font-family: "FuturaBookBT";
    font-weight: 600;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 14px;
      width: 14px;
      transform: rotate(90deg);
    }

    @media (max-width: 767px) {
      width: 170px;
      padding: 0px 15px;
    }
    @media (max-width: 480px) {
      font-size: 12px;
      font-weight: 400;
      width: 170px;
      padding: 0px 10px !important;
    }
  }

  .options {
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;

    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option {
    font-family: "FuturaBookBT";
    background: #ffff;
    color: #000;
    padding: 10px;
    cursor: pointer;
    width: 240px;
    word-break: break-word;
    border-bottom: 1px solid #d1d1d1;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .option:hover {
    background-color: #0063a8;
    color: #fff;
  }

  .selected-option i {
    margin-left: 10px;

    @media (max-width: 767px) {
      margin-left: 5px;
      padding: 3px 15px;
    }
    @media (max-width: 480px) {
      padding: 3px 10px;
    }
  }
    &::after {
    content: url(../assets/white-down.png);
    position: absolute;
    top: 17px;
    right: 19px;
    height: 12px;
    width: 12px;
    object-fit: contain;
    display: flex;
  }
}
select {
   -webkit-appearance: none;
  appearance: none;
  option {
    font-family: "FuturaBookBT";
  }
}
option.option {
  background: #fff !important;
}
:deep(option.option:hover){
  background: red !important;
}
</style>
