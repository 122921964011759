<template>
  <div class="international-section">
    <!-- cursor effect -->
    <div class="cursor" ref="cursor"></div>
    <!-- cursor effect end -->
    <internationalPatients />

    <!-- international patient section start -->
    <div class="container">
      <div class="about-section">
        <h2>International Patients</h2>
        <EcgComp />
        <div class="boxes">
          <div class="box" v-for="(card, index) in cards" :key="index">
            <img
              :src="card.img"
              data-aos="flip-left"
              data-aos-duration="3000"
            />
            <div class="counter-div">
              <span>{{ countingValues[index] }}</span
              ><span>+</span>
              <p>{{ card.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TREATMENT JOURNEY SECTION  -->
  <div class="treatjourney-section">
    <TreatmentJourney />
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import EcgComp from "../components/EcgComp.vue";
import TreatmentJourney from "../components/TreatmentJourney.vue";
import internationalPatients from "../components/InternationalPatients.vue";
export default {
  components: {
    TreatmentJourney,
    EcgComp,
    internationalPatients,
  },
  name: "InternationalPatientsPage",
  setup() {
    const cards = reactive([
      {
        img: require("../assets/inter1.png"),
        count: 14,
        text: "Centres Excellence",
      },
      {
        img: require("../assets/inter2.png"),
        count: 34,
        text: "Specialities",
      },
      {
        img: require("../assets/inter3.png"),
        count: 125,
        text: "ICU Beds",
      },
      {
        img: require("../assets/inter4.png"),
        count: 500,
        text: "Beds",
      },
      {
        img: require("../assets/inter5.png"),
        count: 10,
        text: "Modular OTs",
      },
      {
        img: require("../assets/inter6.png"),
        count: 24,
        text: " Pharmacy",
      },
      {
        img: require("../assets/inter7.png"),
        count: 24,
        text: " Blood Bank",
      },
      {
        img: require("../assets/inter8.png"),
        count: 24,
        text: "Emergency ",
      },
    ]);
    const countingValues = ref(cards.map(() => 0));

    const updateCounts = () => {
      const intervals = 300; // Adjust this value to control the speed of counting
      const duration = 4000; // Total duration for the counting animation

      cards.forEach((card, index) => {
        const increment = Math.ceil(card.count / (duration / intervals));
        const interval = setInterval(() => {
          countingValues.value[index] += increment;
          if (countingValues.value[index] >= card.count) {
            countingValues.value[index] = card.count;
            clearInterval(interval);
          }
        }, intervals);
      });
    };

    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      updateCounts();
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      cursor,
      cards,
      countingValues,
    };
  },
};
</script>
<style scoped lang="less">
.international-section {
  :deep(.about-section ){
    display: none !important;
  }
  .container {
    .about-section {
      h2 {
        text-align: center;
      }
      .intro-block {
        text-align: center;
        margin-bottom: 20px;
        .monitor {
          padding: 0;
          max-width: 200px;

          margin: 0 auto;
        }

        svg {
          max-width: 470px;
          margin-left: 20px;
          margin-right: 20px;
          position: relative;
          transition: all 0.5s ease-in-out;
          .ekg {
            fill: none;
            stroke: #0063a8;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: miter;
            opacity: 0;
            stroke-dashoffset: 1000;
            stroke-dasharray: 1000;
            animation: ekg 1.5s linear forwards infinite;
          }
        }
      }
      @keyframes ekg {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 1;
        }
        50% {
          stroke-dashoffset: 2000;
        }
        99% {
          opacity: 0;
          stroke-dashoffset: 3000;
        }
        100% {
          stroke-dashoffset: 1000;
        }
      }
      .boxes {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 30px;
        margin: 40px auto;
        @media (max-width: 767px) {
          grid-template-columns: auto auto;
          grid-gap: 30px;
        }
        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .counter-div {
            text-align: center;
            margin: 10px 0px;

            @media (max-width: 767px) {
              width: 100px;
            }
            span {
              font-size: 30px;
              color: #0e2d58;
              font-family: "FuturaMediumBT";
              @media (max-width: 767px) {
                font-size: 20px;
                color: #0e2d58;
              }
            }
            p {
              font-size: 16px;
              color: #0e2d58;
              // border-top: 2px solid #3f6599;
              padding: 10px;
              word-break: break-word;
              @media (max-width: 767px) {
                font-size: 14px;
                color: #0e2d58;
              }
            }
          }
        }
      }
    }
  }
  .specialitycard-slider {
    background: linear-gradient(180deg, #ebf7ff, transparent);

    :deep(.cards){
      display: none;
    }
  }
}
</style>
