<template>
  <!-- cursor effect -->
  <div class="cursor" ref="cursor"></div>
  <!-- about section start -->
  <div class="about-wrapper">
    <!-- first section start-->
    <div class="background-abtwrapper">
      <div class="abt-wrapper container">
        <div class="abt-img" data-aos="fade-right" data-aos-duration="1000">
          <img class="desk-img" src="../assets/nabhbanner.jpg" alt="img" />
        </div>
        <div class="abt-content" data-aos="fade-left" data-aos-duration="1000">
          <div class="text">
            <h3>Lorem</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti
              placeat recusandae, odio pariatur nam perspiciatis delectus earum
              reiciendis eveniet facilis dolores, quam iusto, necessitatibus
              repellendus id accusantium in amet ex similique aut fuga molestiae
              corporis. Vel natus aliquid dignissimos? Vitae, voluptates ab!
              Beatae, aliquid suscipit! Explicabo ab labore laboriosam at!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- second section start-->
  <div class="overview-wrapper container">
    <h2>About NABH</h2>
    <p>
      Noble Hospitals & Research Centre is a 250-bedded, tertiary care hospital
      in Hadapsar, Pune — including a chain of polyclinics — that was started
      with an aim to provide high-quality integrated healthcare to patients at
      an affordable cost. Currently, the hospital has over 66 ICU beds, 130+
      doctors, 30+ clinical departments, 8 certified cGreen operation theatre
      complexes, 24x7 Emergency care (backed by the latest radio-diagnostic
      technology), and 32 outpatient consultation rooms. In the next three
      years, we will be rapidly expanding the bed capacity to 1,000 patient beds
      with 3 new greenfield hospitals.
    </p>
    <p>
      Today — 16 years since inception — our key clinical drivers include the
      entire gamut of services in Cardiac Sciences, Neuro Sciences, Robotic
      Joint Replacement Surgery, Orthopedics, Gastro Sciences and HPB Surgery,
      Minimal Access Surgery, Renal Sciences, Oncology, Obstetrics and
      Gynecology, Pediatrics and Neonatology, Emergency and Trauma Care, and
      Interventional Radiology.
    </p>

    <p>
      Governed by our motto "Life is precious, we care for it", we at Noble
      Hospitals & Research Centre intend to revolutionise the patient experience
      by pairing exceptional medical services with intelligence and compassion.
      Our combined expertise of a team of surgeons, physicians, nurses,
      technicians, paramedics, and administrative and maintenance staff — led by
      our visionary Managing Director and Clinician Dr Dileep Mane — ensures
      highly personalised care that our patients deserve.
    </p>
  </div>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
export default {
  name: "NABHpage",
  setup() {
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      cursor,
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.about-wrapper {
  .background-abtwrapper {
    .abt-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      align-items: center;
      padding-bottom: 0px;
      @media (max-width: 1024px) {
        display: block;
      }

      @media (max-width: 767px) {
        display: block;
        margin-top: 35px;
      }

      .abt-img {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
              border-radius: 20px;
        }
      }

      .abt-content {
        @media (max-width: 1024px) {
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.overview-wrapper {
  h2 {
    text-align: left;
  }

  p {
    line-height: 1.5;
    margin-bottom: 15px;

    letter-spacing: 0.5px;
  }
}
</style>

