<template>
 <div class="intro-block">
        <div class="monitor">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 200"
            style="enable-background: new 0 0 500 200"
            xml:space="preserve"
          >
            <g>
              <polyline
                class="ekg"
                points="486.6,113.8 328.2,113.8 310.3,132.3 296,70.7 246.8,127.4 241.6,120.2 233.9,166.4 227,27.6 
                213.2,118.3 211.8,112.3 205.1,126.1 198.2,108.5 194.1,124.4 184.5,92.9 174.1,113 4.3,113  "
              />
            </g>
          </svg>
        </div>
      </div>
</template>

<script>
export default {
  name: "EcgComp",
};
</script>

<style lang="less" scoped>
 .intro-block {
      text-align: center;
      margin-bottom: 20px;
      .monitor {
        padding: 0;
        width: 200px !important;
        margin: 0 auto;
        @media (max-width: 767px) {
          width: 150px !important;
        }
      }

      svg {
        max-width: 470px;
        margin-left: 0px;
        margin-right: 0px;
      top: 14px;
          position: relative;
          transition: all 0.5s ease-in-out;
       @media (max-width: 1600px) {
            top: 11px !important;
          }
        .ekg {
          fill: none;
          stroke: #0063a8;
          stroke-width: 6;
          stroke-linecap: round;
          stroke-linejoin: miter;
          opacity: 0;
          stroke-dashoffset: 1000;
          stroke-dasharray: 1000;
          animation: ekg 1.5s linear forwards infinite;
        }
      }
    }
    @keyframes ekg {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      50% {
        stroke-dashoffset: 2000;
      }
      99% {
        opacity: 0;
        stroke-dashoffset: 3000;
      }
      100% {
        stroke-dashoffset: 1000;
      }
    }
</style>
