<template>
  <div class="cursor" ref="cursor"></div>
  <div class="header" :class="{ hidden: isSticky }">
    <!-- main top header -->
    <div class="header-section container">
      <div class="icon-container">
        <div class="navbar-section">
          <div class="humberger-icon" @click="menuehandler">
            <img class="cursoreffect" src="../assets/menu.png" />
          </div>
          <div class="feedback-icon">
            <img
              class="cursoreffect"
              @click="feedbackhandler"
              src="../assets/feedback.png"
            />
          </div>
          <div class="search-icon" @click="searchhandler">
            <img class="cursoreffect" src="../assets/serch.png" />
          </div>
        </div>
        <div class="text">Life is precious, we care for it</div>
      </div>

      <div class="logo">
        <img
          class="cursoreffect"
          @click="gotohomepage"
          src="../assets/nob-logo.png"
        />
      </div>
      <div class="badges">
        <!-- <img src="../assets/badges.png" class="cursoreffect" /> -->

        <img
          class="cursoreffect jci"
          src="../assets/jci.png"
          @click="gotoJCIpage"
        />
        <img
          class="cursoreffect"
          src="../assets/nabh.png"
          @click="gotoNABHpage"
        />
        <img
          class="cursoreffect nabl"
          src="../assets/nabl.png"
          @click="gotoNABLpage"
        />
      </div>
    </div>
  </div>
  <!-- dropdown section start -->
  <div class="dropdown-wrapper">
    <!-- backdrop overlay section  -->
    <div
      class="backdrop"
      :class="{ active: showSearch || showmenu }"
      @click="closeDropdowns"
    ></div>
    <!-- searchbar section  -->
    <div class="searchbar">
      <div class="search-header" data-aos="fade-down" v-show="showSearch">
        <span class="close-icon" @click="closeSearchhandler">
          <img class="cursoreffect" src="../assets/close.png" />
        </span>
        <div class="search-wrapper">
          <div class="input-container">
            <div class="logo">
              <img
                class="cursoreffect"
                @click="gotohome"
                src="../assets/nob-logo.png"
              />
            </div>
            <div class="input-search">
              <div class="search-icon">
                <img src="../assets/serch.png" />
              </div>
              <input type="text" v-model="search" placeholder="Search" />
            </div>
            <div class="badges">
              <router-link to="/JCI">
                <img class="cursoreffect jci" src="../assets/jci.png"
              /></router-link>
              <router-link to="/NABH">
                <img class="cursoreffect" src="../assets/nabh.png"
              /></router-link>
              <router-link to="/NABL">
                <img class="cursoreffect nabl" src="../assets/nabl.png"
              /></router-link>
            </div>
          </div>
        </div>
        <div class="bottom-serach">
          <div class="search-items">
            <div class="list-items">
              <div class="departments">
                <h3>Departments</h3>
                <div class="ul-flex">
                  <ul>
                    <li v-for="item in departmenLeft" :key="item">
                      <span>{{ item.name }}</span>
                    </li>
                  </ul>
                  <ul>
                    <li v-for="item in departmenRight" :key="item">
                      <span>{{ item.name }}</span>
                    </li>
                    <li><span class="view-all">View all</span></li>
                  </ul>
                </div>
              </div>
              <div class="doctors-list">
                <h3>Doctors</h3>
                <div class="ul-flex">
                  <ul>
                    <li v-for="items in doctorlistLeft" :key="items">
                      <img :src="items.img" /><span>{{ items.name }}</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <img src="../assets/dc7.jpg" /><span
                        >Dr.Reema Y Kashiva</span
                      >
                    </li>
                    <li>
                      <img src="../assets/dc6.jpg" /><span
                        >Dr. Sudhir Halikar</span
                      >
                    </li>
                    <li>
                      <img src="../assets/dc7.jpg" /><span
                        >Dr. Sheetal Uday Harpale</span
                      >
                    </li>
                    <li>
                      <img src="../assets/dc8.jpg" /><span>Dr.S. K Raut</span>
                    </li>
                    <li><span class="view-all">View all</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- mobile searchbar -->
    <div class="searchbar mobilesearch">
      <div class="search-header">
        <div class="search-wrapper">
          <div class="input-container">
            <div class="input-search">
              <input type="text" v-model="search" placeholder="Search" />
            </div>
            <div class="search-icon">
              <img src="../assets/serch.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- menus sidebar section start -->

    <div class="menu-sidebar" v-show="showmenu">
      <div class="header-menus" data-aos="fade-right" data-aos-duration="500">
        <div class="header-wrappers">
          <span class="close-icon" @click="closemenuhandler">
            <img src="../assets/close.png" />
            <span class="close-text cursoreffect">Close</span>
          </span>
          <div class="menu">
            <div class="leftside">
              <ul>
                <li
                  class="cursoreffect"
                  v-for="items in leftmenu"
                  :key="items"
                  @click="toggleSubmenu(items)"
                >
                  <p>{{ items.name }}</p>
                  <p>
                    <img
                      v-if="items.submenus"
                      src="../assets/greater-than-symbol.png"
                    />
                  </p>
                </li>
              </ul>
              <div class="bottom-section">
                <div class="logo">
                  <img
                    class="cursoreffect"
                    @click="gotohome"
                    src="../assets/nob-logo.png"
                  />
                </div>
                <p class="text">Life is precious, We care for it</p>
                <div class="badges">
                  <img
                    class="cursoreffect jci"
                    src="../assets/jci.png"
                    @click="gotoJCIpage"
                  />

                  <img
                    class="cursoreffect"
                    src="../assets/nabh.png"
                    @click="gotoNABHpage"
                  />
                  <img
                    class="cursoreffect nabl"
                    src="../assets/nabl.png"
                    @click="gotoNABLpage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- submenu sidebar -->
      <div class="submenu-sidebar">
        <div
          class="header-menus-sidebar"
          v-show="showsubmenu && hasSubmenus"
          data-aos="fade-right"
          data-aos-duration="500"
        >
          <div class="header-wrappers">
            <div class="menu">
              <div class="leftside">
                <div v-for="items in leftmenu" :key="items.name">
                  <div
                    v-show="
                      items.submenus &&
                      items.submenus.length > 0 &&
                      items.showSubmenu
                    "
                  >
                    <ul class="submenu-item">
                      <div @click="backtomenus" class="back-arrow">
                        <img
                          class="cursoreffect"
                          src="../assets/arrow-left.png"
                        />
                      </div>
                      <li
                        class="cursoreffect"
                        v-for="data in items.submenus"
                        :key="data.name"
                      >
                        <p @click="toggleSubmenulist(data)">{{ data.name }}</p>
                      </li>

                      <div
                        class="inter-counting"
                        v-if="items.name == 'International Patients'"
                      >
                        <div class="stats-container">
                          <div class="stats">
                            <div class="stats-inner">
                              <div class="count" ref="count1">2000+</div>
                              <div class="stats-title">
                                Average International Patients Per Year
                              </div>
                            </div>
                          </div>
                          <div class="stats stats-2">
                            <div class="stats-inner">
                              <div class="count" ref="count2">130+</div>
                              <div class="stats-title">Countries</div>
                            </div>
                          </div>
                          <div class="stats stats-2">
                            <div class="stats-inner">
                              <div class="count" ref="count3">06+</div>
                              <div class="stats-title">Hospitals in India</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <p v-if="items.banner" class="banner-img">
                        <img :src="items.banner" />
                      </p> -->
                      <a href="../assets/dummy-pdf.pdf" download>
                        <button class="boch-btn cursoreffect">
                          Download Bochure
                        </button></a
                      >
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- scroll subheader -->

  <div class="scroll-subheader" :class="{ sticky: isSticky }">
    <div class="scrollheader container" v-show="isSticky">
      <div class="logo-wrapper" :class="{ logofromright: isScrolling }">
        <div class="logo">
          <img
            class="cursoreffect"
            @click="gotohome"
            src="../assets/nob-logo.png"
          />
        </div>
        <div class="text">Life is precious, we care for it</div>
      </div>
      <div class="menus" :class="{ menusfromright: isScrolling }">
        <ul>
          <li v-for="items in scrollmenu" :key="items" class="cursoreffect">
            <div @click="togglescrollSubmenu(items)">
              <p class="cursoreffect">{{ items.name }}</p>
            </div>

            <div class="img-div" v-if="items.submenus">
              <img src="../assets/greater-than-symbol.png" />
            </div>
            <ul class="scrollsubmenu-item" v-if="items.submenus">
              <li
                v-for="data in items.submenus"
                :key="data.name"
                class="cursoreffect"
              >
                <p @click="gotoresearchdeatilpage(data)">{{ data.name }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="right-side">
        <div class="search-icon" @click="searchhandler">
          <img class="cursoreffect" src="../assets/serch.png" />
        </div>
        <div class="feedback-icon">
          <img
            class="cursoreffect"
            @click="feedbackhandler"
            src="../assets/feedback.png"
          />
        </div>
        <div class="badges">
          <img
            class="cursoreffect jci"
            src="../assets/jci.png"
            @click="gotoJCIpage"
          />

          <img
            class="cursoreffect"
            src="../assets/nabh.png"
            @click="gotoNABHpage"
          />
          <img
            class="cursoreffect nabl"
            src="../assets/nabl.png"
            @click="gotoNABLpage"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- sticky forward btn -->

  <div class="forward-btns">
    <div class="vibrant-btn cursoreffect">
      <a class="cc-calto-action-ripple">
        <img src="../assets/forword_btn.png"
      /></a>
      <div class="content">
        <div class="details">
          <p class="emergency-text cursoreffect">
            <img src="../assets/patient-portal.png" class="cursoreffect" />
            <a href="https://noblehrc.com/patient-portal" target="_blank"
              >Access to Patient Portal</a
            >
          </p>

          <p class="emergency-text cursoreffect">
            <img src="../assets/member-card.png" class="cursoreffect" />
            <a href="https://noblehrc.com/gold/card.php" target="_blank"
              >Noble Privilege Program</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="call-btns">
    <div class="vibrant-btn cursoreffect">
      <div class="call-animation">
        <a class="cc-calto-action-ripple"> <img src="../assets/call.gif" /></a>
      </div>
      <div class="content">
        <div class="details">
          <p class="cursoreffect">Book an Appoinment</p>

          <p class="cursoreffect">
            <img src="../assets/blue-phonecall.png" class="cursoreffect" /><a
              href="tel:+91-8007006611"
              target="_blank"
              >+91-8007006611</a
            >
          </p>
          <p class="cursoreffect">Emergency Number</p>

          <p class="cursoreffect">
            <img src="../assets/red-phonecall.png" class="cursoreffect" /><a
              href="tel:105900"
              target="_blank"
              >105900</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "HeaderComp",
  setup() {
    const router = useRouter();
    const showSearch = ref(false);
    const showsubmenu = ref(false);
    const showmenu = ref(false);
    const isSticky = ref(false); // Add a reactive variable for sticky header
    const search = ref(" ");
    const isScrolling = ref(false);
    const lastScrollY = ref(0); // Track the last scroll position
    const scrollmenu = reactive([
      {
        name: "Home",
        path: "/",
      },
      {
        name: "About",
        path: "/about-us",
      },

      {
        name: "Specialities",
        path: "/specialities",

        submenus: [
          { name: "Critical Care", path: "/speciality-detail" },
          { name: "Anesthesiology" },
          { name: "Breast Care Center" },
          { name: "Cardiac Sciences" },

          { name: "Dental & Maxillofacial Surgery" },
        ],
      },

      {
        name: "Research Center",
        submenus: [
          { name: "Clinical Trials", path: "/researchdetail" },
          { name: "Investigator Initiated Research" },
          { name: "Public Health Research" },
          { name: "Research Grants" },
          { name: "Academic Data Research" },
        ],
      },
      {
        name: "Contact",
        path: "/contact-us",
      },
      {
        name: "Careers",
        path: "/careerpage",
      },
    ]);

    const leftmenu = reactive([
      {
        name: "Home",
        path: "/",
      },
      {
        name: "About",
        path: "/about-us",
      },

      {
        name: "Specialities",

        submenus: [
          { name: "Critical Care", path: "/speciality-detail" },
          { name: "Anesthesiology" },
          { name: "Breast Care Center" },
          { name: "Cardiac Sciences" },
          { name: "Dental & Maxillofacial Surgery" },
        ],
      },
      {
        name: "International Patients",

        submenus: [
          { name: "International Patients", path: "/international-patients" },
        ],

        banner: require("../assets/numbers.png"),
      },

      {
        name: "Research Center",
        submenus: [
          { name: "Clinical Trials", path: "/researchdetail" },
          { name: "Investigator Initiated Research" },
          { name: "Public Health Research" },
          { name: "Research Grants" },
          { name: "Academic Data Research" },
        ],
      },
      {
        name: "Patient Portal",
        link: "https://noblehrc.com/patient-portal",
      },

      {
        name: "Blog",
      },
      {
        name: "Contact",
        path: "/contact-us",
      },
      {
        name: "Careers",
        path: "/careerpage",
      },
    ]);

    const doctorlistLeft = reactive([
      { img: require("../assets/dc1.jpg"), name: "Dr. Vishal Chaudhary" },
      { img: require("../assets/dc2.jpg"), name: "Dr. Pallavi Butiyan" },
      { img: require("../assets/dc3.jpg"), name: "Dr. Rahul Dadasaheb Sawant" },
      { img: require("../assets/dc4.jpg"), name: "Dr. Z A Khan" },
    ]);
    const doctorlistRight = reactive([
      { img: require("../assets/dc7.jpg"), name: "Dr. Reema Y Kashiva" },
      { img: require("../assets/dc6.jpg"), name: "Dr. Sudhir Halikar" },
      { img: require("../assets/dc7.jpg"), name: "Dr. Sheetal Uday Harpale" },
      { img: require("../assets/dc8.jpg"), name: "Dr. S. K Raut" },
    ]);
    const departmenLeft = reactive([
      {
        name: "Anesthesiology",
      },
      {
        name: "Breast Care Center",
      },
      {
        name: "Cardiac Sciences",
      },
      {
        name: "Cognitive-Behavioural Psychotherapy",
      },
      {
        name: "Critical Care",
      },
      {
        name: "Dental & Maxillofacial Surgery",
      },
      {
        name: "Dermatology ",
      },
      {
        name: " Diabetes",
      },
      {
        name: "Emergency and Trauma ",
      },
      {
        name: "Endocrinology",
      },
    ]);

    const departmenRight = reactive([
      {
        name: "ENT",
      },
      {
        name: "Fertility Medicine ",
      },
      {
        name: "Fetal Medicine",
      },
      {
        name: "Gastroenterology",
      },
      {
        name: "Hematology",
      },
      {
        name: "Internal Medicine",
      },
      {
        name: "Interventional Radiology ",
      },
      {
        name: "Laparoscopic and Minimally Invasive Surgery (MIS)",
      },
      {
        name: "Emergency and Trauma ",
      },
      {
        name: "View all",
      },
    ]);

    // menu sidebar
    const toggleSubmenu = (items) => {
      // Show the submenu of the clicked item
      items.showSubmenu = true;

      // If the clicked item has a path, navigate to it
      if (items.path) {
        router.push(items.path);
        window.scrollTo(0, 0); // Scroll to top of the page
        showmenu.value = false; // Close the main menu
        document.body.style.overflow = ""; // Enable scrolling on the body
      }

      // Iterate over all menu items to hide their submenus except the clicked one
      leftmenu.forEach((menuitem) => {
        if (menuitem !== items) {
          menuitem.showSubmenu = false;
        }
      });

      // If the clicked item has a link, open it in a new tab
      if (items.link) {
        window.open(items.link, "_blank");
      }

      // Update the showsubmenu value based on any submenu being shown
      showsubmenu.value = leftmenu.some((menuitem) => menuitem.showSubmenu);
    };

    const toggleSubmenulist = (data) => {
      if (data.path) {
        router.push(data.path);
        window.scrollTo(0, 0);
        showmenu.value = false;
        document.body.style.overflow = "";
      }

      leftmenu.forEach((menuitem) => {
        if (menuitem !== data) {
          menuitem.showSubmenu = false;
        }
      });
      showsubmenu.value = leftmenu.some((menuitem) => menuitem.showSubmenu);
    };
    // header when scroll
    const togglescrollSubmenu = (items) => {
      items.showSubmenu = true;
      if (items.path) {
        router.push(items.path);
        window.scrollTo(0, 0);
        showmenu.value = false;
        document.body.style.overflow = "";
      }

      leftmenu.forEach((menuitem) => {
        if (menuitem !== items) {
          menuitem.showSubmenu = false;
        }
      });

      showsubmenu.value = leftmenu.some((menuitem) => menuitem.showSubmenu);
    };
    const gotohome = () => {
      router.push("/");
      window.scrollTo(0, 0);
    };
    const gotohomepage = () => {
      router.push("/");
      window.scrollTo(0, 0);
    };
    const feedbackhandler = () => {
      router.push("/contact-us");
      window.scrollTo(0, 0);
    };
    const backtomenus = () => {
      leftmenu.forEach((menuitem) => {
        menuitem.showSubmenu = false;
      });
      showsubmenu.value = false;
      showmenu.value = true;
    };

    const closeDropdowns = () => {
      showmenu.value = false;
      showsubmenu.value = false;
      showSearch.value = false;
      document.body.style.overflow = "";
    };

    const searchhandler = () => {
      showSearch.value = true;
      showmenu.value = false;
      document.body.style.overflow = "hidden";
    };

    const closeSearchhandler = () => {
      showSearch.value = false;
      document.body.style.overflow = "";
    };

    const menuehandler = () => {
      showmenu.value = true;
      showSearch.value = false;
      document.body.style.overflow = "hidden";
    };

    const closemenuhandler = () => {
      showsubmenu.value = false;
      showmenu.value = false;
      document.body.style.overflow = "";
    };
    const gotoJCIpage = () => {
      router.push("/JCI");
      window.scrollTo(0, 0);
    };
    const gotoNABHpage = () => {
      router.push("/NABH");
      window.scrollTo(0, 0);
    };
    const gotoNABLpage = () => {
      router.push("/NABL");
      window.scrollTo(0, 0);
    };
    const gotoresearchdeatilpage = (data) => {
      data.showSubmenu = true;
      if (data.path) {
        router.push(data.path);
        window.scrollTo(0, 0);
        showmenu.value = false;
        document.body.style.overflow = "";
      }
    };
    const hasSubmenus = computed(() => {
      return leftmenu.some((item) => item.submenus && item.submenus.length > 0);
    });

    // Function to handle scroll event
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      isSticky.value = currentScrollY > 150;
      //  if the scroll position is below 150 and update isScrolling accordingly
      if (currentScrollY < 250) {
        isScrolling.value = currentScrollY < lastScrollY.value; // Check if scrolling up
      } else {
        isScrolling.value = false; // Reset isScrolling when the scroll position is 150 or more
      }
      lastScrollY.value = currentScrollY; // Update last scroll position
    };
    // Event listeners for scroll
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      gotoNABLpage,
      search,
      gotoNABHpage,
      isScrolling,
      departmenRight,
      scrollmenu,
      departmenLeft,
      doctorlistLeft,
      doctorlistRight,
      gotohomepage,
      showSearch,
      gotohome,
      gotoresearchdeatilpage,
      closeDropdowns,
      showmenu,
      togglescrollSubmenu,
      feedbackhandler,
      backtomenus,
      searchhandler,
      toggleSubmenu,
      closeSearchhandler,
      menuehandler,
      closemenuhandler,
      leftmenu,
      showsubmenu,
      hasSubmenus,
      isSticky,
      gotoJCIpage,
      toggleSubmenulist,
    };
  },
};
</script>

<style scoped lang="less">
.header {
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  position: sticky;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 999;
  li {
    list-style: none;
  }
}
.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  .icon-container {
    animation: fadeInFromLeft ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .navbar-section {
      display: flex;
      align-items: center;

      .humberger-icon {
        cursor: pointer;
        width: 16px;
        img {
          width: 100%;
        }
      }
      .feedback-icon {
        padding: 0px 22px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        width: 70px;
        @media (max-width: 767px) {
          padding: 0px;
          margin-left: 10px;
          width: 20px;
        }
        img {
          width: 100%;
        }
        span.feed-text {
          padding-left: 2px;

          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      .search-icon {
        cursor: pointer;
        @media (max-width: 767px) {
          display: none;
        }
        img {
          height: 23px;
          object-fit: contain;
        }
      }
    }
    .text {
      font-family: cursive;
      padding-top: 10px;
      font-size: 14px;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .logo {
    width: 150px;
    animation: fadeInFromLeft ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0px 10px;
      width: 120px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .badges {
    width: 150px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    @media (max-width: 767px) {
      width: 100px;
    }
    img {
      width: 52px;
      object-fit: contain;
      height: 52px;
      @media (max-width: 767px) {
        width: 35px;

        object-fit: contain;
        height: 35px;
      }
    }
  }
}
.search-header {
  border: 2px solid #f7f7f7;
  position: fixed;
  padding: 1rem 10px;
  width: 100%;
  margin-left: auto;
  z-index: 99999;
  right: 0;
  left: 0;
  top: 0;
  margin-right: auto;

  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    padding: 1rem 10px;
  }
  h2 {
    color: #0063a8;

    letter-spacing: 2px;
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .logo {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    li {
      list-style: none;
    }
  }
  .badges {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 52px;
      object-fit: contain;
      height: 52px;
      @media (max-width: 767px) {
        width: 35px;

        object-fit: contain;
        height: 35px;
      }
    }
    li {
      list-style: none;
    }
  }
  .input-container {
    display: flex;
    justify-content: space-between;
    height: 100%;

    align-items: center;
    border-radius: 10px;

    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      height: 30px;
      border-radius: 50px;
      height: 100%;
      padding: 0px 20px;
      border: 1px solid black;
    }
    .input-search {
      display: flex;
      width: 60%;
      margin: auto 20px;
      padding: 12px;
      align-items: center;
      border-radius: 50px;
      height: 100%;

      border: 1px solid black;

      @media (max-width: 767px) {
        width: 100%;
        margin: auto;
        border: none;
      }
      .search-icon {
        img {
          width: 100%;
          cursor: pointer;
          padding-right: 12px;
          height: 23px;
          object-fit: contain;
        }
      }
    }
    input {
      border: none;
      width: 100%;
    }
    input:focus {
      outline: none;
    }
  }

  .searches {
    display: flex;
    .pop-div {
      border: 1px solid grey;
      margin-right: 10px;
      padding: 20px;
      border-radius: 10px;
      h3 {
        line-height: 0;

        color: #000;

        letter-spacing: 1.2px;
      }
    }
  }
  span.close-icon {
    position: absolute;
    right: 20px;

    top: 20px;
    padding: 10px;
    cursor: pointer;
    // top: 5%;

    @media (max-width: 767px) {
      right: 8px;
    }
    img {
      width: 20px;
      height: 20px;
    }
    .close-text {
      color: #545454;

      padding-left: 5px;
    }
  }

  .search-wrapper {
    width: 80%;
    height: 100%;
    margin: auto;
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .bottom-serach {
    width: 100%;
    margin: auto;
    border-top: 1px solid #ececec;
    padding: 20px 0px;
    margin: 20px 0px;
    .search-items {
      width: 80%;
      margin: auto;

      width: 80%;
      margin: auto;
      .list-items {
        display: flex;
        justify-content: space-between;
        align-items: start;

        .departments {
          width: 50%;
          padding-right: 20px;
          h3 {
            padding-bottom: 20px;
          }
          .ul-flex {
            display: flex;
            justify-content: space-between;
            @media (max-width: 1024px) {
              display: block;
            }
            ul {
              list-style: none;
              li {
                line-height: 1.6;
                span {
                  cursor: pointer;
                }
              }
              .view-all {
                color: #0063a8;
                cursor: pointer;
              }
            }
          }
        }
        .doctors-list {
          width: 50%;
          border-left: 2px solid #ececec;
          padding-left: 20px;
          h3 {
            padding-bottom: 20px;
          }
          .ul-flex {
            display: flex;
            justify-content: space-between;
            @media (max-width: 1024px) {
              display: block;
            }

            ul {
              list-style: none;
              width: 50%;
              li {
                line-height: 1.6;
                padding-bottom: 10px;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                img {
                  width: 100px;
                }
                span {
                  margin-left: 25px;
                  cursor: pointer;
                }
              }
              .view-all {
                color: #0063a8;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.mobilesearch .search-header {
  display: none;
  @media (max-width: 767px) {
    display: block;

    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    z-index: 100;
    height: 56px;
    top: 69px;
  }
  .search-icon {
    @media (max-width: 767px) {
      width: auto !important;
    }
    img {
      cursor: pointer;
      height: 23px;
      object-fit: contain;
    }
  }
}
.header-menus {
  overflow-y: scroll;
  border: 2px solid #ebf9ff;
  position: fixed;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 1rem 10px;
  width: 100%;
  margin-left: auto;
  left: 0;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  max-width: 30rem;
  height: 100vh;
  width: 33.3333333333%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
  .header-wrappers {
    width: 100%;
    margin: auto;
    background-color: #fff;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  li {
    cursor: pointer;

    color: #000;

    padding-top: 12px;
    padding-bottom: 12px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &:hover {
      color: #0063a8;
    }
    p {
      font-size: 17px;
      color: #000;
    }
  }
  @media (max-width: 767px) {
    padding: 1rem 10px;
    overflow-y: scroll;
    height: 100vh;
  }

  .menu {
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      display: block;
      width: 100%;
    }
  }

  .leftside {
    padding: 70px 40px 40px 40px;
    height: 100vh;

    @media (max-width: 1024px) {
      padding: 40px 20px 20px 20px;
    }
    svg {
      position: absolute;
      right: 60px;
    }
    .bottom-section {
      padding: 20px 0px;
      background: #ffff;

      // padding: 40px 30px 30px;
      // position: absolute;
      // bottom: 30px;
      // padding: 0px 55px;
      // left: 0;
      // right: 0;
      // width: 27rem;
      .logo {
        width: 150px;

        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        @media (max-width: 767px) {
          width: 100px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        color: #000;

        padding-top: 20px;
        padding-bottom: 20px;
      }
      .badges {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 767px) {
          width: 100px;
        }
        img {
          width: 52px;
          object-fit: contain;
          height: 52px;
          @media (max-width: 767px) {
            width: 35px;

            object-fit: contain;
            height: 35px;
          }
        }
      }
    }
  }
  span.close-icon {
    position: absolute;
    left: 42px;
    padding: 10px;
    top: 20px;

    cursor: pointer;
    display: flex;

    @media (max-width: 1024px) {
      left: 20px;

      top: 7px;
    }
    @media (max-width: 767px) {
      top: 0%;
      left: 20px;
    }
    .close-text {
      color: #545454;

      padding-left: 5px;
    }
    img {
      width: 20px;
      height: 20px;
      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.submenu-sidebar {
  .header-menus-sidebar {
    border: none;
    position: fixed;

    left: 30rem;
    z-index: 999;

    width: 33.33333333%;
    @media (max-width: 1024px) {
      width: 100%;
      top: 0;
      left: 0;
    }
    @media (max-width: 767px) {
      width: 100%;
      top: 0;
      left: 0;
    }
    li {
      cursor: pointer;

      color: #000;

      padding-top: 20px;
      padding-bottom: 15px;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        color: #0063a8;
      }
    }
    .back-arrow {
      display: none;
      @media (max-width: 1024px) {
        display: block;
        padding-left: 7px;
      }
      @media (max-width: 767px) {
        display: block;
        padding-left: 7px;
      }
    }
  }
  .leftside {
    padding: 0px;
  }
  ul.submenu-item {
    border: 2px solid #ebf9ff;
    position: fixed;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 1rem 10px;
    width: 100%;
    margin-left: auto;
    top: 0;
    left: 0;
    padding: 40px;
    z-index: 999;
    background: #fff;
    max-width: 30rem;
    height: 100vh;
    /* width: 33.33333333%; */
    @media (max-width: 1024px) {
      padding: 20px;
    }
    p {
      font-size: 17px;
      color: #000;
    }

    p.banner-img {
      max-width: 30rem;
      margin-top: 40px;
      margin-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .boch-btn {
      background: #0063a8;
      color: #fff;
      padding: 15px;
      border: navajowhite;
      border-radius: 8px;
      font-family: "FuturaBookBT";
      margin: 20px 0px;
    }
  }
  .stats-container {
    display: flex;
    justify-content: space-between;
    background: #0063a8;
    color: #fff;
    padding: 40px 10px;
    .stats {
      width: 30%;
      text-align: center;
      .count {
        font-size: 25px;
        font-weight: 700;
        padding-bottom: 10px;
      }
      .stats-title {
        font-size: 14px;
      }
    }
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 999;
  display: none;
}

.backdrop.active {
  display: block;
}

.dropdown-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

// scroll subheader css
.scroll-subheader {
  transition: top 0.3s, transform 0.3s ease;
  padding: 0;
  background: #fff;
  transition: top 0.3s;
  @media (max-width: 1024px) {
    display: none;
  }
  li {
    list-style: none;
  }
  .scrollheader {
    align-items: center;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    @keyframes fadeInFromRight {
      0% {
        opacity: 0;
        transform: translateX(100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .logofromright {
      animation: fadeInlogo ease 2s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards !important;
    }
    @keyframes fadeInlogo {
      0% {
        opacity: 0;
        transform: translateX(0%);
      }
      100% {
        opacity: 1;
        transform: translateX(-100%);
      }
    }
    .logo-wrapper {
      animation: fadeInFromRight ease 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      .text {
        font-family: cursive;

        font-size: 14px;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    .logo {
      animation: fadeInFromRight ease 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      width: 150px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }
    @keyframes fadeInFromLeft {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .menusfromright {
      animation: fadeInFromCenter ease 2s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards !important;
    }
    @keyframes fadeInFromCenter {
      0% {
        opacity: 0;
        transform: translateX(10%);
      }
      100% {
        opacity: 1;
        transform: translateX(-50%);
      }
    }
    .menus {
      animation: fadeInFromLeft ease 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      ul {
        display: flex;
        li {
          position: relative;

          list-style: none;
          margin: 0 10px;
          display: flex;
          cursor: pointer;
          justify-content: space-between;
          align-items: center;

          &:hover {
            ul.scrollsubmenu-item {
              display: flex;

              flex-direction: column;
            }
          }
          // &:nth-child(7) {
          //   display: none;
          // }
          // &:nth-child(8) {
          //   display: none;
          // }
          // &:nth-child(9) {
          //   display: none;
          // }
          .img-div {
            width: 24px;
            height: 24px;
            img {
              transform: rotate(90deg);
              width: 100%;
            }
          }
          p {
            font-size: 17px;
            color: #000;
          }
        }
        ul.scrollsubmenu-item {
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          border-radius: 5px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.14);
          background-color: #fff;
          padding: 10px 10px;
          z-index: 999;
          width: 260px;
          li {
            padding: 15px 0px;
            border-bottom: 1px solid rgb(167 169 171 / 50%);

            &:last-child {
              border: none;
            }
          }
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      @keyframes fadeInFromLeft {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      .feedback-icon {
        animation: fadeInFromLeft ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        padding: 0px 22px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        width: 70px;
        img {
          width: 100%;
        }
      }
      @keyframes fadeInFromLeft {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      .search-icon {
        animation: fadeInFromLeft ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        img {
          cursor: pointer;
          height: 23px;
          object-fit: contain;
        }
      }
      .badges {
        width: 150px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        img {
          width: 52px;
          object-fit: contain;
          height: 52px;
          @media (max-width: 767px) {
            width: 35px;

            object-fit: contain;
            height: 35px;
          }
        }
      }
    }
  }
}
.scroll-subheader.sticky {
  transition: transform 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 995;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.hidden {
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
}
// cursor effect
.cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #edeef0;
  opacity: 0.6;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  transform-origin: 65% 65%;
  z-index: 999999;
}

.link-grow {
  transform: scale(2.8);
  background-color: #64c698;
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.2;
  transition: all 0.01s linear;
}

.link-grow1 {
  transform: scale(4);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.8;
  transition: all 0.01s linear;
}

.link-shadow {
  transform: scale(4);
  background-color: #edeef0;
  box-shadow: 0px 0px 3px 0.5px #888888;
  opacity: 0.2;
  transition: all 0.3s linear;
}
// cursor effect

// forword section start
.forward-btns {
  z-index: 9999993;
  position: fixed;
  bottom: 152px;
  padding: 10px 0px;
  right: 20px;
  &:hover .forwardbtns {
    visibility: visible;
  }
  .vibrant-btn {
    position: relative;
    z-index: 99999;
    box-shadow: 1px 1px 6px grey;
    background: #28a745; /* Changed to green */
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    border-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    a {
      width: 100%;
      height: 100%;
      padding: 4px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &:hover .content {
      visibility: visible;
    }
    .content {
      position: absolute;
      top: 0;
      right: 120%;
      visibility: hidden;
      bottom: 0;
      width: 210px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      color: #000;
      .details {
        background-color: #fff;
        color: #ff1c1c;
        border: 2px solid #ff1c1c;
        border-radius: 5px;
        position: relative;
        width: 100%;
        padding: 10px;
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            color: #0063a8;
            font-weight: 600;
            cursor: pointer;
            text-decoration: none;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -10px;
          width: 15px;
          height: 14px;
          border-style: solid;
          border-color: #ff1c1c transparent transparent #ff1c1c;
          background-color: white;
          transform: rotate(-226deg);
          bottom: 0;
          margin: auto;
          border-width: 2px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          right: -32px;
          width: 56px;
          height: 40px;
          border-style: solid;
          border-color: transparent;
          background-color: transparent;
          transform: rotate(-270deg);
          bottom: 0;
          margin: auto;
        }
      }
      .call-buton .cc-calto-action-ripple i {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        font-size: 2.2rem;
      }

      img {
        width: 25px;
        padding-right: 3px;
        height: 100%;

        object-fit: contain;
      }
    }
  }
}
.call-btns {
  z-index: 9999993;
  position: fixed;
  bottom: 62px;
  right: 20px;
  padding: 10px 0px;
  &:hover .forwardbtns {
    visibility: visible;
  }
  .vibrant-btn {
    position: relative;
    z-index: 99999;
    box-shadow: 1px 1px 6px grey;
    background: #28a745; /* Changed to green */
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    border-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    .call-animation {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 100%;
      animation: play 2s ease infinite;

      a {
        margin: auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    @keyframes play {
      0% {
        transform: scale(1);
      }
      15% {
        box-shadow: 0 0 0 5px rgba(40, 167, 69, 0.3);
      }
      25% {
        box-shadow: 0 0 0 10px rgba(40, 167, 69, 0.3),
          0 0 0 20px rgba(40, 167, 69, 0.3);
      }
      50% {
        box-shadow: 0 0 0 15px rgba(106, 230, 135, 0.3),
          0 0 0 30px rgba(40, 167, 69, 0.3);
      }
    }
    &:hover .content {
      visibility: visible;
    }
    .content {
      position: absolute;
      top: 0;
      right: 120%;
      visibility: hidden;
      bottom: 0;
      width: 202px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      color: #000;
      .details {
        background-color: #fff;
        color: #ff1c1c;
        border: 2px solid #0063a8;
        position: relative;
        border-radius: 5px;
        width: 100%;
        padding: 10px 15px;
        p:nth-child(1) {
          color: #0063a8;
          font-weight: 600;
          padding-bottom: 3px;
        }
        p:nth-child(2) {
          color: #0063a8;
          font-weight: 600;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            width: 25px;
            padding-right: 5px;
            height: 100%;
            object-fit: contain;
          }
          a {
            color: #0063a8;
            text-decoration: none;
          }
        }
        p:nth-child(3) {
          color: #ff1c1c;
          font-weight: 600;
          padding-bottom: 3px;
        }
        p:nth-child(4) {
          color: #ff1c1c;
          cursor: pointer;
          font-weight: 600;
          img {
            width: 25px;
            padding-right: 5px;
            height: 100%;
            object-fit: contain;
          }
          a {
            color: #ff1c1c;
            text-decoration: none;
          }
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: -10px;
          width: 15px;
          height: 14px;
          border-style: solid;
          border-color: #0063a8 transparent transparent #0063a8;
          background-color: white;
          transform: rotate(-226deg);
          bottom: 0;
          margin: auto;
          border-width: 2px;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          right: -32px;
          width: 56px;
          height: 40px;
          border-style: solid;
          border-color: transparent;
          background-color: transparent;
          transform: rotate(-270deg);
          bottom: 0;
          margin: auto;
        }
      }
      .call-buton .cc-calto-action-ripple i {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        font-size: 2.2rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

// forword section end

.nabl {
  height: 46px !important;
  @media (max-width: 767px) {
    height: 35px !important;
  }
}
.jci {
  height: 70px !important;
  width: 70px !important;
  @media (max-width: 767px) {
    height: 50px !important;
  }
}
// Call Section End
</style>
