<template>
  <div>
    <!-- cursor effect -->
    <div class="cursor" ref="cursor"></div>
<!-- <SliderComp/> -->
    <AboutBanner />
    <AboutOverview />
    <VisionComp />
    <StatisticsComp />
    <DoctorsSay/>
    <LatestStories />
    <AwardsComp />
  </div>
</template>

<script>
// import SliderComp from "../components/SliderComp.vue"
import AboutBanner from "../components/AboutBannr.vue";
import { ref } from "vue";
import { onMounted } from "vue";
import AboutOverview from "../components/AboutOverview.vue";
import VisionComp from "../components/VisionComp.vue";
import StatisticsComp from "../components/StatisticsComp.vue";
import AwardsComp from "../components/AwardsComp.vue";
import LatestStories from "../components/LatestStories";
import DoctorsSay from "../components/DoctorsSay.vue"
export default {
  name: "AboutPage",
  components: {
    // SliderComp,
    VisionComp,
DoctorsSay,
    AboutBanner,
    AboutOverview,
    StatisticsComp,
    AwardsComp,
    LatestStories,
  },
  setup() {
    const cursor = ref(null);
    onMounted(() => {
      document.body.style.overflow = "";
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });

      const navLinksElements = document.querySelectorAll(".cursoreffect");
      navLinksElements.forEach((link) => {
        link.addEventListener("mouseover", () => {
          cursorinner.classList.add("link-grow"); // Apply the class to cursor inner directly
        });
        link.addEventListener("mouseleave", () => {
          cursorinner.classList.remove("link-grow"); // Remove the class from cursor inner
        });
      });
    });

    return {
      cursor,
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.about-wrapper {
  .background-abtwrapper {
    // background: #f9f9f9;

    background: linear-gradient(180deg, #ebf7ff, transparent);
  }
}
</style>
