<template>
  <div class="cursor" ref="cursor"></div>
  <HeaderComp />
  <router-view></router-view>
  <FooterComp />
</template>
<script>
import { ref, onMounted } from "vue";
import HeaderComp from "./components/HeaderComp.vue";
import FooterComp from "./components/FooterComp.vue";
import { useRouter } from "vue-router"; // Import useRouter from vue-router

export default {
  name: "App",
  components: {
    HeaderComp,
    FooterComp,
  },
  setup() {
    const cursor = ref(null);
    const router = useRouter(); // Get router instance

    onMounted(() => {
      const cursorinner = cursor.value;

      document.addEventListener("mousemove", function (e) {
        var x = e.clientX;
        var y = e.clientY;
        cursorinner.style.left = x + "px";
        cursorinner.style.top = y + "px";
      });
      router.afterEach(() => {
        const navLinksElements = document.querySelectorAll(".cursoreffect"); // Change this to select elements with the correct class
        navLinksElements.forEach((link) => {
          link.addEventListener("mouseover", () => {
            cursor.value.classList.add("link-grow");
          });
          link.addEventListener("mouseleave", () => {
            cursor.value.classList.remove("link-grow");
          });
        });
      });
    });

    return {
      cursor,
    };
  },
};
</script>

<style lang="less">
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@font-face {
  font-family: "FuturaBookBT";
  src: url("../src/assets/Noble Fonts/FuturaBookBT.ttf") format("truetype");
}
@font-face {
  font-family: "FuturaMediumBT";
  src: url("../src/assets/Noble Fonts/FuturaMediumBT.ttf") format("truetype");
}
#app {
  font-family: "FuturaBookBT", sans-serif;
}
.container {
  max-width: calc(100% - 17rem);
  padding: 60px 0px;
  margin: 0 auto;
  @media (max-width: 1600px) {
    max-width: calc(100% - 11rem);
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    max-width: calc(100% - 4rem);
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    max-width: calc(100% - 2rem);
    margin: 0 auto;
  }
}
body{
  line-height: 1.6;
}
h1,h2,h3,h4,h5,h6{
  line-height: 1.3;
}
h2 {
  font-family: "FuturaMediumBT";
  font-size: 32px;

  color: #454545;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
  @media (max-width: 1599px) {
    font-size: 24px;
  }
}
h3 {
  font-size: 20px;
  font-family: "FuturaBookBT";
  color: #58595b;
  font-weight: 600;

  @media (max-width: 992px) {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0px;
  }
}
h4 {
  font-size: 16px;
  font-family: "FuturaBookBT";
}
h5 {
  font-size: 18px;
  font-family: "FuturaBookBT";
}
p {
  font-family: "FuturaBookBT";
  font-size: 16px;
      line-height: 1.6;
 
}
label {
  font-weight: 600;
  font-size: 16px;
  font-family: "FuturaBookBT";
}

// static pages css start
.static-pages {
  h2 {
    text-align: center;
  }
  .text-container {
    h2 {
      text-align: left;
      margin-top: 10px;
      font-size: 24px;
    }
    p {
      line-height: 1.5;
    }
    ul {
      padding-left: 30px;
      li {
        line-height: 1.5;
      }
    }
  }
}
// static pages css end
// cursor effect
.cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #edeef0;
  opacity: 0.6;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  transform-origin: 65% 65%;
  z-index: 9999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-grow {
  transform: scale(2.8);
  background-color: #64c698;
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.2;
  transition: all 0.01s linear;
  z-index: 9999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-grow1 {
  transform: scale(4);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 2px 0.5px #888888;
  opacity: 0.8;
  transition: all 0.01s linear;
  z-index: 99999999;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.link-shadow {
  transform: scale(4);
  background-color: #edeef0;
  box-shadow: 0px 0px 3px 0.5px #888888;
  opacity: 0.2;
  z-index: 99999999;
  transition: all 0.3s linear;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
// cursor effect
</style>
